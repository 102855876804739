import MainTitle from "../../../components/MainTitle";
import { useState, useEffect, Fragment, useRef } from "react";
import useAuth from "../../../hooks/useAuth";
import { useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faCircleXmark,
  faChevronLeft,
  faEnvelope,
  faComment,
  faPaperclip,
  faPaperPlane,
  faTrash,
  faChevronCircleDown,
  faDownload,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";

import {Transition, Dialog, Listbox, Menu} from '@headlessui/react'
import Modal from "../../../components/Modal";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import InfoBanner from "../../../components/InfoBanner";
import moment from "moment";
import WBAForm from "./Forms/WBAForm";
import PRSForm from "./Forms/PRSForm";
import OHResultForm from "./Forms/OHResultForm";
import { ImageConfig } from "../../../components/ImageConfig";

const ViewClaim = () => {
  const { id, claim } = useParams()
  const { setShowLoader, createInfo, auth } = useAuth()
  // search
  const navigate = useNavigate()
  const axios = useAxiosPrivate()
  // No of Records to be displayed on each page
  const [showModal, setShowModal] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [selectedForm, setSelectedForm] = useState('')
  const [formPopOutTitle, setFormPopOutTitle] = useState('')
  const [modalTitle, setModalTitle] = useState("")
  const [statuses, setStatuses] = useState({})
  const [claimData, setClaimData] = useState({})
  const [editMode, setEditMode] = useState(false)
  const commsRef = useRef([])
  const [service, setService] = useState([])
  const [services, setServices] = useState([])
  const [showComms, setShowComms] = useState(false)
  const [showDocs, setShowDocs] = useState(false);
  const [documents, setDocuments] = useState(false);
  const [client, setClient] = useState({})
  const [comms, setComms] = useState({})
  const [serviceProvider, setServiceProvider] = useState('')
  const [newMessage, setNewMessage] = useState({});
  const [addMessage, setAddMessage] = useState(false)
  const [addMessageType, setAddMessageType] = useState('2')
  const [messageAttachments, setMessageAttachments] = useState([])

  const publishingOptions = [
    // { id: 1, title: 'SMS', current: true },
    { id: 2, title: 'Email', current: false },
  ]

  const [selected, setSelected] = useState(publishingOptions[0])

  //drag and drop
  const wrapperRef = useRef(null)
  const [fileList, setFileList] = useState([])
  const [selectedFile, setSelectedFile] = useState()
  const [errorMsg, setErrorMsg] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const allowedFileTypes = [
    '.doc',
    '.docx',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    '.xls',
    '.xlsx',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/csv',
    '.csv',
    'application/pdf',
    '.pdf',
    'image/jpeg',
    '.jpg',
    'image/png',
    '.png',
    'image/tiff',
    '.tiff',
  ];

  const onDragEnter = () => wrapperRef.current.classList.add('dragover')
  const onDragLeave = () => wrapperRef.current.classList.remove('dragover')
  const onDragOver = (e) => {
    e.stopPropagation()
    e.preventDefault()
  }
  const onDrop = (e) => {
    e.preventDefault()
    wrapperRef.current.classList.remove('dragover')
    onFileDrop(e.nativeEvent.dataTransfer.files)
  }


  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const sendMessage = async (e) => {
    e.preventDefault()
    setShowLoader(true)
    let files = [];
    const fd = new FormData()
    for (let i = 0; i < messageAttachments.length; i++) {
      files.push(messageAttachments[i])
    }
    fd.append(
      'data',
      JSON.stringify({
        delivery: 'outbound',
        body: newMessage.body,
        recipient_id: claimData.claimable.id,
        sender_id: auth.user.id,
        thread_id: claimData.threads.id,
        status_id: 1,
        type_id: addMessageType,
        attachments: files
      })
    )
    try {
      await axios.post(`/comms/new`, fd).then((response) => {
        if (response.error === false) {
          createInfo('success', `Message is sending`)
          setComms([...comms, response.data.result]);
          setAddMessage(false)
          setNewMessage({ body: '' }) // Reset the text area to blank
          setMessageAttachments([])
        }
      })
    } catch (error) {
      createInfo('error', `Failed to create message`)
    }
    newMessage.body = ''
    setShowLoader(false)
  }


  const getStatuses = async () => {
    try {
      await axios.get("/claims/statuses").then((response) => {
        setStatuses(response.data.result)
      });
    } catch (error) {
      console.log(error)
    }
  };

  const updateStatus = async (status) => {
    setShowLoader(true)
    try {
      const response = await axios.post(`claims/status/update/${claimData.id}/${status}`);
      if (response.data.result) {
        setShowLoader(false)
        createInfo('success', 'Updated claim successfully')
        setTimeout(() => {
          window.location.reload();
        }, 5000)
      } else {
        setShowLoader(false)
        createInfo('error', 'Failed to update claim')
      }
    } catch (error) {
      createInfo('error', 'Failed to update claim')
    }
  }

  const deleteAttachment = (attachment) =>
  {
    setMessageAttachments((messageAttachments) =>
    messageAttachments.filter((item) => item !== attachment)
  );

  }

  
  const uploadDocuments = async () => {
    validateSelectedFile()

    setShowLoader(true)
    console.log(fileList)
    const fd = new FormData()
    for (let i = 0; i < fileList.length; i++) {
      fd.append('shared-document-' + i, fileList[i], fileList[i].name)
    }
    try {
      const response = await axios.post(`/clients/${id}/upload-documents`, fd)

      if (response.data.result) {
        setShowDocs([...showDocs, ...response.data.result])
      }

      setFileList([])

      createInfo(
        'success',
        'Uploaded ' + response.data.result.length + ' document(s)'
      )
    } catch (error) {}
    setShowLoader(false)
  }

  const handleUpdate = async () => {
    setShowLoader(true);
    const formData = {
      claim_id: claimData.id,
      service_id: claimData.service.id,
      service_provider_id: serviceProvider,
      description: claimData.description,
    };
    try {
      const response = await axios.post(`claims/update/${claimData.id}`, formData);
      if (response.data.result) {
        setShowLoader(false)
        createInfo('success', 'Updated claim successfully')
        setTimeout(() => {
          navigate(`/clients/${id}/referrals`)
        }, 5000)
      } else {
        setShowLoader(false)
        createInfo('error', 'Failed to update claim')
      }
    } catch (error) {
      console.error("Form submission failed:", error)
    }
    
  }

  const handleEditClick = () => {
    setEditMode((prevState) => !prevState);
  }

  const goToClaims = () => {
    navigate(`/clients/${claimData.claimable.id}/claims`);
  };


  const getClaim = async () => {
    setShowLoader(true);
      await axios.get(`/claims/${claim}`).then((response) => {
        setClaimData(response.data.result)
        setClient(response.data.result.claimable)
        axios.get(`/services/by/group/${response.data.result.service.service_group_id}`).then((response) => {
            setServices(response.data.result)
          });
        setClaimData(response.data.result);
        setClient(response.data.result.claimable);
        setDocuments(response.data.result.documents);
        if(response.data.result.threads !== null)
          {
            setComms(response.data.result.threads.comms);
          }
        setService(response.data.result.service);
        setServiceProvider(response.data.result.service.id);
        setShowLoader(false)
      }).catch((error) => {
        setShowLoader(false)
      })
  }

  const openForm = ($formName) => {
    if($formName === 'WBA') {
      setFormPopOutTitle('Refer to WBA')
    } else {
      setFormPopOutTitle($formName + ' Form')
    }
    
    setSelectedForm($formName)
    setShowForm(true)
  }
  
  const fileRemove = (file) => {
    const updatedList = [...fileList]
    updatedList.splice(fileList.indexOf(file), 1)
    setFileList(updatedList)
  }

  const isValidFileType = (file) => {
    const extension = `.${file.name.split('.').pop()}`;
    const mimeType = file.type;
    return (
      allowedFileTypes.includes(extension) ||
      allowedFileTypes.includes(mimeType)
    );
  };

  const deleteFile = async (file) => {
    setShowLoader(true)
    try {
      const response = await axios.get(
        `/clients/${id}/delete-document/${file.id}`
      )

      if (!response.data.error) {
        const updatedList = [...showDocs]
        updatedList.splice(showDocs.indexOf(file), 1)
        setShowDocs(updatedList)

        createInfo('success', 'Document removed')
      } else {
        createInfo('error', 'Could not remove document')
      }
    } catch (error) {
      createInfo('error', 'Could not remove document')
    }
    setShowLoader(false)
  }

  const onFileUpload = async (e) => {
    e.preventDefault();
    const newFile = e.target.files[0];

    if (newFile && isValidFileType(newFile)) {
      const updatedList = [...fileList, newFile];
      setFileList(updatedList);
    } else {
      console.log('Invalid file type. Please select a valid file.');
    }
  };

  const onFileDrop = async (files) => {
    const newFile = files[0];
  
    if (newFile && isValidFileType(newFile)) {
      const updatedList = [...fileList, newFile];
      setFileList(updatedList);
    } else {
      createInfo('error', 'Invalid file type');
      return
    }
  };

  const validateSelectedFile = async () => {
    const file_size = await axios.get(`/clients/max-file-size`)

    const MAX_FILE_SIZE = file_size.value // 10MB

    if (!selectedFile) {
      setErrorMsg('Please choose a file')
      setIsSuccess(false)
      return
    }

    const fileSizeKiloBytes = selectedFile.size / 1024

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      setErrorMsg('File size is greater than maximum limit')
      setIsSuccess(false)
      return
    }

    setErrorMsg('')
    setIsSuccess(true)
  }



  useEffect(() => {
    getStatuses();
    getClaim()
  }, [claim])

  const ModalBody = () => {
    return
  }

  const resetModal = () => {
    setShowModal(false);
    setModalTitle("");
  }

  const toolTipData = [
    // {
    //   title: 'Name',
    //   value: client.name,
    // },
    // {
    //   title: 'Date of birth',
    //   value: moment(client?.client_details?.date_of_birth).format('DD/MM/YYYY'),
    // },
    // {
    //   title: 'Email',
    //   value: client.email,
    // }
  ]


  return (
    <section>
      <MainTitle title={`Client: ${client?.name}`} icon={true} toolTipData={toolTipData} />
      {showModal && (
      <Modal title={modalTitle} body={
      <ModalBody />
      } show={resetModal} />
      )}
        <Transition show={showComms} as={Fragment}>
          <Dialog as="div" className="relative z-[100]" onClose={() =>
              {setShowComms(false)}}>
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-75"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-75"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>
                <div className="fixed inset-0 overflow-hidden">
                  <div className="absolute inset-0 overflow-hidden">
                    <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                      <Transition.Child
                        as={Fragment}
                        enter="transform transition ease-in-out duration-500 sm:duration-700"
                        enterFrom="translate-x-full"
                        enterTo="translate-x-0"
                        leave="transform transition ease-in-out duration-500 sm:duration-700"
                        leaveFrom="translate-x-0"
                        leaveTo="translate-x-full"
                        >
                      <Dialog.Panel className="pointer-events-auto w-screen max-w-md xl:max-w-2xl">
                          <div className="flex h-full overflow-y-scroll xl:overflow-y-none flex-col bg-white py-6 shadow-xl">
                            <div className="px-4 sm:px-6">
                                <div className="flex items-start justify-end">
                                    <div className="ml-3 flex h-7 items-center">
                                        <button
                                          type="button"
                                          className="relative rounded-md bg-white text-gray-400 hover:text-gray-500"
                                          onClick={() => setShowComms(false)}
                                        >
                                          <span className="absolute -inset-2.5" />
                                          <span className="sr-only">Close panel</span>
                                          <FontAwesomeIcon icon={faCircleXmark} size="lg" />
                                        </button>
                                      </div>
                                  </div>
                              </div>
                            <div className="relative mt-2 flex-1 px-4 sm:px-6">
                              <section>
                                <section className="relative w-full py-4 px-4 mb-8">
                                  <div className="w-1/2 p-4">
                                  </div>
                                  <section className="d-flex row justify-content-between px-3 align-items-center">
                                    <aside className="d-flex align-items-center text-right border">
                                      <div className="rounded overflow-hidden shadow-md">
                                        <div className="p-4 text-md flex justify-between">
                                        <h4><span className="font-semibold">Thread:</span> {claimData?.threads?.subject}</h4>
                                        </div>
                                      <hr />
                                        <div className="px-6 py-4 comms-container overflow-y-scroll w-full max-h-50 bg-slate-100 flex flex-col">
                                          {comms.length > 0 ? (comms?.map((message, index) => {
                                            return (
                                            <div
                                            ref={commsRef.current[index]}
                                            className={`comms-history p-4 w-3/5 relative block ${
                                            message?.delivery === 'inbound'
                                            ? 'mr-auto'
                                            : 'ml-auto'
                                            }`}
                                            >
                                            <div
                                            className={`message-time ${
                                            message?.delivery === 'inbound'
                                            ? 'text-left'
                                            : 'text-right'
                                            }`}
                                            >
                                            <span className="text-xs font-medium">
                                            {moment(message.created_at).format(
                                            'DD/MM/YYYY HH:mm'
                                            )}
                                            <span> By {message?.sender?.name}</span>
                                            {message?.delivery === 'outbound' && (
                                            <>
                                            {' - '}
                                            <span className="font-bold">
                                            Status: {message.status}
                                            </span>
                                            </>
                                            )}
                                            </span>
                                            </div>
                                            <div
                                            className={`flex items-center ${
                                            message?.delivery === 'inbound'
                                            ? 'flex-row-reverse -ml-6'
                                            : '-mr-6'
                                            }`}
                                            >
                                            <div
                                            className={`message-bubble --${
                                            message?.delivery === 'outbound'
                                            ? message?.status
                                            : ''
                                            } py-3 px-4 rounded w-full ${
                                            message?.delivery === 'inbound'
                                            ? 'text-left bg-blue-300'
                                            : 'text-right bg-pink-300'
                                            }`}
                                            >
                                            <p>{message?.body}</p>
                                            </div>
                                            <div className="message-type">
                                            <span
                                            className={`flex ${
                                            message?.delivery === 'inbound'
                                            ? 'text-left mr-2'
                                            : 'text-right ml-2'
                                            }`}
                                            >
                                            {message?.type_id === 1 ? (
                                            <FontAwesomeIcon
                                              icon={faEnvelope}
                                              className="text-slate-400"
                                              />
                                            ) : (
                                            <FontAwesomeIcon
                                              icon={faComment}
                                              className="text-slate-400"
                                              />
                                            )}
                                            </span>
                                            </div>
                                            </div>
                                            <div
                                            className={`w-full message-attachments ${
                                            message?.delivery === 'inbound'
                                            ? 'text-left'
                                            : 'text-right'
                                            }`}
                                            >
                                            {message?.attachments?.length > 0 && (
                                            <div>
                                            <Menu
                                              as="div"
                                              className="relative inline-block text-left z-10"
                                              >
                                            <div>
                                            <Menu.Button className="flex items-center rounded-md cursor-pointer">
                                            <span className="font-semibold text-sm text-left flex">
                                            <span className="mr-2">
                                            Attachments
                                            </span>
                                            <FontAwesomeIcon
                                              icon={faChevronCircleDown}
                                              />
                                            </span>
                                            </Menu.Button>
                                            </div>
                                            <Transition
                                              as={Fragment}
                                              enter="transition ease-out duration-100"
                                              enterFrom="transform opacity-0 scale-95"
                                              enterTo="transform opacity-100 scale-100"
                                              leave="transition ease-in duration-75"
                                              leaveFrom="transform opacity-100 scale-100"
                                              leaveTo="transform opacity-0 scale-95"
                                              >
                                            <Menu.Items
                                            className={`absolute ${
                                            message.delivery === 'inbound'
                                            ? 'left-0'
                                            : 'right-0'
                                            } mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none`}
                                            >
                                            <div className="p-2">
                                            {message.attachments.map(
                                            (attachment) => {
                                            return (
                                            <Menu.Item>
                                            <a
                                              href={attachment.url}
                                              target="_blank"
                                              rel="noreferrer"
                                              >
                                            <button
                                              className={`group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                              >
                                            {attachment.name}
                                            </button>
                                            </a>
                                            </Menu.Item>
                                            )
                                            }
                                            )}
                                            </div>
                                            </Menu.Items>
                                            </Transition>
                                            </Menu>
                                            </div>
                                            )}
                                            </div>
                                            </div>
                                            )
                                          })) : (
                                            <span>No Comms</span>
                                          )}
                                          {claimData?.threads?.length <= 0 && (
                                          <div className="text-center py-5 px-5">
                                          No Comms Found
                                          </div>
                                          )}
                                        </div>
                                      <form
                                        className=" bg-slate-100 px-4 pt-4 pb-6"
                                        onSubmit={sendMessage}
                                        >
                                      <div className="flex items-start border-2 border-gray-300 bg-white rounded-lg shadow-md">
                                      <Listbox value={selected} onChange={setSelected}>
                                          {({ open }) => (
                                            <>
                                              <Listbox.Label className="sr-only">
                                                Change comm method
                                              </Listbox.Label>
                                              <div className="">
                                                <div className="inline-flex">
                                                  <div className="h-16 inline-flex rounded-l-lg shadow-sm items-center bg-slate-800 p-2 hover:bg-slate-600  focus:outline-none focus:ring-2 focus:ring-slate-700 focus:ring-offset-2 focus:ring-offset-gray-50">
                                                    <div className="inline-flex items-center px-3 text-white text-sm">
                                                      <p className=" font-semibold">
                                                        {selected.title}
                                                      </p>
                                                    </div>

                                                    {/* <span className="sr-only">
                                                      Change comm method
                                                    </span>
                                                    <FontAwesomeIcon
                                                      icon={faChevronDown}
                                                      className="text-white"
                                                    /> */}
                                                  </div>
                                                </div>

                                                <Transition
                                                  show={open}
                                                  as={Fragment}
                                                  leave="transition ease-in duration-100"
                                                  leaveFrom="opacity-100"
                                                  leaveTo="opacity-0"
                                                >
                                                  <Listbox.Options className="absolute left-4 mt-2 w-20 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                                                    {/* {publishingOptions.map((option) => (
                                                      <Listbox.Option
                                                        onClick={() =>
                                                          setAddMessageType( option.id ?? 1)
                                                        }
                                                        key={option.id}
                                                        className={({ active }) =>
                                                          classNames(
                                                            active
                                                              ? 'bg-slate-800 text-white'
                                                              : 'text-gray-900',
                                                            'cursor-default select-none p-2 text-xs'
                                                          )
                                                        }
                                                        value={option}
                                                      >
                                                        {({ selected, active }) => (
                                                          <div className="flex flex-col">
                                                            <div className="flex justify-between">
                                                              <p
                                                                className={
                                                                  selected
                                                                    ? 'font-semibold'
                                                                    : 'font-normal'
                                                                }
                                                              >
                                                                <span>
                                                                  {' '}
                                                                  {option.title === '1' ? (
                                                                    <FontAwesomeIcon
                                                                      icon={faPhone}
                                                                      className="text-slate-600"
                                                                    />
                                                                  ) : (
                                                                    <FontAwesomeIcon
                                                                      icon={faEnvelope}
                                                                      className="text-slate-600"
                                                                    />
                                                                  )}
                                                                </span>
                                                                <span className="pl-1">
                                                                  {option.title}
                                                                </span>
                                                              </p>
                                                              {selected ? (
                                                                <span
                                                                  className={
                                                                    active
                                                                      ? 'text-white'
                                                                      : 'text-slate-800'
                                                                  }
                                                                >
                                                                  <FontAwesomeIcon
                                                                    icon={faCheck}
                                                                    className="h-3 w-3"
                                                                  />
                                                                </span>
                                                              ) : null}
                                                            </div>
                                                          </div>
                                                        )}
                                                      </Listbox.Option>
                                                    ))} */}
                                                    <Listbox.Option
                                                        key={2}
                                                        className={({ active }) =>
                                                          classNames(
                                                            active
                                                              ? 'bg-slate-800 text-white'
                                                              : 'text-gray-900',
                                                            'cursor-default select-none p-2 text-xs'
                                                          )
                                                        }
                                                        value={'Email'}
                                                      >
                                                      </Listbox.Option>
                                                  </Listbox.Options>
                                                </Transition>
                                              </div>
                                            </>
                                          )}
                                      </Listbox>
                                      <div className="w-full flex border-l-2">
                                      <textarea
                                        type="text"
                                        placeholder="Write your message..."
                                        required
                                        name="body"
                                        id="body"
                                        value={newMessage.body}
                                        onChange={(e) =>
                                      setNewMessage({
                                      ...newMessage,
                                      body: e.target.value,
                                      })
                                      }
                                      className="px-4 py-2 block w-full resize-none"
                                      />
                                      <div className="flex justify-end items-center pr-2 space-x-1 bg-white rounded-r-lg">
                                      <div className="" title="Add attachment">
                                        <label
                                          className="flex items-center hover:cursor-pointer rounded-lg bg-slate-400 text-white px-4 font-medium py-3"
                                          htmlFor="upload_file"
                                          >
                                        <FontAwesomeIcon icon={faPaperclip} />
                                        </label>
                                        <input
                                            className="hidden w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                            id="upload_file"
                                            onChange={async (e) => {
                                              const files = Array.prototype.slice.call(
                                                e.target.files
                                              )
                                              const attachments = [...messageAttachments]
                                              files.some((file) => {
                                                return (
                                                  attachments.push(file)
                                                )
                                              })
                                              setMessageAttachments(attachments)
                                            }}
                                            type="file"
                                            accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.csv,text/csv,.pdf,application/pdf,image/jpeg,image/png,image/tiff"
                                          />
                                      </div>
                                    
                                      <button
                                        type="submit"
                                        title="Send"
                                        className="bg-blue-500 hover:bg-blue-700 rounded-lg px-4 font-medium py-2 text-white cursor-pointer"
                                        >
                                      <FontAwesomeIcon icon={faPaperPlane} />
                                      </button>
                                      </div> 
                                      </div>
                                      </div>
                                      <ul className="flex flex-wrap flex-row-reverse">
                                      {messageAttachments.map((attachment) => {
                                      return (
                                      <li key={attachment.name} className="flex py-3 mr-2">
                                      <span className="pill small">
                                      {attachment.name}
                                      </span>
                                      <span
                                        title="Remove Attachment"
                                        className="small pill-button"
                                        onClick={() => deleteAttachment(attachment)}
                                      >
                                      <FontAwesomeIcon icon={faTrash} />
                                      </span> 
                                      </li>
                                      )
                                      })}
                                      </ul>
                                      </form>
                                      </div>
                                    </aside>
                                  </section>
                                </section>
                              </section>
                            </div>
                </div>
                </Dialog.Panel>
                </Transition.Child>
                </div>
                </div>
                </div>
            </Dialog>
          </Transition>

          <Transition show={showDocs} as={Fragment}>
            <Dialog as="div" className="relative z-[100]" onClose={() =>
                {setShowDocs(false)}}>
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-75"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-75"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  >
                  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>
                  <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                      <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                        <Transition.Child
                          as={Fragment}
                          enter="transform transition ease-in-out duration-500 sm:duration-700"
                          enterFrom="translate-x-full"
                          enterTo="translate-x-0"
                          leave="transform transition ease-in-out duration-500 sm:duration-700"
                          leaveFrom="translate-x-0"
                          leaveTo="translate-x-full"
                          >
                        <Dialog.Panel className="pointer-events-auto w-screen max-w-md xl:max-w-2xl">
                            <div className="flex h-full overflow-y-scroll xl:overflow-y-none flex-col bg-white py-6 shadow-xl">
                              <div className="px-4 sm:px-6">
                                  <div className="flex items-start justify-end">
                                      <div className="ml-3 flex h-7 items-center">
                                          <button
                                            type="button"
                                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500"
                                            onClick={() => setShowDocs(false)}
                                          >
                                            <span className="absolute -inset-2.5" />
                                            <span className="sr-only">Close panel</span>
                                            <FontAwesomeIcon icon={faCircleXmark} size="lg" />
                                          </button>
                                        </div>
                                    </div>
                                </div>
                              <div className="relative mt-2 flex-1 px-4 sm:px-6">
                                <section>
                                <section className="w-full bg-white py-4 px-4 mb-8">
          <h2 className="font-bold text-lg mb-4">Documents</h2>

          <div className="grid grid-cols-4 gap-3">
            {documents.length > 0 && documents?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="grid gap-2"
                >
                  <div className="flex flex-wrap items-center col-span-2">
                    <img
                      src={
                        ImageConfig[item.name.split('.')[1]] ||
                        ImageConfig['default']
                      }
                      alt=""
                      className="w-14 xl:w-16"
                    />

                    <div className="drop-file-preview__item__info text-sm xl:text-base break-all">
                      <p>{item.name}</p>
                    </div>
                  </div>

                  <div className="flex items-center justify-end space-x-2">
                    <div
                      className=" hover:text-red-700 cursor-pointer "
                      onClick={() => deleteFile(item)}
                    >
                      <FontAwesomeIcon
                        icon={faCircleXmark}
                        className="w-6 h-6 xl:w-8 xl:h-8 text-gray-700 hover:text-red-700"
                      />
                    </div>

                    <a href={item.url} target="_blank" rel="noreferrer">
                      <FontAwesomeIcon
                        icon={faDownload}
                        className="w-3 h-3 xl:w-4 xl:h-4 border border-green-200 text-emerald-700 cursor-pointer rounded-full bg-green-200 p-1.5 xl:p-2 hover:bg-transparent hover:border-emerald-700"/>
                    </a>
                  </div>
                </div>
              )
            })}
          </div>
        </section>
        <section className="w-full bg-white py-4 px-4 mb-8">
          <div className="flex items-center justify-between mb-4">
            <h2 className="font-bold text-lg">
              Upload Documents
            </h2>
            <button
              onClick={uploadDocuments}
              className="rounded-lg border border-teal-500 bg-teal-500 text-white px-4 py-2 flex items-center hover:bg-transparent hover:text-teal-500"
            >
              <FontAwesomeIcon icon={faUpload} />
              <span className="pl-2"> Save</span>
            </button>
          </div>
          <label
            htmlFor="file-upload"
            className="col-span-full"
            ref={wrapperRef}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
            onDragOver={onDragOver}
          >
            <label
              htmlFor="cover-photo"
              className="block text-sm font-medium leading-6 text-gray-900"
            ></label>
            <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
              <div className="text-center">
                {' '}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="mx-auto h-12 w-12 text-gray-300"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                  />
                </svg>
                <div className="mt-4 flex text-sm leading-6 text-gray-600 justify-center">
                  <div className="relative cursor-pointer rounded-md bg-white font-semibold text-nav focus-within:outline-none focus-within:ring-2 focus-within:ring-nav focus-within:ring-offset-2 hover:text-dark-purple">
                    <span>Drag and Drop or click to upload a file</span>
                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.csv,text/csv,.pdf,application/pdf,image/jpeg,image/png,image/tiff"
                      multiple
                      className="sr-only"
                      onChange={onFileUpload}
                    />
                  </div>
                  {/* <p className="pl-1">or drag and drop</p> */}
                </div>
                <p className="text-xs leading-5 text-gray-600">
                  PNG, JPG, CSV, XLS or PDF up to 10MB
                </p>
                <p className="error-message">{errorMsg}</p>
              </div>
            </div>
          </label>
          <div className="grid grid-cols-4 gap-3 mt-8">
            {fileList?.map((item, index) => (
              <div
                key={index}
                className="drop-file-preview__item flex items-center justify-between "
              >
                <div className="flex items-center">
                  <img
                    src={
                      ImageConfig[item.type.split('/')[1]] ||
                      ImageConfig['default']
                    }
                    alt=""
                    className="w-16"
                  />

                  <div className="drop-file-preview__item__info">
                    <p className="break-words">{item.name}</p>
                    <p>{item.size}</p>
                  </div>
                </div>

                <span
                  className=" hover:text-red-700 drop-file-preview__item__del"
                  onClick={() => fileRemove(item)}
                >
                  <FontAwesomeIcon
                    icon={faCircleXmark}
                    className=" text-gray-700 hover:text-red-700 w-8 h-8"
                  />
                </span>
              </div>
            ))}
          </div>
        </section>
                                </section>
                              </div>
                  </div>
                  </Dialog.Panel>
                  </Transition.Child>
                  </div>
                  </div>
                  </div>
              </Dialog>
          </Transition>

        <section className="">
          <div className="flex justify-between">
              <div>
                <span
                    className="inline-flex items-center text-slate-500 mb-10 cursor-pointer hover:text-slate-600"
                    onClick={goToClaims}
                    >
                    <FontAwesomeIcon icon={faChevronLeft} className="text-sm" />
                    <span className="text-sm ml-2">Go back</span>
                </span>
              </div>
              <div>
                {/* <button onClick={() => {openForm('Fusion')}} className="px-4 py-2 rounded-lg bg-violet-400 font-medium text-white hover:bg-violet-500 mr-2">Fusion Form</button>
                <button onClick={() => {openForm('GP')}} className="px-4 py-2 rounded-lg bg-violet-400 font-medium text-white hover:bg-violet-500 mr-2">GP Form</button> */}
                <button onClick={() => {openForm('OH Result')}} className="px-4 py-2 rounded-lg bg-violet-400 font-medium text-white hover:bg-violet-500 mr-2">OH Result Form</button>
                {/* <button onClick={() => {openForm('Private Med')}} className="px-4 py-2 rounded-lg bg-violet-400 font-medium text-white hover:bg-violet-500 mr-2">Private Med Form</button> */}
                <button onClick={() => {openForm('PRS')}} className="px-4 py-2 rounded-lg bg-violet-400 font-medium text-white hover:bg-violet-500 mr-2">PRS Form</button>
                <button onClick={() => {openForm('WBA')}} className="px-4 py-2 rounded-lg bg-violet-400 font-medium text-white hover:bg-violet-500 mr-2">WBA Form</button>
                {claimData?.claim_status_id !== 5 && (<button onClick={() => {updateStatus(5)}} className="px-4 py-2 rounded-lg bg-violet-400 font-medium text-white hover:bg-violet-500 mr-2">In House</button>)}
                <button onClick={() => {setShowComms(true)}} className="px-4 py-2 rounded-lg bg-violet-400 font-medium text-white hover:bg-violet-500 mr-2">Comms</button>
                <button onClick={() => {setShowDocs(true)}} className="px-4 py-2 rounded-lg bg-violet-400 font-medium text-white hover:bg-violet-500 mr-2">Docs</button>
                {editMode && (<button onClick={() => {handleUpdate()}} className="px-4 py-2 rounded-lg bg-blue-400 font-medium text-white hover:bg-blue-500 mr-2">Update</button>)}
                {!editMode && (<button onClick={handleEditClick} className="px-4 py-2 rounded-lg bg-violet-400 font-medium text-white hover:bg-violet-500">Edit</button>)}
                {editMode && (<button onClick={handleEditClick} className="px-4 py-2 rounded-lg bg-violet-400 font-medium text-white hover:bg-violet-500">Cancel edit</button>)}
              </div>
          </div>
          <div className="flex justify-around space-x-4 max-w-7xl mx-auto">
              <div className="mb-8 w-full">
                <div className="flex flex-col items-center">
                    <div className="bg-white rounded-lg shadow-lg p-4 w-full mb-8">
                      <h2 className="text-slate-800 font-bold mb-4">
                          Claim Details
                      </h2>
                      <div className="grid grid-cols-2 gap-4 mb-4">
                          <div>
                            <label htmlFor="" className="text-slate-800 font-semibold">
                            Reference
                            </label>
                            <p className="text-md text-violet-800 font-semibold">
                                {claimData?.reference}
                            </p>
                          </div>
                          <div>
                            <label htmlFor="" className="text-slate-800 font-semibold">
                            Contact Type
                            </label>
                            <p className="text-md text-violet-800 font-semibold">
                                {claimData?.contact_type?.title}
                            </p>
                          </div>
                          <div>
                            <label htmlFor="" className="text-slate-800 font-semibold">
                            Status
                            </label>
                            <p className="text-md text-violet-800 font-semibold">
                                {claimData?.status?.title.toUpperCase()}
                            </p>
                          </div>
                          <div className="">
                            {!editMode ? (
                            <div>
                                <label className="text-slate-800 font-semibold">
                                Claim Notes
                                </label>
                                <textarea
                                  disabled
                                  defaultValue={claimData?.description}
                                  className="bg-gray-100 border text-violet-800 font-semibold border-gray-300 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-violet-500 dark:focus:border-violet-500"
                                  />
                            </div>
                            ) : (
                            <div>
                                <label className="text-slate-800 font-semibold">
                                Claim Notes
                                </label>
                                <textarea
                                  defaultValue={claimData?.description}
                                  onChange={(e) => {claimData.description = e.target.value}}
                                className="bg-white border-2 border-violet-300 text-violet-800 font-semibold text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-violet-500 dark:focus:border-violet-500"
                                />
                            </div>
                            )}
                          </div>
                      </div>
                      <div className="mt-8"></div>
                    </div>
                </div>
              </div>
              <div className="mb-8 w-full">
                <InfoBanner messages={[`Status: ${claimData?.status?.title.toUpperCase()}`]} color={'blue'} />
                <InfoBanner messages={[`Created By: ${claimData?.created_by_user?.name}`]} color={'pink'} />
                <InfoBanner messages={[`Created At: ${moment(claimData?.created_at).format('DD/MM/YYYY')}`]} color={'green'} />
                <div className="">
                    <div className="bg-white rounded-lg shadow-lg p-4 w-full mb-8">
                      <h2 className="text-slate-800 font-bold mb-4">
                          Service Details
                      </h2>
                      <div>
                          {!editMode ? (
                          <p className="text-md text-violet-800 font-semibold">
                            {service?.name}
                          </p>
                          ) : (
                          <select 
                            onChange={(e) =>
                            setServiceProvider(JSON.parse(e.target.value).provider.id)} 
                            defaultValue={JSON.stringify(service)}
                            className='bg-white border-2 border-violet-300 text-violet-800 text-md rounded-md focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-violet-500 dark:focus:border-violet-500'>
                            {services?.map((item) => {
                            return (
                            <option value={JSON.stringify(item)}>{item.name}</option>
                            )
                            })}
                          </select>
                          )}
                      </div>
                      <div className="grid grid-cols-2 gap-4 mt-2 mb-4">
                          <div>
                            <label htmlFor="" className="text-slate-800 font-semibold">
                            No. Sessions
                            </label>
                            <p className="text-md text-violet-800 font-semibold">
                                {service?.default_session_allocation}
                            </p>
                          </div>
                          <div>
                            <label htmlFor="" className="text-slate-800 font-semibold">
                            Cost per session
                            </label>
                            <p className="text-md text-violet-800 font-semibold">
                                {service?.default_unit_cost_amount}
                            </p>
                          </div>
                      </div>
                    </div>
                </div>
              </div>
          </div>
        </section>

        {/* Form Slide Out */}
        <Transition show={showForm} as={Fragment}>
            <Dialog as="div" className="relative z-[100]" onClose={() => {setShowForm(false)}}>
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-75"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-75"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>
      
              <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                  <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                    <Transition.Child
                      as={Fragment}
                      enter="transform transition ease-in-out duration-500 sm:duration-700"
                      enterFrom="translate-x-full"
                      enterTo="translate-x-0"
                      leave="transform transition ease-in-out duration-500 sm:duration-700"
                      leaveFrom="translate-x-0"
                      leaveTo="translate-x-full"
                    >
                      <Dialog.Panel className="pointer-events-auto w-screen max-w-md xl:max-w-2xl">
                        <div className="flex h-full overflow-y-scroll xl:overflow-y-none flex-col bg-white py-6 shadow-xl">
                          <div className="px-4 sm:px-6">
                            <div className="flex items-start justify-between">
                              <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                {formPopOutTitle}
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="relative rounded-md bg-white text-gray-400 hover:text-gray-500"
                                  onClick={() => setShowForm(false)}
                                >
                                  <span className="absolute -inset-2.5" />
                                  <span className="sr-only">Close panel</span>
                                  <FontAwesomeIcon icon={faCircleXmark} size="lg" />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="relative mt-2 flex-1 px-4 sm:px-6">
                            {/* ToDo: Add Auditing to Forms */}
                            {selectedForm === 'WBA' && (
                              <WBAForm setShowForm={setShowForm}></WBAForm>
                            )}
                            {selectedForm === 'PRS' && (
                              <PRSForm setShowForm={setShowForm}></PRSForm>
                            )}
                            {selectedForm === 'OH Result' && (
                              <OHResultForm setShowForm={setShowForm} claim={claimData} policy={client.policy}></OHResultForm>
                            )}
                          </div>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </div>
            </Dialog>
          </Transition>
 </section>
  );
};

export default ViewClaim;
