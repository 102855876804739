import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function CardButton({ icon, title }) {
  return (
    <div className="p-6 bg-dark-purple border border-dark-purple rounded-md shadow flex items-center cursor-pointer hover:bg-nav-dark">
      <FontAwesomeIcon icon={icon} className="text-3xl text-white mr-4" />
      <h5 className="text-xl font-semibold tracking-tight text-white">
        {title}
      </h5>
    </div>
  )
}

export default CardButton
