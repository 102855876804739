import { faBell, faCircleInfo} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import Header from './Header'
import DateTime from './Clock'

function MainTitle({ title, status = false, backButton = false, links = false, icon = false}) {
  const navigate = useNavigate()
  const [showInfo, setShowInfo] = useState(false)
  const [weather, setWeather] = useState(null)

  const goBack = () => {
    navigate(-1)
  }

  const fetchWeather = async (lat, lon) => {
    try {
      const response = await fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${process.env.REACT_APP_WEATHER_API_KEY}&units=metric`);
      const data = await response.json();
      setWeather({
        temperature: data.main.temp,
        condition: data.weather[0].description,
        icon: `https://openweathermap.org/img/wn/${data.weather[0].icon}.png`
      });
    } catch (error) {
      console.error("Error fetching weather data:", error)
    }
  }

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        fetchWeather(position.coords.latitude, position.coords.longitude)
      },
      (error) => console.error("Error getting location:", error)
    );
  }, [])

  const toggleShowInfo = () => 
  {
    setShowInfo((prevState) => !prevState)
  }

  return (
    <div className="font-bold text-2xl w-full border-b-2 border-b-white mb-4 text-gray-700">
      <div className='flex items-center justify-between'>
        <h1 className='flex items-center space-x-2'>
          <span>{title}</span>
          {icon && (
          <span className='relative'>
            <FontAwesomeIcon icon={faCircleInfo} className='text-black hover:cursor-pointer' data-popover-target="popover" onClick={toggleShowInfo} />
          </span>
          )} 
         
        </h1>
        <div className='flex items-center space-x-2 text-base font-normal'>
            <div className='bg-white rounded-full flex items-center mb-2'> 
              <Header />
            </div>
          {links && (
            <div className='flex items-center space-x-2 text-base font-normal'>
              <a href="https://admin.kisdigital.co.uk/" target='blank' className='bg-[#31AAE1] hover:bg-[#2188C9] rounded-lg text-white font-medium shadow-md px-4 py-2 cursor-pointer'>KIS</a>
              <a href='https://app.makeadifferenceuk.com/' target='blank' className='bg-[#AB366A] hover:bg-[#DB567C] rounded-lg text-white font-medium shadow-md px-4 py-2 cursor-pointer'>WBA</a>
              <a href='https://schooladvice.co.uk/' target='blank' className='bg-[#8CCA94] hover:bg-[#394C4B] rounded-lg text-white font-medium shadow-md px-4 py-2 cursor-pointer'>SAS</a>
            </div>
          )}
          {/* <a href='https://schooladvice.co.uk/' target='blank' className='bg-nav hover:bg-nav-dark rounded-lg text-white font-medium shadow-md px-4 py-2 cursor-pointer'> <FontAwesomeIcon icon={faBell} className="" /></a> */}
        </div>
      </div>
     
      {status && (
        <div className="flex justify-between items-center my-2">
          <DateTime />
          {weather && (
            <div className="flex items-center bg-white rounded-lg shadow p-2">
              <img src={weather.icon} alt={weather.condition} className="w-12 h-12" />
              <div className="ml-3">
                <p className="text-lg font-semibold">{weather.temperature}°C</p>
                <p className="text-gray-600 capitalize text-base">{weather.condition}</p>
              </div>
            </div>
          )}
        </div>
      )}
      {backButton && (
        <span
          className="inline-flex items-center text-slate-500 mt-1 cursor-pointer hover:text-slate-600"
          onClick={goBack}
        >
          
          <span className="text-sm ml-2">Go Back</span>
        </span>
      )}
    </div>
  )
}

export default MainTitle
