import React from 'react'
import moment from 'moment';

function SuggestedCards({client, setSelected, isKis=true, reference = null, selectedKisClient=null, selectedWbaClient=null, selectable, highlightBypass=null}) {
  let classNames = isKis ? 'hover:border-sky-400 hover:shadow-lg': 'hover:border-violet-400 hover:shadow-lg';

  if(selectable && selectedKisClient && selectedKisClient.staff_id === client.staff_id && highlightBypass === null) {
    classNames += ' ring ring-blue-400 shadow-xl';
  }

  if(selectable && selectedWbaClient && selectedWbaClient.id === client.id) {
    classNames += ' ring ring-violet-400 shadow-xl';
  }

  if(selectable && selectedKisClient && selectedKisClient.id === client.id) {
    classNames += ' ring ring-violet-400 shadow-xl';
  }

  if(selectable)
  {
    classNames += ' cursor-pointer';
  }

  function extractPostcode(address) {
    const parts = address?.split(',');
    const postcode = parts[parts.length - 2]?.trim();
    return postcode ?? '';
  }

  let postcode = '';
  if (client.wba_link && client.client_details.home_address.length > 0) {
    postcode = extractPostcode(client?.client_details?.home_address);
  }

  return (
    <div className={"block max-w-sm p-4 bg-white border mx-3 border-gray-200 rounded-lg shadow-md text-wrap " + classNames}
        onClick={() => { if(selectable) {setSelected(client); return;}  return false;}}>
        <span className="flex flex-row mb-2 text-sm text-gray-900 font-bold space-x-1">
          <p>Name:</p>
          <p className="font-medium">{client.name}</p>
        </span>
        <span className="flex flex-row mb-2 text-sm text-gray-900 font-bold space-x-1">
          <p>Email:</p>
          <p className="font-medium">{client.email}</p>
        </span>
        <span className="flex flex-row mb-2 text-sm text-gray-900 font-bold space-x-1">
          <p>Date Of Birth:</p>
          <p className="font-medium">{moment(client.date_of_birth).format('DD/MM/YYYY')} </p>
        </span>
        <>
          {client.kis_link && (
            <span className="flex flex-row mb-2 text-sm text-gray-900 font-bold space-x-1">
              <p>Academy Postcode:</p>
              <p className="font-medium">{client.academy_postcode} </p>
            </span>
          )}
        </>
        <>
          {client.wba_link && (
          <span className="flex flex-row mb-2 text-sm text-gray-900 font-bold space-x-1">
              <p>Postcode:</p>
              <p className="font-medium">{postcode}</p>
            </span>
          )}
        </>
        
        {reference ? 
        <span className="flex flex-row mb-2 text-sm text-gray-900 font-bold space-x-1">
          <p>reference:</p>
          <p className="font-medium">{reference}</p>
        </span>
        : null }
    </div>
  )
}

export default SuggestedCards