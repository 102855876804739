import { NavLink, Link } from 'react-router-dom'
import logo from '../img/singlogolight.png'
import { useState } from 'react'
import { faCalendarDay, faChevronDown, faRightFromBracket, faTasks, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import useLogout from '../hooks/useLogout'
import useAuth from '../hooks/useAuth'

import AdminNav from '../components/Admin/Nav'

const navClasses =
  'w-full text-white p-1 flex relative rounded-md items-center'

function LeftBar({ collapsed, toggleSidebar }) {
  const { admin, auth, can } = useAuth()
  const logout = useLogout()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <aside className={`bg-nav fixed inset-0 z-50 my-4 ml-4 h-[calc(100vh-32px)] w-52 rounded-xl shadow-md ${collapsed ? 'hidden' : 'block'}`}>
      <div className="relative border-b border-b-white mx-4">
        <Link to="/" className="my-4">
          <img src={logo} alt="" className="h-10 my-4 mx-2" />
        </Link>
      </div>
      <div className="mx-5 flex flex-col relative h-[calc(100%-100px)]">
        <ul className="mb-4 flex flex-col gap-1 mt-4">
          <AdminNav />
        </ul>

        <ul className="border-t border-t-white pt-4">
        {can('access calendar') ? (
          <li className="mb-2">
            <NavLink
              to={`/users/${auth.user.id}/availability`}
              className={({ isActive }) =>
                isActive
                  ? 'text-nav-dark ' + navClasses
                  : navClasses + ' hover:text-nav-dark'
              }
            >
              <span className="flex items-center w-10">
                <FontAwesomeIcon icon={faCalendarDay} className='text-xl'/>
              </span>
              <div>Calendar</div>
            </NavLink>
          </li>
        ) : null}
          <li className="mb-2">
            <NavLink
               to={`/tasks`}
              className={({ isActive }) =>
                isActive
                  ? 'text-nav-dark ' + navClasses
                  : navClasses + ' hover:text-nav-dark'
              }
            >
              <span className="flex items-center w-10" >
                <FontAwesomeIcon icon={faTasks} className="text-xl" />
              </span>
              <div>Tasks</div>
            </NavLink>
          </li>
        </ul>
      </div>

      <div className="absolute bottom-0 bg-white w-full rounded-b-lg text-sm">
        <button
          onClick={toggleDropdown} 
          className="w-full text-nav-dark py-4 px-1 flex relative rounded-md items-center justify-between"
        >
          <img
            src={auth?.user.profile_pic}
            alt="User Profile"
            className="h-7 w-7 rounded-md ml-1"
          />
          <div className='text-left leading-3'>
            <div className='text-sm'>{auth?.user.name}</div>
            <div className='text-[10px]'>{auth?.user.email}</div>
          </div>
          <span className="flex items-center w-4">
            <FontAwesomeIcon
              icon={faChevronDown}
              className={`${isDropdownOpen ? 'rotate-180' : ''} transition-transform duration-300 text-xs`} 
            />
          </span>
        </button>

        {isDropdownOpen && (
          <div className='py-4 space-y-2 border-t border-nav mx-4'>
            <NavLink
              to={`/users/${auth.user.id}`}
              className={({ isActive }) =>
                  isActive
                    ? 'text-nav-dark ' + navClasses
                    : navClasses + ' hover:text-nav-dark text-nav'
                }
            >
              <FontAwesomeIcon icon={faUser} className="mr-4 text-lg" />
              My Profile
            </NavLink>
            <button
              onClick={logout}
              className=' hover:text-nav-dark text-nav'>
              <FontAwesomeIcon
                icon={faRightFromBracket}
                className="mr-4 ml-1 text-lg"
              />
              Logout
            </button>
          </div>
        )}
      </div>
    </aside>
  )
}

export default LeftBar
