/* eslint-disable */
import { useState, useEffect } from 'react'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import ClientTitle from '../../../components/ClientTitle'
import { useParams } from 'react-router-dom'
import useAuth from '../../../hooks/useAuth'
import ClientNav from '../../../components/Admin/ClientNav'
import InfoBanner from '../../../components/InfoBanner'
import moment from 'moment'
import { faThumbsUp, faThumbsDown, faChevronCircleDown, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PhoneNumberUtil } from 'google-libphonenumber'
import Modal from '../../../components/Modal'
import { useNavigate } from 'react-router-dom'
import ClientNotes from './ClientNotes'
import ClientHistory from './ClientHistory'
import ClientContacts from './ClientContacts'
import ClientClaims from './ClientReferrals'
import Enquiries from './ClientEnquiries'
import Documents from './Documents'
import Comms from './Comms'

const Client = () => {
  const { id } = useParams()
  const axios = useAxiosPrivate()
  const [editMode, setEditMode] = useState(false)
  const [editClientData, setEditClientData] = useState({})
  const [editClientDetails, setEditClientDetails] = useState({})
  const [contactMethods, setContactMethods] = useState([])
  const [editContactMethod, setEditContactMethod] = useState([])
  const [phoneNumber, setPhoneNumber] = useState('')
  const [yearsOld, setYearsOld] = useState('')
  const [isValid, setIsValid] = useState(true)
  const [activeTab, setActiveTab] = useState('details')

  //Modal consts
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [link, setLink] = useState({})
  const [type, setType] = useState('')
  const navigate = useNavigate()
  //
  const [showPossibleKisMatches, setShowPossibleKisMatches] = useState(false)
  const [showPossibleWbaMatches, setShowPossibleWbaMatches] = useState(false)

  const [isLoadingKisMatches, setIsLoadingKisMatches] = useState(false)
  const [isLoadingWbaMatches, setIsLoadingWbaMatches] = useState(false)

  const [kisClients, setKisClients] = useState([])
  const [wbaClients, setWbaClients] = useState([])

  const [selectedKisClient, setSelectedKisClient] = useState(null)
  const [selectedWbaClient, setSelectedWbaClient] = useState(null)

  const [openLinks, setOpenLinks] = useState(false)
  
  const [clientPolicy, setClientPolicy] = useState({})
  const [bannerImage, setBannerImage] = useState('')
  const [bannerColor, setBannerColor] = useState('')
  const [bannerBorderColor, setBannerBorderColor] = useState('')

  const [notEnoughClientInformation, setNotEnoughClientInformation] = useState(false)
  const [notOnLatestPolicy, setNotOnLatestPolicy] = useState(false)
  const [notOnLivePolicy, setNotOnLivePolicy] = useState(false)
  const [policyDueToExpire, setPolicyDueToExpire] = useState(false)
  const [expandedSection, setExpandedSection] = useState(null)
  const [clientData, setClientData] = useState({
    name: '',
    reference: 'HUB-1',
  })
  const { setShowLoader, createInfo } = useAuth()

  const handlePhoneNumberChange = (event) => {
    const { value } = event.target
    setPhoneNumber(value)
    const isValidNumber = validatePhoneNumber(value)
    setIsValid(isValidNumber)
  }

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberUtil = PhoneNumberUtil.getInstance()
    try {
      const parsedNumber = phoneNumberUtil.parse(phoneNumber, '+44')
      const isValidNumber = phoneNumberUtil.isValidNumber(parsedNumber)
      return isValidNumber
    } catch (e) {
      console.error('Error parsing phone number:', e)
      return false
    }
  }

  const toggleSection = (section) => {
    setExpandedSection(prevSection => (prevSection === section ? null : section)); // Toggle open/close section
  };

  const selectKisClient = (client) => {

    if (selectedKisClient && client.staff_id === selectedKisClient.staff_id) {
      setSelectedKisClient(null);

      client.kis_link = undefined;
      return;
    }

    client.kis_link = {
      'academy_urn': client.academy_urn,
      'academy_name': client.academy_name,
      'policy_reference': client.policy_reference,
      'policy_expire': client.policy_expire,
      'staff_id': client.staff_id,
      'email': client.email,
      'name': client.name,
      'date_of_birth': client.date_of_birth,
    }

    setSelectedKisClient(client);
  }

  const handleViewOrganisation = () => {
    navigate(`/organisations/${clientData.organisation.id}`);
  }

  const selectWbaClient = (client) => {

    if (selectedWbaClient && client.id === selectedWbaClient.id) {
      setSelectedWbaClient(null);
      return;
    }
    client.wba_link = {
      'id': client.client_id,
      'email': client.email,
      'name': client.name,
      'date_of_birth': client.date_of_birth,
      'reference': client.reference
    }


    setSelectedWbaClient(client);
  }


  const getPossibleKisMatches = async () => {

    if (isLoadingWbaMatches || isLoadingKisMatches) { return; }

    try {

      setShowLoader(true)
      let name = clientData.name.split(" ");
      const queryString = "&email=" + clientData.email
        + "&first_name=" + name[0]
        + "&last_name=" + name[1]
        + "&date_of_birth=" + clientData.client_details?.date_of_birth;

      let kisUrl = "https://test.kisdigital.co.uk/api/v1/clients/suggested?api_token=9e12909f-7aca-4513-aae7-28fd193cd3bb" + queryString;

      setIsLoadingKisMatches(true)

      const KisResponse = await axios.get(kisUrl)

      setSelectedKisClient(null)

      setKisClients([...KisResponse.data.clients])
      setIsLoadingKisMatches(false)
      setShowPossibleKisMatches(true)

      setShowLoader(false)
    } catch (error) {
      console.log(error)
      setIsLoadingKisMatches(false)
    }
  }

  const getPossibleWbaMatches = async () => {
    if (isLoadingWbaMatches || isLoadingKisMatches) { return; }
    let name = clientData.name.split(" ");
    try {
      setShowLoader(true)
      const queryString = "&email=" + clientData.email
        + "&first_name=" + name[0]
        + "&last_name=" + name[1]
        + "&date_of_birth=" + clientData.client_details?.date_of_birth;

      let wbaUrl = "https://dev.api.makeadifferenceuk.com/api/v1/clients/suggested?api_token=18fb44c7-1ef1-4056-8390-f311e2bf8a8e" + queryString;

      setIsLoadingWbaMatches(true)
      const wbaResponse = await axios.get(wbaUrl)

      setSelectedWbaClient(null)

      setWbaClients([...wbaResponse.data.clients])

      setIsLoadingWbaMatches(false)
      setShowPossibleWbaMatches(true)

      setShowLoader(false)
    } catch (error) {
      console.log(error)
      setIsLoadingWbaMatches(false)
    }
  }

  const handleInputChange = (fieldName, e) => {
    setEditClientData({ ...editClientData, [fieldName]: e.target.value })
  }

  const handleDetailsChange = (fieldName, e) => {
    setEditClientDetails({ ...editClientDetails, [fieldName]: e.target.value })
  }

  const handleContactMethod = (method) => {
    const updatedContactMethods = [...editContactMethod]
    const index = updatedContactMethods.findIndex(
      (contactMethod) => contactMethod.id === method.id
    )

    if (index !== -1) {
      updatedContactMethods.splice(index, 1)
    } else {
      updatedContactMethods.push(method)
    }

    setEditContactMethod(updatedContactMethods)
  }

  const handleEditClick = () => {
    setEditMode((prevState) => !prevState)
    setEditClientData({ ...clientData })
    setEditClientDetails({ ...clientData.client_details })
    setIsValid(true)
  }

  useEffect(() => {
    if (clientData.client_details?.phone_number) {
      setPhoneNumber(clientData.client_details.phone_number)
    }
  }, [clientData.client_details])

  const handleSaveClick = async () => {
    let data = {}
    data.client = editClientData
    data.client_details = editClientDetails

    if (selectedKisClient?.kis_link) {
      data.client.kis_link = JSON.stringify(selectedKisClient.kis_link)
    }

    if (selectedWbaClient?.wba_link) {
      data.client.wba_link = JSON.stringify(selectedWbaClient.wba_link)
    }

    const contactMethodIds = editContactMethod.map((method) => method.id)
    data.contact_method_ids = contactMethodIds

    try {
      setShowLoader(true)
      const response = await axios.post(`/clients/update/${id}`, data)
      setClientData(response.data.result)
      setShowPossibleKisMatches(false)
      setShowPossibleWbaMatches(false)
      setSelectedKisClient(false)
      setSelectedWbaClient(false)
      setEditMode(false)
    } catch (error) {
      console.error(error)
    }
    setShowLoader(false)
  }

  const refreshStaff = async () => {
    setShowLoader(true);
    let url = "http://localhost:7000/api/v1/singl/staff/refresh?api_token=9e12909f-7aca-4513-aae7-28fd193cd3bb"
    await axios.post(url, {staff_id: clientData.staff_id}).then((res) => {
      console.log(res.data)
      axios.post(`/clients/refresh/${id}`, res.data.data).then(() => {
        setShowLoader(false);
        createInfo('success', `Staff Updated`)
        setTimeout(() => {
          window.location.reload();
        }, 5000)
      });
    })
  }

  const getClientById = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/clients/${id}`)
      await setClientData(response.data.result)

      setClientPolicy(response.data.result.policy)
      
      getNumberOfYearsOld(response.data.result.client_details.date_of_birth)

      const contactMethodsArray = Object.values(
        response.data.result.contact_methods
      )
      setEditContactMethod(contactMethodsArray)

      let clientInformation = response.data.result.client_details

      if(!clientInformation?.phone_number || !clientInformation?.date_of_birth || !clientInformation.home_address) {
        setNotEnoughClientInformation(true)
      }

      let organisation = response.data.result.organisation
      let latestCompanyPolicy = JSON.parse(organisation.policy_information)[0]
      
      if(latestCompanyPolicy.id !== response.data.result.latest_policy_id) {
        setNotOnLatestPolicy(true)
      }

      if(latestCompanyPolicy.status !== 'live' && latestCompanyPolicy.status !== 'accepted') {
        setNotOnLivePolicy(true)
      }

      if(['accepted', 'pending'].includes(latestCompanyPolicy.status)) {
        setPolicyDueToExpire(true)
      }

      getDepartmentBannerAttributes(response.data.result.policy.department)
      if(response.data.result.policy.status == 'pending') {
        setBannerColor('bg-[#d4d5da]')
        setBannerBorderColor('border-[#d4d5da]')
      }

      setShowLoader(false)
    } catch (error) {
      console.log('failed to getClientById')
     }

     setShowLoader(false)
  }

  const getContactMethods = async () => {
    try {
      const response = await axios.get(`/contact-methods/all`)
      setContactMethods(response.data.result)
    } catch (error) { }
  }

  const formatter = new Intl.DateTimeFormat('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' });

  const getNumberOfYearsOld = (dob) => {
    let dateOfBirth = moment().diff(dob, 'years');
    setYearsOld(dateOfBirth)
  }

  useEffect(() => {
    getClientById(id)
    getContactMethods()
  }, [id])


  const openLinkClicked = (link, type) => {
    setOpenLinks(true);
    setLink(link);
    setType(type);
    const jsxTitle = (
      <span className='font-medium'>
        You are viewing {type === 'kis' ? 'KIS' : 'WBA'} link: {link.name}
      </span>
    );
    setModalTitle(jsxTitle);
    setShowModal(true);
  };


  const ModalBody = () => {
    if (openLinks) {
      return <LinkDetailsElement />
    }
  }


  const LinkDetailsElement = () => {
    return (
      <div>
        {type === 'kis' && (
          <>
            <div className="mb-3">
              Name: {JSON.parse(link.kis_link).name}
            </div>
            <div className="mb-3"><span>Email: {JSON.parse(link.kis_link).email}</span> </div>
            <div className="mb-3"><span>DOB: </span> {moment(JSON.parse(link.kis_link).date_of_birth).format('DD/MM/YYYY')} </div>
            <div className="mb-3"><span>Academy name: </span>{JSON.parse(link.kis_link)?.academy_name}</div>
            <div className="mb-3"><span>Academy URN: </span>{JSON.parse(link.kis_link)?.academy_urn}</div>
            <div className="mb-3"><span>Policy Reference: </span>{JSON.parse(link.kis_link)?.policy_reference}</div>
            <div className="mb-3"><span>Policy Expire: </span>{JSON.parse(link.kis_link)?.policy_expire}</div>
          </>
        )}
        {type === 'wba' && (
          <>
            <div className="mb-3">
              Name: {JSON.parse(link.wba_link).name}
            </div>
            <div className="mb-3"><span>Email: {JSON.parse(link.wba_link).email}</span> </div>
            <div className="mb-3"><span>DOB: </span> {moment(JSON.parse(link.wba_link).date_of_birth).format('DD/MM/YYYY') ?? ''} </div>
            <div className="mb-3"><span>Reference: </span>{JSON.parse(link.wba_link)?.reference}</div>
          </>
        )}
        <div className="flex mt-2 w-full">
          <button
            type="button"
            className="btn red mt-4 w-full"
            onClick={resetModal}>
            Close
          </button>
        </div>
      </div>
    )
  }

  const resetModal = () => {
    setShowModal(false)
    setOpenLinks(false)
    setType('');
    setModalTitle('')
  }
  
  const getDepartmentBannerAttributes = (department) => {
    switch(department) {
      case "SAS":
        setBannerImage("/images/logo_sas.png")
        setBannerColor("bg-[#31aae1]")
        setBannerBorderColor("border-[#31aae1]")
        return
      case "AIS":
        setBannerImage("/images/logo_ais.png")
        setBannerColor("bg-[#FFD324]")
        setBannerBorderColor("border-[#FFD324]")
        return
      case "Mutual":
        setBannerImage("/images/logo_schools_mutual.png")
        setBannerColor("bg-[#31aae1]")
        setBannerBorderColor("border-[#31aae1]")
        return
      case "SWB":
        setBannerImage("/images/swb-logo-heart.png")
        setBannerColor("bg-[#e7a3ca]")
        setBannerBorderColor("border-[#e7a3ca]")
        return
      case "GBS":
        setBannerImage("/images/logo_gbs.png")
        setBannerColor("bg-[#58cc6c]")
        setBannerBorderColor("border-[#58cc6c]")
        return
      default:
        setBannerImage("/images/logo_naht.png")
        setBannerColor("bg-[#4E5F80]")
        setBannerBorderColor("border-[#4E5F80]")
        return
    }
  }

  return (
    <div>
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}
      <div className='flex space-x-6 text-gray-600 max-w-[74rem] 2xl:max-w-none'>
        <div className='w-2/3'>
          <ClientTitle
            client={clientData}
            status={clientData.status}
            backButton
          />
          <ClientNav activeTab={activeTab} setActiveTab={setActiveTab}  />
          {activeTab === 'details' &&
            <div>
              <div className='bg-white rounded-lg p-4 mb-4'>
                <div className='flex items-center justify-between cursor-pointer' onClick={() => toggleSection('claims')}>
                  <div>
                    <div className='font-semibold text-xl'>Referrals</div>
                    <div className='text-gray-400 text-xs'>Click to Expand</div>
                  </div>
                  <div><FontAwesomeIcon icon={expandedSection === 'claims' ? faChevronCircleDown : faChevronCircleRight} className='text-nav-dark'/></div>
                </div>
                {expandedSection === 'claims' && <ClientClaims />}
              </div>
              <div className='bg-white rounded-lg p-4 mb-4'>
                <div className='flex items-center justify-between cursor-pointer' onClick={() => toggleSection('contacts')}>
                  <div>
                    <div className='font-semibold text-xl'>Contacts</div>
                    <div className='text-gray-400 text-xs'>Click to Expand</div>
                  </div>
                  <div><FontAwesomeIcon icon={expandedSection === 'contacts' ? faChevronCircleDown : faChevronCircleRight} className='text-nav-dark' /></div>
                </div>
                {expandedSection === 'contacts' && <ClientContacts />}
              </div>
              <div className='bg-white rounded-lg p-4 mb-4'>
                <div className='flex items-center justify-between cursor-pointer' onClick={() => toggleSection('history')}>
                  <div className='mb-4'>
                    <div className='font-semibold text-xl'>History</div>
                    <div className='text-gray-400 text-xs'>Click to Expand</div>
                  </div>
                  <div><FontAwesomeIcon icon={expandedSection === 'history' ? faChevronCircleDown : faChevronCircleRight} className='text-nav-dark' /></div>
                </div>
                {expandedSection === 'history' && <ClientHistory />} 
              </div>
            </div>
          }
          {activeTab === 'enquiries' && <Enquiries />}
          {activeTab === 'comms' && <Comms />}
          {activeTab === 'documents' && <Documents />}
        </div>
        <div className='w-1/3 space-y-4 mt-10'>
        {clientPolicy && (
          <div className={"w-full flex justify-center"}>
            <div id="policyBanner" className={"w-full items-center p-4 border rounded-lg shadow-md text-center flex justify-between text-white " + bannerColor + " " + bannerBorderColor}>

              {clientPolicy?.department == 'SWB' ? (
                <div className={" text-center"}>
                  <img className={"mx-auto"} width="40" src={bannerImage} />
                </div>
              ) : (
                <div className={" text-center"}>
                  <img className={"mx-auto"} width="120" src={bannerImage} />
                </div>
              )}
              
              <div className={" text-center"}>
                <div>
                  <p className={"text-lg font-bold mb-2"}>{clientPolicy?.product}</p>
                </div>

                <div>
                  <p className={"text-xs font-medium"}> {moment(clientPolicy.inception).format('DD/MM/YYYY')} -  {moment(clientPolicy.expire).format('DD/MM/YYYY')}</p>
                </div>
              </div>

              <div className={" text-center"}>
                  <div className={"text-center flex justify-center items-center mb-2"}>
                    {clientPolicy.status == 'live' && (
                      <FontAwesomeIcon icon={faThumbsUp} transform={"grow-5"}></FontAwesomeIcon>
                    )}

                    {clientPolicy.status == 'previous' && (
                      <FontAwesomeIcon icon={faThumbsDown} transform={"grow-5"}></FontAwesomeIcon>
                    )}

                    {clientPolicy.status != 'live' && clientPolicy.status != 'previous' && (
                      <FontAwesomeIcon icon={faThumbsUp} className={"rotate-90"} transform={"grow-5"}></FontAwesomeIcon>
                    )}
                  </div> 
                  <p className={"text-sm font-semibold mt-2"}>{clientPolicy.status}</p>
              </div>
            </div>
          </div>
          )}
          <ClientNotes />
          

          {clientData.id && (
            <section>
              {notEnoughClientInformation && (
                <div className={"my-4"}>
                  <InfoBanner messages={["Client Information Missing!"]} icon={true}></InfoBanner>
                </div>
              )}
              {notOnLatestPolicy ? (
                <div className={"my-4"}>
                  <InfoBanner messages={["Client Not On Latest Policy!"]} icon={true}></InfoBanner>
                </div>
              ) : (
                <>
                  {notOnLivePolicy && (
                    <div className={"my-4"}>
                      <InfoBanner messages={["Client Not On Live Policy!"]} color={"red"} icon={true}></InfoBanner>
                    </div>
                  )}
                  {policyDueToExpire && (
                    <div className={"my-4"}>
                      <InfoBanner messages={["Policy Due To Expire!"]} color={"red"} icon={true}></InfoBanner>
                    </div>
                  )}
                </>
              )}
              {notOnLivePolicy && (
                <div className={"my-4"}>
                  <InfoBanner messages={["Client Not On Live Policy!"]} color={"red"} icon={true}></InfoBanner>
                </div>
              )}
              {policyDueToExpire && (
                <div className={"my-4"}>
                  <InfoBanner messages={["Policy Due To Expire!"]} color={"red"} icon={true}></InfoBanner>
                </div>
              )}
            </section>
          )}
        </div>

      </div>
      
    </div>
  )
}

export default Client
