/* eslint-disable */
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import MainTitle from '../../../components/MainTitle'
import useAuth from '../../../hooks/useAuth'
import { useState, useEffect, Fragment } from 'react'
import Pagination from '../../../components/Pagination';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faEye, faSortUp, faSort, faSortDown, faPlus, faChevronDown, faCircleXmark, faPlusCircle, faCirclePause, faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import Modal from '../../../components/Modal'
import { Menu, Transition, Dialog } from '@headlessui/react'
import ClientNav from '../../../components/Admin/ClientNav'
import ContactForm from '../../../components/ContactForm'
import CardButton from '../../../components/CardButton'

const OrgContacts = () => {
  const { id } = useParams()
  const axios = useAxiosPrivate()
  const { setShowLoader, createInfo } = useAuth()

  //
  const [clientData, setClientData] = useState({
    name: '',
    reference: 'HUB-1',
  })
  
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending',
  });

  // search
  const [filteredRecords, setFilteredRecords] = useState([])
  const [nPages, setNumberOfPages] = useState(0)
  const [currentRecords, setCurrentRecords] = useState([])
  const [showModal, setShowModal] = useState(false)
  const navigate = useNavigate();

  // No of Records to be displayed on each page
  const [currentPage, setCurrentPage] = useState(1)
  const [recordsPerPage] = useState(10)
  const [indexOfLastRecord, setIndexOfLastRecord] = useState(10)
  const [indexOfFirstRecord, setIndexOfFirstRecord] = useState(0)
  const [originalRecords, setOriginalRecords] = useState([]);
  const [columnClickCounts, setColumnClickCounts] = useState({});

  const [contacts, setContacts] = useState([])
  const [selectedClient, setSelectedClient] = useState('')
  const [selectedContact, setSelectedContact] = useState('')
  const [selectedContactTypeID, setContactTypeID] = useState('')
  const [selectedDescription, setSelectedDescription] = useState('')
  const [selectedContactOutcomeID, setSelectedContactOutcomeID] = useState('')
  const [selectedCreatedAt, setSelectedCreatedAt] = useState('')
  const [selectedCreatedBy, setSelectedCreatedBy] = useState('')
  const [selectedWithdrawalStatus, setSelectedWithdrawalStatus] = useState('')
  const [selectedWithdrawalReason, setSelectedWithdrawalReason] = useState('')
  const [contactPopOutTitle, setContactPopOutTitle] = useState('')
  const [showContactForm, setShowContactForm] = useState(false)
  const [successfulCreation, setSuccessfulCreation] = useState(false)
  const [markedAsWithdrawn, setMarkedAsWithdrawn] = useState(false)
  
  const [search, setSearch] = useState('')
  const [statusFilter, setStatusFilter] = useState('');

  const getClientById = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/clients/${id}`)
      await setClientData(response.data.result)
      setSelectedClient(response.data.result.id)
      setShowLoader(false)
    } catch (error) {}
  }

  const handleSort = (key) => {
    let newColumnClickCounts = { ...columnClickCounts };
    if (!newColumnClickCounts[key]) {
      newColumnClickCounts[key] = 1;
    } else {
      newColumnClickCounts[key]++;
    }

    if (newColumnClickCounts[key] === 3) {
      setSortConfig({
        key: null,
        direction: 'descending',
      });
      newColumnClickCounts[key] = 0;
      setCurrentRecords(originalRecords.slice(
        indexOfFirstRecord,
        indexOfLastRecord
      ));
    } else {
      let direction =
        sortConfig.direction === 'ascending'
          ? 'descending'
          : 'ascending';
      setSortConfig({ key, direction });
  
      const sortedRecords = sortArray(
        originalRecords,
        key,
        direction
      );
      setCurrentRecords(sortedRecords.slice(
        indexOfFirstRecord,
        indexOfLastRecord
      ));
    }
    setColumnClickCounts(newColumnClickCounts);
  };

  const sortArray = (array, key, direction) => {
    return [...array].sort((a, b) => {
      
      const segments = key.split('.');
      let aKey = a;
      let bKey = b;

      segments.forEach(element => {
        if(!aKey[element]) {
          aKey = '';
          return;
        }

        if(!bKey[element]) {
          bKey = '';
          return;
        }

        aKey = aKey[element];
        bKey = bKey[element];
      });

      if (aKey < bKey) return direction === 'ascending' ? -1 : 1;
      if (aKey > bKey) return direction === 'ascending' ? 1 : -1;
      return 0;
    });
  };

  const viewContactClicked = (id, contact_type_id, description, contact_outcome_id, created_at, created_by_name, withdrawal_status, withdrawal_reason) => {
    setSelectedContact(id)
    setContactTypeID(contact_type_id)
    setSelectedDescription(description)
    setSelectedContactOutcomeID(contact_outcome_id)
    setSelectedCreatedAt(moment(created_at).utc().format('DD/MM/YYYY HH:mm:ss'))
    setSelectedCreatedBy(created_by_name)
    setSelectedWithdrawalStatus(withdrawal_status)
    setSelectedWithdrawalReason(withdrawal_reason)
    setContactPopOutTitle('View Contact')
    setShowContactForm(true)
  }

  const viewCreateContact = (id) => {
    setSelectedContact(null)
    setContactPopOutTitle('Create Contact')
    setShowContactForm(true)
  }

  const handleSearch = (handle) => {
    setIndexOfFirstRecord(0)
    setIndexOfLastRecord(10)
    setCurrentPage(0)
    setCurrentRecords(filteredRecords.slice(0, 10))

    let searchValue = handle.target.value
    let searchString = searchValue.trim().toLowerCase()
    if (searchString.length > 0) {
      let records = contacts.filter((e) => {
        return (
          e.description.toLowerCase().match(searchString)
        )
      })
      setFilteredRecords(records)
      setCurrentRecords(records.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(records.length / recordsPerPage))
    } else {
      setFilteredRecords(contacts)
      setCurrentRecords(contacts.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(contacts.length / recordsPerPage))
    }
  }

  const getClientContacts = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/contacts/by/` + id)
      let filteredContacts = response.data.result;
      if (statusFilter) {
        filteredContacts = filteredContacts.filter(contact => contact.statuses.title.toLowerCase() === statusFilter);
      }
      if (filteredContacts) {
        await setContacts(filteredContacts)
        await setOriginalRecords(filteredContacts)
        await setCurrentRecords(filteredContacts)
        await setFilteredRecords(filteredContacts)
        setShowLoader(false)
      }
    } catch (error) {
      console.log(error)
      setShowLoader(false)
    }
    setShowLoader(false)
  }

  const nextPage = () => {
    if (currentPage < nPages) {
      const newPage = currentPage + 1;
      const indexFirst = newPage * recordsPerPage - recordsPerPage;
      const indexLast = newPage * recordsPerPage;
  
      setIndexOfFirstRecord(indexFirst);
      setIndexOfLastRecord(indexLast);
      setCurrentPage(newPage);
      const sortedRecords = sortArray(originalRecords, sortConfig.key, sortConfig.direction);
      setCurrentRecords(sortedRecords.slice(indexFirst, indexLast));
    }
  }
  
  const previousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      const indexFirst = (newPage - 1) * recordsPerPage;
      const indexLast = newPage * recordsPerPage;
  
      setIndexOfFirstRecord(indexFirst);
      setIndexOfLastRecord(indexLast);
      setCurrentPage(newPage);
  
      const sortedRecords = sortArray(originalRecords, sortConfig.key, sortConfig.direction);
      setCurrentRecords(sortedRecords.slice(indexFirst, indexLast));
    }
  }

  const resetModal = () => {
    setShowModal(false)
    setAssignUser(false)
    setModalTitle('')
  }

  const renderSortArrow = (columnKey) => {
    if (sortConfig.key === columnKey) {
      if (sortConfig.direction === 'ascending') {
        return <FontAwesomeIcon icon={faSortUp} />;
      } else {
        return <FontAwesomeIcon icon={faSortDown} />;
      }
    }
    return <FontAwesomeIcon icon={faSort} />;
  };

  useEffect(() => {
    if(id) {
      getClientContacts(id)
      getClientById(id)
    }
  }, [id])

  useEffect(() => {
    if(successfulCreation == true) {
      createInfo('success', 'Contact Created')
      getClientContacts()
      setShowContactForm(false)
    }
  }, [successfulCreation])

  useEffect(() => {
    if(markedAsWithdrawn == true) {
      createInfo('success', 'Contact Marked As Withdrawn')
      getClientContacts()
      setShowContactForm(false)
    }
  }, [markedAsWithdrawn])

  return (
    <div className={showContactForm && 'overflow-y-none'}>
       {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}
      <div className='w-full'>
        <div className='flex items-center justify-between'>
          <h2>Create Note</h2>
          <button type="submit" className="btn">
            <FontAwesomeIcon icon={faCirclePlus} />
            <span className='pl-2'>Add Note</span>
          </button>
        </div>
        <div className="w-1/2 mb-6 mr-3 cursor-pointer">
          <label className='text-xs' htmlFor="">Description <span className='text-red-500'>*</span></label>
          <textarea
            required
            className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md focus:ring-violet-500 focus:border-violet-500"
          />
        </div>
      </div>

      {clientData.id && (
        <section>
          <div className='overflow-x-scroll relative !overflow-auto'>
            <div className="flex items-center justify-between">
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    onClick={handleSearch}
                  />
                </div>
                <input
                  type="text"
                  id="table-search"
                  onChange={handleSearch}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block w-80 pl-10 p-2.5  "
                  placeholder="Search Contacts"
                />
              </div>
              <div onClick={viewCreateContact}>
                <FontAwesomeIcon icon={faPlusCircle} className='text-lg text-nav hover:text-nav-dark cursor-pointer' />
              </div>
            </div>
            <table className="table-main mt-4 mb-10">
                <thead>
                  <tr>
                    <th className='w-8'></th>
                    <th className='cursor-pointer' scope="col" onClick={() => handleSort('contact.contact_type')}>Contact Type{renderSortArrow('contact.contact_type')}</th>
                    <th className='cursor-pointer' scope="col" onClick={() => handleSort('contact.description')}>Contact Details{renderSortArrow('contact.description')}</th>
                    <th className='cursor-pointer' scope="col" onClick={() => handleSort('contact.created_at')}>Created On{renderSortArrow('contact.created_at')}</th>
                    <th className='cursor-pointer' scope="col" onClick={() => handleSort('contact.created_by_name')}>Created By{renderSortArrow('contact.created_by_name')}</th>
                    <th className='cursor-pointer' scope="col" onClick={() => handleSort('contact.withdrawal_reason')}>Withdrawal Reason{renderSortArrow('contact.withdrawal_reason')}</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                {currentRecords.length > 0 ? (
                  currentRecords?.map((contact) => {
                    return (
                      <tr key={contact.id}>
                        <td className="w-2">
                          {contact.withdrawn == '1' && (
                            <div className="h-2 w-2 rounded-full bg-red-500"></div>
                          )}
                        </td>
                        <td>{contact.contact_type?.title}</td>
                        <td>{contact?.description.length > 35 ? (
                          contact?.description.substring(0, 32) + '...'
                        ) : (
                          contact?.description
                        )}</td>
                        <td>{moment(contact.created_at).utc().format('DD/MM/YYYY HH:mm:ss')}</td>
                        <td>{contact.created_by_name}</td>
                        <td>{contact.withdrawn_reason ? contact.withdrawn_reason : ''}</td>
                        <td>
                          <Menu as="div" className=" inline-block text-left">
                            <div>
                              <Menu.Button className="flex items-center rounded-md cursor-pointer ml-2 hover:bg-gray-300 px-2 py-1">
                                <span className="font-semibold text-sm mr-2">
                                  Actions
                                </span>
                                <FontAwesomeIcon icon={faChevronDown} />
                              </Menu.Button>
                            </div>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="z-50 absolute right-0 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="p-2 space-y-2 ">
                                  <Menu.Item>
                                    <div onClick={() => viewContactClicked(
                                      contact.id, 
                                      contact.contact_type_id, 
                                      contact.description, 
                                      contact.contact_outcome_id,
                                      contact.created_at,
                                      contact.created_by_name,
                                      contact.withdrawn,  
                                      contact.withdrawn_reason
                                      )}  title="View Contact" className="flex justify-start items-center px-2 py-1 rounded-md hover:bg-blue-200 cursor-pointer">
                                      <FontAwesomeIcon icon={faEye} className='text-blue-800' /> <div className='pl-2'>View</div>
                                    </div>
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={8}>
                      <p className="no-records">No contacts found</p>
                    </td>
                  </tr> 
                )}
                </tbody>
            </table>
            <Pagination
              next={nextPage}
              prev={previousPage}
              first={indexOfFirstRecord}
              last={indexOfLastRecord}
              total={filteredRecords.length}
            />
          </div>
          <Transition show={showContactForm} as={Fragment}>
            <Dialog as="div" className="relative z-[100]" onClose={() => {setShowContactForm(false)}}>
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-75"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-75"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>
      
              <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                  <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                    <Transition.Child
                      as={Fragment}
                      enter="transform transition ease-in-out duration-500 sm:duration-700"
                      enterFrom="translate-x-full"
                      enterTo="translate-x-0"
                      leave="transform transition ease-in-out duration-500 sm:duration-700"
                      leaveFrom="translate-x-0"
                      leaveTo="translate-x-full"
                    >
                      <Dialog.Panel className="pointer-events-auto w-screen max-w-md xl:max-w-2xl">
                        <div className="flex h-full overflow-y-scroll xl:overflow-y-none flex-col bg-white shadow-xl">
                          <div className="bg-nav">
                            <div className="flex items-start justify-between p-6">
                              <Dialog.Title className="text-xl font-semibold leading-6  text-white">
                                {contactPopOutTitle} {selectedWithdrawalStatus == 1 && (<span className={"rounded-xl bg-red-400 py-1 px-2 text-white text-sm ml-2"}>Withdrawn</span>)}
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="relative text-white hover:text-nav-dark"
                                  onClick={() => setShowContactForm(false)}
                                >
                                  <span className="absolute -inset-2.5" />
                                  <span className="sr-only">Close panel</span>
                                  <FontAwesomeIcon icon={faCircleXmark} size="lg" />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="relative mt-2 flex-1 px-4 sm:px-6">
                            <ContactForm 
                            contact_id={selectedContact} 
                            contact_type_id={selectedContactTypeID} 
                            description={selectedDescription} 
                            contact_outcome_id={selectedContactOutcomeID} 
                            contactable_id={selectedClient} 
                            contactable_type="Client" 
                            created_at={selectedCreatedAt} 
                            created_by={selectedCreatedBy}
                            withdrawal_status={selectedWithdrawalStatus}
                            withdrawal_reason={selectedWithdrawalReason}
                            getClientContacts={getClientContacts} 
                            setSuccessfulCreation={setSuccessfulCreation}
                            setMarkedAsWithdrawn={setMarkedAsWithdrawn} />
                          </div>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </div>
            </Dialog>
          </Transition>
        </section>
      )}
    </div>
  )
}

export default OrgContacts
