import MainTitle from './MainTitle'
import TuiCalendar from './TuiCalendar'
import { useParams, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import useAuth from '../hooks/useAuth'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import UserNav from './UserNav'

function AvailabilityCalendar() {
  const axios = useAxiosPrivate()

  const [calendarType, setCalendarType] = useState('bookings')
  const [currentEvents, setCurrentEvents] = useState([])

  const { auth, setShowLoader } = useAuth()
  const path = useParams()
  const navigate = useNavigate()

  const goBack = () => {
    navigate(-1)
  }

  const loadCalendarEvents = async (from, to) => {
    setShowLoader(true)
    try {
      const response = await axios.get(
        `/users/${path.id}/availability/${from}/${to}`
      )
      setCurrentEvents(response.data.result)
    } catch (error) {}
    // }
    setShowLoader(false)
  }

  return (
    <section>
      <MainTitle title="User Availability" />

      <UserNav active="availability" userId={path.id} />

      <TuiCalendar
        events={currentEvents}
        loadEvents={loadCalendarEvents}
        calendarType="availability"
        userId={path.id}
      />
    </section>
  )
}

export default AvailabilityCalendar
