import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAuth from '../hooks/useAuth';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EditClientSlideOut = ({ open, setOpen, client }) => {
  const { setShowLoader, createInfo } = useAuth()
  const axios = useAxiosPrivate()
  const [error, setError] = useState('')
  const [contactMethods, setContactMethods] = useState([])
  const [editContactMethod, setEditContactMethod] = useState([])
  const [formValues, setFormValues] = useState({
    name: '',
    pronouns: '',
    date_of_birth: '',
    email: '',
    phone_number: '',
    home_address: '',
    preferred_contact_time: '',
    preferred_method_of_contact: ''
  });

  useEffect(() => {
    if (client) {
      setFormValues({
        name: client.name || '',
        pronouns: client.client_details?.pronouns || '',
        date_of_birth: client.client_details?.date_of_birth || '',
        email: client.email || '',
        phone_number: client.client_details?.phone_number || '',
        home_address: client.client_details?.home_address || '',
        preferred_contact_time: client.client_details?.preferred_contact_time || '',
        preferred_method_of_contact: client.contact_methods?.join(', ') || ''
      })
    }
  }, [client])

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }))
  }

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const validatePhoneNumber = (phone_number) => {
    const phoneRegex = /^[0-9]{10,15}$/
    return phoneRegex.test(phone_number)
  }

  const handleContactMethodChange = (e) => {
    const methodId = parseInt(e.target.value, 10);
    setEditContactMethod((prevMethods) => {
      if (e.target.checked) {
        return [...prevMethods, contactMethods.find((method) => method.id === methodId)];
      } else {
        return prevMethods.filter((method) => method.id !== methodId);
      }
    });
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault()
  
    if (!validateEmail(formValues.email)) {
      setError('Invalid email format')
      toast.error('Invalid email format')
      return
    }
    if (!validatePhoneNumber(formValues.phone_number)) {
      setError('Invalid phone number')
      toast.error('Invalid phone number')
      return
    }
  
    const contactMethodIds = editContactMethod.map((method) => method.id)
  
    const payload = {
      name: formValues.name,
      email: formValues.email,
      contact_methods: contactMethodIds,
      client_details: {
        date_of_birth: formValues.date_of_birth,
        home_address: formValues.home_address,
        phone_number: formValues.phone_number,
        preferred_contact_time: formValues.preferred_contact_time,
        pronouns: formValues.pronouns,
      },
    }
  
    try {
      setShowLoader(true);
      const response = await axios.post(`/clients/update/${client.id}`, payload)
      if (response.status === 200) {
        createInfo('success', 'Client updated successfully')
        toast.success('Client updated successfully')
        setOpen(false)
        setFormValues({
          ...formValues,
          contact_methods: editContactMethod,
        });
      }
    } catch (error) {
      setError('Failed to update client')
      console.error(error)
      toast.error('Failed to update client')
    } finally {
      setShowLoader(false)
    }
  }

  const getContactMethods = async () => {
    try {
      const response = await axios.get(`/contact-methods/all`)
      setContactMethods(response.data.result)
    } catch (error) { }
  }

  useEffect(() => {
    getContactMethods()
  }, [])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[100]" onClose={setOpen}>
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="bg-nav px-4 py-6 sm:px-6">
                      <div className="flex items-center justify-between">
                        <div>
                          <Dialog.Title className="text-lg font-semibold leading-6 text-white">
                            Editing: {client.name}
                          </Dialog.Title>
                          <div className="text-xs text-white">
                            Please complete the required information below.
                          </div>
                        </div>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-nav text-white hover:text-white focus:outline-none"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <FontAwesomeIcon icon={faCircleXmark} />
                          </button>
                        </div>
                      </div>
                    </div>
                    <form onSubmit={handleSubmit}>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6">
                        <div className="px-4 py-2">
                          <div className="text-nav-dark text-lg font-medium">
                            Details
                          </div>
                          <div className="mt-4 w-full flex-col">
                            <label htmlFor="name">
                              Name <span className="text-red-500">*</span>
                            </label> <br />
                            <input
                              type="text"
                              name="name"
                              value={formValues.name}
                              onChange={handleInputChange}
                              required
                              className="border border-nav-dark rounded-md py-2 px-4 cursor-pointer"
                              style={{ width: '100%' }}
                            />
                          </div>
                          <div className="flex flex-col mt-4">
                            <label htmlFor="pronouns">
                              Pronouns
                            </label>
                            <input
                              type="text"
                              name="pronouns"
                              value={formValues.pronouns}
                              onChange={handleInputChange}
                              required
                              className="border border-nav-dark rounded-md py-2 px-4 cursor-pointer"
                              style={{ width: '100%' }}
                            />
                          </div>
                          <div className="flex flex-col mt-4">
                            <label htmlFor="date_of_birth">
                              Date of Birth <span className="text-red-500">*</span>
                            </label>
                            <input
                              type="date"
                              name="date_of_birth"
                              value={moment(formValues.date_of_birth).format('YYYY-MM-DD')}
                              onChange={handleInputChange}
                              required
                              className="border border-nav-dark rounded-md py-2 px-4 cursor-pointer"
                              style={{ width: '100%' }}
                            />
                          </div>
                          <div className="flex flex-col mt-4">
                            <label htmlFor="email">
                              Email <span className="text-red-500">*</span>
                            </label>
                            <input
                              type="email"
                              name="email"
                              value={formValues.email}
                              onChange={handleInputChange}
                              required
                              className="border border-nav-dark rounded-md py-2 px-4 cursor-pointer"
                              style={{ width: '100%' }}
                            />
                          </div>
                          <div className="flex flex-col mt-4">
                            <label htmlFor="phone_number">
                              Telephone <span className="text-red-500">*</span>
                            </label>
                            <input
                              type="text"
                              name="phone_number"
                              value={formValues.phone_number}
                              onChange={handleInputChange}
                              required
                              className="border border-nav-dark rounded-md py-2 px-4 cursor-pointer"
                              style={{ width: '100%' }}
                            />
                          </div>
                          <div className="flex flex-col mt-4">
                            <label htmlFor="home_address">
                              Address <span className="text-red-500">*</span>
                            </label>
                            <input
                              type="text"
                              name="home_address"
                              value={formValues.home_address}
                              onChange={handleInputChange}
                              required
                              className="border border-nav-dark rounded-md py-2 px-4 cursor-pointer"
                              style={{ width: '100%' }}
                            />
                          </div>
                          <div className="text-nav-dark text-lg font-medium mt-4">
                            Contact Preferrences
                          </div>
                          <div className="flex flex-col mt-4">
                            <label htmlFor="preferred_contact_time">
                              Preferred Contact Time <span className="text-red-500">*</span>
                            </label>
                            <input
                              type="text"
                              name="preferred_contact_time"
                              value={formValues.preferred_contact_time}
                              onChange={handleInputChange}
                              required
                              className="border border-nav-dark rounded-md py-2 px-4 cursor-pointer"
                              style={{ width: '100%' }}
                            />
                          </div>
                          <div className="flex flex-col mt-4">
                            <label htmlFor="preferred_method_of_contact">
                              Preferred Method of Contact <span className="text-red-500">*</span>
                            </label>
                            <div className="flex items-center space-x-2">
                              {contactMethods.map((method) => (
                                <div key={method.id} className="flex items-center">
                                  <input
                                    type="checkbox"
                                    id={`contact_method_${method.id}`}
                                    value={method.id}
                                    checked={editContactMethod.some((m) => m.id === method.id)}
                                    onChange={handleContactMethodChange}
                                    className="w-4 h-4"
                                  />
                                  <label htmlFor={`contact_method_${method.id}`} className="ml-2 text-sm">{method.title}</label>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="w-11/12 mx-auto my-4 px-2">
                        <button
                          type="submit"
                          className='border border-nav-dark hover:bg-nav-dark hover:text-white text-nav hover:shadow-md flex items-center rounded h-[45px] w-full'
                        >
                          <div className="h-full flex justify-center items-center px-10 font-medium w-full">
                            Update Client
                          </div>
                          <div className="bg-nav text-white h-full flex w-[45px] justify-center items-center">
                            <FontAwesomeIcon icon={faPlus} />
                          </div>
                        </button>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default EditClientSlideOut;

