import React, { useEffect, useState } from 'react';
import axios from 'axios';
import useAuth from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faCircleArrowLeft} from '@fortawesome/free-solid-svg-icons'
//partials
import Counselling from '../partials/services/Counselling';
import GPService from '../partials/services/GPService';
import Mindfulness from '../partials/services/Mindfulness';
import NurseSupport from '../partials/services/NurseSupport';
import Physiotherapy from '../partials/services/Physiotherapy';
import WeightManagement from '../partials/services/WeightManagement';
import useAxiosPrivate from '../hooks/useAxiosPrivate'

const EnquiryForm = () => {
  const navigate = useNavigate();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const { auth } = useAuth()
  const [step, setStep] = useState(1);
  const [selectedService, setSelectedService] = useState({});
  const [supportType, setSupportType] = useState('');
  const [showSubmissionSuccess, setShowSubmissionSuccess] = useState(false)

  const services = [{name: 'Counselling',}, {name: 'Integrated GP Service'}, {name: 'Mindfulness'}, {name:'Nurse Support'}, {name:'Physiotherapy'}, {name:'Weight Management'}];


  const handleBackToHome = () => {
    navigate(`/`)
  };

  const handleCheckboxChange = (e) => {
    setIsButtonDisabled(!e.target.checked);
  };

  const selectService = (item) => {
    setSelectedService(item)
    setStep(2)
  }

  const renderPartials = () => {
    switch(selectedService.name) {
      case 'Counselling':
        return <Counselling />;
      case 'Nurse Support':
        return <NurseSupport setSupportType={setSupportType}/>;
      case 'Physiotherapy':
        return <Physiotherapy />;
      case 'Mindfulness':
        return <Mindfulness />;
      case 'Integrated GP Service':
        return <GPService />;
      case 'Weight Management':
        return <WeightManagement />;
      default:
        return '';
    }
  }

  const changeStep = (step) => {
    if(!selectedService.name)
    {
      return;
    }
    setStep(step);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      name: e.target.name.value,
      email: e.target.email.value,
      dateOfBirth: e.target.dateOfBirth.value,
      contactNumber: e.target.contactNumber.value,
      schoolName: e.target.schoolName.value,
      schoolPostcode: e.target.schoolPostcode.value,
      contactTime: e.target.contactTime.value ?? 'Morning',
      shielding: e.target.shielding.value,
      supportType: e.target.supportType ? e.target.supportType.value : '',
      enquiry_referred_id: 1,
      enquiry_status_id: 1,
      service: selectedService
    };
    try {
      const response = await axios.post(`/api/enquiries/create`, formData);
      if (response.status === 200) {
        setShowSubmissionSuccess(true)
      } else {
        console.error("Form submission failed");
      }
    } catch (error) {
      console.error("Form submission failed:", error);
    }
  };

  return (
    <section className='max-w-3xl mx-auto mt-10'>
      {!showSubmissionSuccess ? ( <div>
        <div className='mt-8'>
        </div>
        <div className="font-bold py-2 text-2xl w-full border-b border-b-gray-300 mb-4 mt-6">
          <h1 className='text-center mb-5'>New Enquiry Form</h1>
        </div>
        <div className='flex justify-center space-x-4 mb-8'>
          <div onClick={() => {changeStep(1)}} className={`flex justify-center items-center rounded-full ring-4 ring-gray-300 h-12 w-12 bg-white relative cursor-pointer step ` + (step === 1 ? 'active' : '')}>
            <div className='top-5 left-7 font-bold'>1</div>
          </div>
          <div onClick={() => {changeStep(2)}} className={`flex justify-center items-center rounded-full ring-4 ring-gray-300 h-12 w-12 bg-white relative cursor-pointer step ` + (step === 2 ? 'active' : '')}>
            <div className='top-5 left-7 font-bold'>2</div>
          </div>
        </div>
        {step === 1 && (
          <div className='flex flex-col items-center'>
            <h2 className='mb-4 font-bold text-xl'>Select a service</h2>
            <div className='services flex flex-col justify-center w-2/4 mb-6'>
              {services.map((item) => {
                return (
                  <div className={'w-full p-4 text-white mb-6 cursor-pointer hover:bg-slate-600 rounded-md service ' + (selectedService.name === item.name ? 'bg-violet-400' : 'bg-slate-800')} onClick={() => selectService(item)}>
                  <p>{item.name}</p>
                </div>
                )
              })}
            </div>
          </div>
        )}
        {step === 2 && (
          <form onSubmit={handleSubmit}  className="mt-6">
            <p className='text-slate-800 py-4 font-medium text-lg'>Selected Service: {selectedService.name}</p>
          <div className='bg-white border rounded-lg shadow-lg p-4 w-full'>
              <div className='grid grid-cols-2 gap-4'>
                <div>
                  <label htmlFor="" className='text-slate-800'>Name</label>
                  <input
                    type="text"
                    id="name"
                    required
                    className="border border-nav-dark text-gray-900 text-md rounded-xl focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-violet-500 dark:focus:border-violet-500"
                  />
                </div>
                <div>
                  <label className='text-slate-800' htmlFor="">Email Address</label>
                  <input
                    type="text"
                    id="email"
                    required
                    className=" border border-nav-dark0 text-gray-900 text-md rounded-xl focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-violet-500 dark:focus:border-violet-500"
                  />
                </div>
                <div>
                  <label className='text-slate-800' htmlFor="">Date of Birth</label>
                  <input
                    type="date"
                    id="dateOfBirth"
                    required
                    className="border border-nav-dark text-gray-900 text-sm rounded-xl focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-violet-500 dark:focus:border-violet-500"
                  />
                </div>
                <div>
                  <label className='text-slate-800' htmlFor="">Contact Number</label>
                  <input
                    type="text"
                    id="contactNumber"
                    required
                    className="border border-nav-dark text-gray-900 text-md rounded-xl focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-violet-500 dark:focus:border-violet-500"
                  />
                </div>
                <div>
                  <label className='text-slate-800' htmlFor="">School Name</label>
                  <input
                    id="schoolName"
                    type="text"
                    required
                    className="border border-nav-dark text-gray-900 text-md rounded-xl focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-violet-500 dark:focus:border-violet-500"
                  />
                </div>
                <div>
                  <label className='text-slate-800' htmlFor="">School Postcode</label>
                  <input
                    type="text"
                    id="schoolPostcode"
                    required
                    className="border border-nav-dark text-gray-900 text-md rounded-xl focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-violet-500 dark:focus:border-violet-500"
                  />
                </div>
                <div>
                  <label className='text-slate-800' htmlFor="">What is the best time to contact you?</label>
                  <select
                    id="contactTime"
                    defaultValue={'Morning'}
                    type="text"
                    className="border border-nav-dark text-gray-900 text-md rounded-xl focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-violet-500 dark:focus:border-violet-500"
                  >
                    <option value="Morning">Morning</option>
                    <option value="Afternoon">Afternoon</option>
                    <option value="Evening">Evening</option>
                  </select>
                </div>

            {renderPartials()}
            
              </div>


              <div className='mt-8 space-y-4'>
                <div className='flex items-center'>
                    <input
                        type="checkbox"
                        id="shielding"
                        onChange={handleCheckboxChange}
                        className="w-4 h-4 text-purple-600 bg-gray-100 border-gray-300  focus:ring-purple-500  focus:ring-2 focus:bg-violet-400 rounded-lg"
                    />
                    <label className='pl-4 text-slate-800' htmlFor="">Is the staff member still working?</label>
                </div>
              </div>
              <div className='mt-8'>
              <button 
                type="submit" 
                className={`bg-violet-400 px-4 py-2 w-full rounded-lg shadow-md text-white font-medium hover:bg-violet-700`}
              >
                Send Request
              </button>
            </div>
          </div>
        </form>
        )}
      </div>) : (
        <div>
          <div className="font-bold py-2 text-2xl w-full mb-4 mt-6">
          <span className="flex justify-center mb-6 text-blue-500">
          <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: 70, color:'green' }} />
        </span>
            <h1 className='text-center mb-5'>Thank you!</h1>
            <p className='text-lg font-bold'>Your enquiry has been submitted, a member of our team will be in touch shortly.</p>
          </div>
        </div>
      )}
  
  </section>
  )
}

export default EnquiryForm
