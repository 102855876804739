import { Outlet } from "react-router-dom";
import { useEffect} from 'react'
import logo from "../img/singlogo.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCirclePlus} from '@fortawesome/free-solid-svg-icons'
import { Tooltip } from "react-tooltip";
import { useNavigate } from 'react-router-dom';

function AuthLayout() {
  const navigate = useNavigate();
  const handleEnquiry = () => {
    navigate(`/new-enquiry`)
  };

  return (
    <div className="flex bg-slate-100 relative">
      {/* <div className="absolute top-2 right-2">
        <div onClick={handleEnquiry} className="flex flex-col justify-center bg-[#cfafd2] hover:bg-hub-primary rounded-lg shadow-lg p-4 text-white cursor-pointer">
          <FontAwesomeIcon
              icon={faFileCirclePlus}
              className="text-3xl"
              data-tooltip-id="new-enquiry"
            />
            <Tooltip 
            id="new-enquiry"
            content="New Enquiry Form"
            />
        </div>
      </div> */}
      {/* <Header /> */}
      <div
        className="relative flex mx-auto h-screen justify-center items-center w-3/4"
      >
        {/* Left */}
          <div className="flex flex-col items-center justify-center px-6 py-8 font-montserrat">
            <div className="w-full flex ml-40">
              <img src={logo} alt="School Wellbeing Logo" className="w-72 mb-10" />
            </div>
            <div
              className="w-full bg-white rounded-lg border shadow-lg dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700"
              style={{ maxWidth: 500, minHeight: 200 }}
            >
              <Outlet />
            </div>
          </div>
        {/* </main> */}
        {/* Right */}
      </div>
    </div>
  );
}

export default AuthLayout;
