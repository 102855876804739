import React, { useState, useEffect } from 'react';
import useAuth from '../../../hooks/useAuth';
import '../../../viewRequest.css';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faUser } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import Modal from '../../../components/Modal'

const ContactSlideout = ({ isOpen, onClose, contacts }) => {
  const { setShowLoader} = useAuth()
  const axios = useAxiosPrivate();
  //Modal consts
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalOpen, setModalOpen] = useState(false) 
  // const toggleResults = () => {
  // };

  // const openWithdraw = () => {
  //   setWithdraw(true);
  //   setShowModal(true);
  //   setModalTitle("Withdraw this referral?");
  // }

  const ModalBody = () => {
    //
  }
  
  const resetModal = () => {
    setShowModal(false);
    setModalTitle('');
  };

  // const CompleteOrReopenReferralElement = () => {
  //   const handleSubmit = async (e) => {
  //     e.preventDefault();
  //     setShowLoader(true);  
  //   };
  
  //   return (
  //     <form>
  //       <div className="flex mt-2 w-full">
  //         <button onClick={handleSubmit} type="button" className="btn mt-4 mr-4 w-1/2">
  //           Update
  //         </button>
  //         <button type="button" className="btn red mt-4 w-1/2" onClick={resetModal}>
  //           Cancel
  //         </button>
  //       </div>
  //     </form>
  //   );
  // };

  return (
   <div>
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog static as="div" className="relative z-[100]" onClose={onClose}>
        {showModal && (
          <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
        )}
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex w-1/3 pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen transform transition duration-500 ease-in-out sm:duration-700">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="bg-nav px-4 py-6 sm:px-6">
                        <div className="flex items-center justify-between">
                          <div>
                            <Dialog.Title className="text-lg font-semibold leading-6 text-white">
                              View Contacts
                            </Dialog.Title>
                          </div>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative rounded-md bg-nav text-white hover:text-nav-dark focus:outline-none"
                              onClick={onClose}
                            >
                              <span className="sr-only">Close panel</span>
                              <FontAwesomeIcon icon={faCircleXmark} />
                            </button>
                          </div>
                        </div>
                    </div>
                    <div className="mt-2 space-y-1 w-full p-4 text-gray-700">
                      <div>
                        {contacts?.length > 0 && (
                          <div className="grid grid-cols-2 gap-4">
                            {contacts.map((contact) => {
                              const bgColor = contact.main_contact === 1
                                ? 'bg-orange-200'
                                : contact.contact_type !== 'primary'
                                ? 'bg-gray-200'
                                : 'bg-light-blue';

                              return (
                                <div
                                  key={contact.id}
                                  className={`flex items-center space-x-2 text-xs rounded-lg p-2 ${bgColor}`}
                                >
                                  <FontAwesomeIcon icon={faUser} className="text-sm" />
                                  <div>
                                    <div>{contact.contact_name}</div>
                                    <div>{contact.contact_email}</div>
                                    <div>{contact.contact_number}</div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
   </div>
  );
};

export default ContactSlideout;


