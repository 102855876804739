import React, { useEffect, useState } from 'react';
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import useAuth from "../../../../hooks/useAuth";
import moment from "moment";

const WBAForm = (setShowForm) => {
    const axios = useAxiosPrivate();
    const { setShowLoader, createInfo, auth } = useAuth();

    let formData = {
        client: {
            name: '',
            email: '',
            status_id: 1,
            child: 0,
            claim_reference: '',
        },
        clientDetails: {
            date_of_birth: '',
            phone_number: '',
            home_address: '',
            region_id: 0,
            emergency_contact: '{}',
            gp_details: '{}',
            child_details: '{}',
            contact_time: ''
        },
        contact_methods: [],
        policy_info: {
            insurer: 1,
            policy_ref: '',
            school_name: '',
            school_postcode: '',
            policy_start_date: moment().format('YYYY-MM-DD') + ' 00:00:00',
            policy_end_date: '',
        },
        service: []
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setShowLoader(true)

        formData.client.name = e.target.clientName.value;
        formData.client.email = e.target.clientEmail.value;
        formData.client.child = e.target.clientChild.value;
        formData.client.claim_reference = e.target.claimReference.value;

        formData.clientDetails.date_of_birth = e.target.clientDOB.value;
        formData.clientDetails.phone_number = e.target.clientPhone.value;
        formData.clientDetails.home_address = e.target.clientAddress.value;
        formData.clientDetails.gp_details = e.target.clientChild.value;
        formData.clientDetails.child_details = e.target.clientChild.value;
        formData.clientDetails.contact_time = e.target.contactTime.value;

        formData.policy_info.school_name = e.target.schoolName.value;
        formData.policy_info.school_postcode = e.target.schoolPostcode.value;

        await axios.post('claims/send-wbs-form/wba', formData).then((response) => {
            createInfo('success', 'Form Sent')
            setShowForm(false);
            setShowLoader(false)
        })

        setShowLoader(false)
    }
    
    return (
        <section>
            <form onSubmit={handleSubmit}>
                <p className={"text-lg font-semibold text-violet-500 mb-2"}>Client Details</p>
                <div className={"mb-2 ml-2"}>
                    <label className={"text-md"}>Client Type</label>
                    <div>
                        <p>Adult</p>
                            <label>
                                <input className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block pl-2 p-2.5"} type="checkbox" id="clientChild"></input>
                            </label>
                        <p>Child</p>
                    </div>
                </div>
                
                <div className={"flex flex-col mb-2"}>
                    <label className={"text-md"}>Name</label>
                    <input className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block pl-2 p-2.5"} type="text" id="clientName"></input>
                </div>
                
                <div className={"flex flex-col mb-2"}>
                    <label for="wba_dob" className={"text-md"}>Date of Birth</label>
                    <input className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block pl-2 p-2.5"} type="date" id="clientDOB" required></input>
                </div>
                
                <div className={"flex flex-col mb-2"}>
                    <label className={"text-md"}>Email</label>
                    <input className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block pl-2 p-2.5"} type="email" id="clientEmail" required></input>
                </div>
                
                <div className={"flex flex-col mb-2"}>
                    <label className={"text-md"}>Phone Number <span className={"text-md text-red-500"}>(Please use +44 and no spaces)</span></label>
                    <input className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block pl-2 p-2.5"} type="tel" id="clientPhone" required></input>
                </div>
                
                <div className={"flex flex-col mb-2"}>
                    <label className={"text-md"}>Home Address</label>
                    <input className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block pl-2 p-2.5"} type="text" id="clientAddress" required></input>
                </div>

                <p className={"text-lg font-semibold text-violet-500 mt-2 mb-2"}>Contact Methods</p>
                
                <div className={"ml-2 mb-2 flex flex-col"}>
                    <div className={"flex items-center"}>
                        <input className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block pl-2 p-2.5 mr-2"} type="checkbox" id="contactEmail" name="contact_methods" value="1"></input>
                        <label >Email</label>
                    </div>
                    <div className={"flex items-center"}>
                        <input className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block pl-2 p-2.5 mr-2"} type="checkbox" id="contactSMS" name="contact_methods" value="2"></input>
                        <label >SMS</label>
                    </div>
                    <div className={"flex items-center"}>
                        <input className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block pl-2 p-2.5 mr-2"} type="checkbox" id="contactPhone" name="contact_methods" value="3"></input>
                        <label >Telephone</label>
                    </div>
                    <div className={"flex items-center"}>
                        <input className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block pl-2 p-2.5 mr-2"} type="checkbox" id="contactVoicemail" name="contact_methods" value="4"></input>
                        <label >Voicemail</label>
                    </div>
                </div>
                    
                <div className={"flex flex-col mb-2"}>
                    <label  className={"text-md"}>Preferred Contact Time</label>
                    <input className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block pl-2 p-2.5"} type="text" id="contactTime" required></input>
                </div>

                <p className={"text-lg font-semibold text-violet-500 mt-2 mb-2"}>School/Trust Details</p>
                
                <div className={"flex flex-col mb-2"}>
                    <label className={"text-md"}>School Name</label>
                    <input className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block pl-2 p-2.5"} type="text" id="schoolName" required></input>
                </div>
                
                <div className={"flex flex-col mb-2"}>
                    <label className={"text-md"}>School Postcode</label>
                    <input className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block pl-2 p-2.5"} type="text" id="schoolPostcode" required></input>
                </div>
                    

                <p className={"text-lg font-semibold text-violet-500 mt-2"}>Additional Information</p>
                
                <div className={"flex flex-col mb-2"}>
                    <label className={"text-md"}>Claim Reference</label>
                    <input className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block pl-2 p-2.5"} type="text" id="claimReference" required></input>
                </div>
                    
                <p className={"text-lg font-semibold text-violet-500 mt-2 mb-2"}>Referable Services</p>
                
                <div className={"ml-2 mb-2"}>
                    <div className={"flex items-center"}>
                        <input className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block pl-2 mr-2 p-2.5"} type="checkbox" id="rsPhysio" name="contact_methods" value="1"></input>
                        <label>Physiotherapy</label>
                    </div>
                    <div className={"flex items-center"}>
                        <input className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block pl-2 mr-2 p-2.5"} type="checkbox" id="rsCounselling" name="contact_methods" value="2"></input>
                        <label>Counselling</label>
                    </div>
                    <div className={"flex items-center"}>
                        <input className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block pl-2 mr-2 p-2.5"} type="checkbox" id="rsMindfulness" name="contact_methods" value="3"></input>
                        <label>Mindfulness</label>
                    </div>
                    <div className={"flex items-center"}>
                        <input className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block pl-2 mr-2 p-2.5"} type="checkbox" id="rsPT" name="contact_methods" value="4"></input>
                        <label>PT and Nutrition</label>
                    </div>
                    <div className={"flex items-center"}>
                        <input className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block pl-2 mr-2 p-2.5"} type="checkbox" id="rsNurse" name="contact_methods" value="5"></input>
                        <label>Nurse Support</label>
                    </div>
                </div>
                    
                
                <button className="px-4 py-2 rounded-lg bg-violet-400 font-medium text-white hover:bg-violet-500 mr-2 mt-2">Send to WBA</button>
            
            </form> 
        </section>
        
    )
}

export default WBAForm