import React from 'react'

function Mindfulness() {
  return (
    <div>
      
        
    </div>
  )
}

export default Mindfulness