import React, { useState, useEffect } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import moment from 'moment';

const RequestNotes = ({ notes }) => {
  const axios = useAxiosPrivate();

  return (
    <div className="w-full bg-white rounded-xl shadow-md p-4 mb-8">
      <h2 className="font-bold text-xl mb-2">Request Notes</h2>
      {notes && notes.map((note) => (
        <div key={note.id} className="w-full bg-white rounded-xl shadow-md p-4 mb-8">
          <p className="text-sm text-gray-600">{moment(note.created_at).format('DD/MM/YYYY')}</p>
          <p className="text-sm text-gray-600">{note.request_note}</p>
        </div>
      ))}
      {!notes && (
        <div className="w-full bg-white rounded-xl shadow-md p-4 mb-8">
          <p className="text-sm text-gray-600">No notes yet</p>
        </div>
      )}
    </div>
  )
};

export default RequestNotes;
