/* eslint-disable */
import MainTitle from '../../components/MainTitle'
import { NavLink } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Pagination from '../../components/Pagination'
import {
  faTrashCan,
  faMagnifyingGlass,
  faEye,
  faPlus,
  faSort,
  faSortUp,
  faSortDown,
  faMailBulk,
  faEnvelope,
  faArrowUp,
  faArrowDown,
  faBuilding,
  faHome,
} from '@fortawesome/free-solid-svg-icons'
import Modal from '../../components/Modal'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { data } from 'autoprefixer'

function Organisations() {
  const axios = useAxiosPrivate()
  const { setShowLoader, showLoader, can, createInfo } = useAuth()

  const [organisations, setOrganisations] = useState([])
  const [deleteOrganisation, setDeleteOrganisation] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [stats, setStats] = useState([])

  const [search, setSearch] = useState('')

  const [currentRecords, setCurrentRecords] = useState([])
  const [filteredRecords, setFilteredRecords] = useState([])
  const [nPages, setNumberOfPages] = useState(0)

  const [currentPage, setCurrentPage] = useState(1)
  // No of Records to be displayed on each page
  const [recordsPerPage] = useState(10)

  const [indexOfLastRecord, setIndexOfLastRecord] = useState(10)
  const [indexOfFirstRecord, setIndexOfFirstRecord] = useState(0)

  const [modalTitle, setModalTitle] = useState('')

  const [originalRecords, setOriginalRecords] = useState([])
  const [columnClickCounts, setColumnClickCounts] = useState({})
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending',
  })

  const [status, setStatuses] = useState([]);
  const [statusFilter, setStatusFilter] = useState('');
  const [dropdownValue, setDropdownValue] = useState('');

  const handleStatusChange = (e) => {
    setStatusFilter(e.target.value);
    setDropdownValue(e.target.value);
  }

  const getStatuses = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/organisations/statuses`)
      if (response.data) {
        await setStatuses(response.data.result)
        setShowLoader(false)
      }
    } catch (error) {
      console.log(error)
      setShowLoader(false)
    }
    setShowLoader(false)
  }

  const resetFilters = () => {
    setStatusFilter('');
    setSearch('');
    setDropdownValue('');
  };

  const isFilterApplied = () => {
    return statusFilter !== '' || search !== '';
  };

  const sortArray = (array, key, direction) => {
    return [...array].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1
      if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1
      return 0
    })
  }

  const renderSortArrow = (columnKey) => {
    if (sortConfig.key === columnKey) {
      if (sortConfig.direction === 'ascending') {
        return <FontAwesomeIcon icon={faSortUp} />
      } else {
        return <FontAwesomeIcon icon={faSortDown} />
      }
    }
    return <FontAwesomeIcon icon={faSort} />
  }

  const handleSort = (key) => {
    let newColumnClickCounts = { ...columnClickCounts }
    if (!newColumnClickCounts[key]) {
      newColumnClickCounts[key] = 1
    } else {
      newColumnClickCounts[key]++
    }

    if (newColumnClickCounts[key] === 3) {
      setSortConfig({
        key: null,
        direction: 'descending',
      })
      newColumnClickCounts[key] = 0
      setCurrentRecords(
        originalRecords.slice(indexOfFirstRecord, indexOfLastRecord)
      )
    } else {
      let direction =
        sortConfig.direction === 'ascending' ? 'descending' : 'ascending'
      setSortConfig({ key, direction })

      const sortedRecords = sortArray(originalRecords, key, direction)
      setCurrentRecords(
        sortedRecords.slice(indexOfFirstRecord, indexOfLastRecord)
      )
    }
    setColumnClickCounts(newColumnClickCounts)
  }

  const nextPage = () => {
    if (currentPage < nPages) {
      const newPage = currentPage + 1
      const indexFirst = newPage * recordsPerPage - recordsPerPage
      const indexLast = newPage * recordsPerPage

      setIndexOfFirstRecord(indexFirst)
      setIndexOfLastRecord(indexLast)
      setCurrentPage(newPage)

      const sortedRecords = sortArray(
        originalRecords,
        sortConfig.key,
        sortConfig.direction
      )
      setCurrentRecords(sortedRecords.slice(indexFirst, indexLast))
    }
  }

  const previousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1
      const indexFirst = (newPage - 1) * recordsPerPage
      const indexLast = newPage * recordsPerPage

      setIndexOfFirstRecord(indexFirst)
      setIndexOfLastRecord(indexLast)
      setCurrentPage(newPage)

      const sortedRecords = sortArray(
        originalRecords,
        sortConfig.key,
        sortConfig.direction
      )
      setCurrentRecords(sortedRecords.slice(indexFirst, indexLast))
    }
  }

  const handleSearch = (handle) => {
    setIndexOfFirstRecord(0)
    setIndexOfLastRecord(10)
    setCurrentPage(0)
    setCurrentRecords(filteredRecords.slice(0, 10))

    let searchValue = handle.target.value
    setSearch(searchValue)
    let searchString = searchValue.trim().toLowerCase()
    if (searchString.length > 0) {
      let records = organisations.filter((e) => {
        return (
          e.name.toLowerCase().match(searchString) ||
          e.status.toLowerCase().match(searchString) ||
          e.created_at.toLowerCase().match(searchString)
        )
      })
      setFilteredRecords(records)
      setCurrentRecords(records.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(records.length / recordsPerPage))
    } else {
      setFilteredRecords(organisations)
      setCurrentRecords(organisations.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(organisations.length / recordsPerPage))
    }

    setSortConfig({
      key: null,
      direction: 'descending',
    })
  }

  useEffect(() => {
    getOrganisations();
  }, [statusFilter]);

  const getOrganisations = async () => {
    try {
      const response = await axios.get(`/organisations/all`)
      let filteredOrganisations = response.data.result;
      if (statusFilter) {
        filteredOrganisations = filteredOrganisations.filter(organisation => organisation?.status?.toLowerCase() === statusFilter);
      }
      await setOrganisations(filteredOrganisations)
      await calculateStats(filteredOrganisations)
      await setOriginalRecords(filteredOrganisations)
      await setFilteredRecords(filteredOrganisations)
      await setCurrentRecords(
        filteredOrganisations.slice(indexOfFirstRecord, indexOfLastRecord)
      )

      
      setNumberOfPages(Math.ceil(filteredOrganisations.length / recordsPerPage))
    } catch (error) {console.log(error)}
    setShowLoader(false)
  }

  useEffect(() => {
    setShowLoader(true)
    if (!showLoader) {
      getStatuses()
      getOrganisations()
    }
  }, [])

  const deleteOrganisationClicked = (organisation) => {
    setDeleteOrganisation(organisation)
    setModalTitle(`Delete organisation: ${organisation.name}`)
    setShowModal(true)
  }

  const resetModal = (resetOrganisations = true) => {
    setDeleteOrganisation({})
    setShowModal(false)
    if (resetOrganisations) {
      setOrganisations(organisations)
    }
  }

  const ModalBody = () => {
    if (deleteOrganisation.id) {
      return <DeleteOrganisationElement />
    }
  }

  const DeleteOrganisationElement = () => {
    const deleteOrganisationFn = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      resetModal(false)
      try {
        await axios.get('/organisations/delete/' + deleteOrganisation.id)
        createInfo('error', `Deleted Organisation: ${deleteOrganisation.name}`)

        setCurrentRecords(
          currentRecords.filter((organisation) => {
            return Organisation.id !== deleteOrganisation.id
          })
        )
        setOrganisations(
          organisations.filter((organisation) => {
            return Organisation.id !== deleteService.id
          })
        )

        setShowLoader(false)
      } catch (error) {
        setShowLoader(false)
      }
    }

    return (
      <form onSubmit={deleteOrganisationFn}>
        <div className="mb-3">
          <p className="text-lg font-bold my-8 text-center">
            Are you sure you want to delete this organisation?
          </p>
          <div className="flex mt-2 w-full">
            <button className="btn red mt-4 mr-4 w-1/2">Delete Organisation</button>

            <button
              type="button"
              className="btn mt-4 w-1/2"
              onClick={resetModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    )
  }

  const calculateStats = (allEnquiries) => {
    const totalEnquiries = allEnquiries.length

    const currentWeekStart = moment().startOf('isoWeek')
    const lastWeekStart = moment().subtract(1, 'weeks').startOf('isoWeek')
    const lastWeekEnd = moment().subtract(1, 'weeks').endOf('isoWeek')
    const enquiriesThisWeek = allEnquiries.filter(enquiry => 
      moment(enquiry.created_at).isSameOrAfter(currentWeekStart)
    ).length

    const enquiriesLastWeek = allEnquiries.filter(enquiry => 
      moment(enquiry.created_at).isBetween(lastWeekStart, lastWeekEnd)
    ).length

    let change = 0;
    let changeType = 'increase'
    
    if (enquiriesLastWeek > 0) {
      change = ((enquiriesThisWeek - enquiriesLastWeek) / enquiriesLastWeek) * 100;
      changeType = enquiriesThisWeek >= enquiriesLastWeek ? 'increase' : 'decrease';
    } else if (enquiriesThisWeek > 0) {
      change = 100
      changeType = 'increase'
    } else {
      change = 0
    }

    setStats([
      { id: 1, name: 'Total Organisations', stat: totalEnquiries, icon: faBuilding },
      { id: 2, name: 'New Organisations', stat: enquiriesThisWeek, icon: faHome, change: `${Math.abs(change.toFixed(1))}%`, changeType }
    ]);
  }

  return (
    <section>
      <MainTitle title="Organisations" />

      <section>
        {showModal && (
          <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
        )}
        <div className='mb-4'>
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
            {stats.map((item) => (
              <div
                key={item.id}
                className="relative overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:px-6 sm:pt-6"
              >
                <dt>
                  <div className="absolute rounded-md bg-dark-purple px-3 py-2">
                    <FontAwesomeIcon icon={item.icon} className="h-6 w-6 text-white" />
                  </div>
                  <p className="ml-16 truncate text-sm font-medium text-gray-500">{item.name}</p>
                </dt>
                <dd className="ml-16 flex items-baseline">
                  <p className="text-2xl font-semibold text-gray-900">{item.stat}</p>
                  {item.change && (
                    <p
                      className={`ml-2 flex items-baseline text-sm font-semibold ${item.changeType === 'increase' ? 'text-green-600' : 'text-red-600'}`}
                    >
                      {item.changeType === 'increase' ? (
                        <FontAwesomeIcon icon={faArrowUp} aria-hidden="true" className="h-5 w-5 flex-shrink-0 self-center text-green-500" />
                      ) : (
                        <FontAwesomeIcon icon={faArrowDown} aria-hidden="true" className="h-5 w-5 flex-shrink-0 self-center text-red-500" />
                      )}
                      <span className="sr-only"> {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by </span>
                      {item.change}
                    </p>
                  )}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </section>

      <div className="py-4 flex justify-between items-center">
        <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <FontAwesomeIcon
                onChange={handleSearch}
                icon={faMagnifyingGlass}
              />
            </div>
              <input
                type="text"
                id="table-search"
                onChange={handleSearch}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-400 focus:border-violet-400 block w-80 pl-10 p-2.5"
                placeholder="Search Organisations"
              />
        </div>

        <div className="py-4">
          <select
            onChange={handleStatusChange}
            value={dropdownValue}
            className="px-4 py-2 border border-gray-300 rounded-md shadow-md mr-2"
          >
            <option value="">Filter by Status</option>
            {status.map((status, index) => (
              <option key={index} value={status?.name?.toLowerCase()}>
                {status.name}
              </option>
            ))}
          </select>
          {isFilterApplied() && (
            <button
              onClick={resetFilters}
              className="bg-red-500 text-white rounded-lg px-4 py-2 ml-4"
            >
              Reset Filters
            </button>
          )}
        </div>

      </div>
      <table className="table-main">
        <thead>
          <tr>
            <th
              className="cursor-pointer"
              scope="col"
              onClick={() => handleSort('name')}
            >
              Name {renderSortArrow('name')}
            </th>
            <th
              className="cursor-pointer"
              scope="col"
              onClick={() => handleSort('status')}
            >
              Status {renderSortArrow('status')}
            </th>
            <th
              className="cursor-pointer"
              scope="col"
              onClick={() => handleSort('created_at')}
            >
              Created At {renderSortArrow('created_at')}
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className="table-main">
          {currentRecords.length > 0 ? (
            currentRecords.map((organisation) => {
              return (
                <tr key={organisation.id}>
                  <td>{organisation.name}</td>
                  <td>
                    <div
                      className={`status ${organisation?.status?.toLowerCase()}`}
                    >
                      {organisation.status}
                    </div>
                  </td>
                  <td>{moment(organisation.created_at).format('DD/MM/YYYY')}</td>
                  <td>
                    <div className="flex justify-center space-x-2">
                      <Link to={`/organisations/${organisation.id}`}>
                        <span className="flex justify-center items-center bg-blue-400 rounded-md text-blue-800 h-9 w-12 hover:bg-blue-500 cursor-pointer">
                          <FontAwesomeIcon icon={faEye} />
                        </span>
                      </Link>

                      <span
                        onClick={() => deleteOrganisationClicked(organisation)}
                        className="flex justify-center items-center bg-red-400 rounded-md text-red-800 h-9 w-12  hover:bg-red-500 cursor-pointer"
                      >
                        <FontAwesomeIcon icon={faTrashCan} />
                      </span>
                    </div>
                  </td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={7}>
                <p className="no-records">No organisations found</p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Pagination
        next={nextPage}
        prev={previousPage}
        first={indexOfFirstRecord}
        last={indexOfLastRecord}
        total={filteredRecords.length}
      />
    </section>
  )
}

export default Organisations
