import MainTitle from '../../components/MainTitle'
import useAuth from '../../hooks/useAuth'
import { useState } from 'react'
import ReportConfirm from '../../components/ReportConfirm'
import Modal from '../../components/Modal'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile } from '@fortawesome/free-solid-svg-icons'
function Reports() {
  const [showReportConfirmation, setShowReportConfirmation] = useState(false)
  const [sessionsReportData, setSessionsReportData] = useState({})
  const [modalTitle, setModalTitle] = useState('')
  const [showModal, setShowModal] = useState(false)
  const axios = useAxiosPrivate()

  const SessionsReportModal = () => {
    const sessionReport = async (e) => {
      e.preventDefault()
      resetModal()
      setShowReportConfirmation(true)
      const data = {
        start_date: sessionsReportData.startDate,
        end_date: sessionsReportData.endDate,
      }
      try {
        const response = await axios.post(`/reports/sessions`, data)
      } catch (error) {}
    }

    return (
      <form onSubmit={sessionReport}>
        <div className="mb-3">
          <label
            htmlFor="name"
            className="block font-medium text-gray-600 text-sm"
          >
            Report Start Date
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              type="date"
              name="start_date"
              id="name"
              onChange={(e) => (sessionsReportData.startDate = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>

        <div className="mb-3">
          <label
            htmlFor="name"
            className="block font-medium text-gray-600 text-sm"
          >
            Report End Date
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              type="date"
              name="end_date"
              id="name"
              onChange={(e) => (sessionsReportData.endDate = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>

        <div className="mb-3">
          <span>
            Note: If you don't select any dates the report will pull all of the
            data available
          </span>
        </div>

        <div className="mb-3 flex justify-between">
          <button
            type="button"
            className="btn red mt-4 w-1/3"
            onClick={resetModal}
          >
            Cancel
          </button>
          <button type="submit" className="btn mt-4 w-1/3">
            Download
          </button>
        </div>
      </form>
    )
  }

  const ohMaster = async () => {
    setShowReportConfirmation(true)
    try {
      let url = "https://test.kisdigital.co.uk/api/v1/singl/reports/oh_all"
      await axios.post(url).then((res) => {})

    } catch (error) {
      console.error(error)
    }
  }

  const ohInvoicing = async () => {
    setShowReportConfirmation(true)
    try {

      let url = "https://test.kisdigital.co.uk/api/v1/singl/reports/oh_invoiceable"
      await axios.post(url).then((res) => {})

    } catch (error) {
      console.error(error)
    }
  }

  const resetModal = () => {
    setSessionsReportData({})
    setShowModal(false)
  }

  const ModalBody = () => {
    if (sessionsReportData) {
      return <SessionsReportModal />
    }
  }

  return (
    <section className="relative">
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}
      {showReportConfirmation && (
        <ReportConfirm show={setShowReportConfirmation} />
      )}
      <MainTitle title="Reports" />
      <article className="">
        <div className="grid grid-cols-3 xl:grid-cols-4 gap-4 mt-4">    
          <div className='mb-4'>
            <dl className="">
              <div className="rounded-lg bg-white px-4 py-5 shadow ">
                <dt className='flex items-center ml-8'>
                  <div className=" rounded-md bg-dark-purple px-3 py-2">
                    <FontAwesomeIcon icon={faFile} className="h-8 w-8 text-white" />
                  </div>
                  <div>
                    <p className="ml-10 truncate text-base font-medium text-gray-500">OH Master</p>
                    <dd className="ml-10 flex items-baseline justify-end mt-2">
                      <button className="text-xs px-3 py-2 rounded-lg text-white font-medium shadow-sm hover:bg-teal-600 bg-teal-500" onClick={ohMaster}>
                        Download
                      </button>
                    </dd>
                  </div>
                </dt>
              </div>
            </dl>
          </div> 
          <div className="p-4 bg-white flex items-center rounded-md shadow-md">
            <div> <FontAwesomeIcon icon={faFile} className='text-nav-dark h-16 w-16' /> </div>   
            <div>
              <div className="text-center font-medium pb-4">
                OH Master
              </div>
              <div className="flex justify-center">
                <button className="px-4 py-3 rounded-lg text-white font-medium shadow-sm hover:bg-teal-600 bg-teal-500" onClick={ohMaster}>
                  Download
                </button>
              </div>
            </div>
          </div>
          <div className="p-4 bg-white flex-1 rounded-md shadow-md">
            <div className="text-center font-medium pb-4">
              OH Invoicing
            </div>
            <div className="flex justify-center">
              <button className="px-4 py-3 rounded-lg text-white font-medium shadow-sm hover:bg-teal-600 bg-teal-500" onClick={ohInvoicing}>
                Download
              </button>
            </div>
          </div>
        </div>
      </article>
    </section>
  )
}

export default Reports
