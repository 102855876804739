import React, { useState, useEffect } from 'react'
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import useAuth from '../hooks/useAuth'
import { useParams, useNavigate, Navigate } from "react-router-dom";
import {
  faCircleXmark, faPlus
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
const ViewClaimSlideOut = ({ claimOpen, setClaimOpen }) => {
  const navigate = useNavigate();
  const { id } = useParams()
  const { auth, setShowLoader, can } = useAuth()
  const axios = useAxiosPrivate()

  const [selectedService, setSelectedService] = useState({});
  const [claimNotes, setClaimNotes] = useState('')
  const [contactTypes, setContactTypes] = useState([]);
  const [extraSessions, setExtraSessions] = useState(0);
  const [creditAmount, setCreditAmount] = useState(0);
  const [costAmount, setCostAmount] = useState(0);
  const [contactType, setContactType] = useState(1);
  const [services, setServices] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [groups, setGroups] = useState([]);
  const [clientData, setClientData] = useState([]);

  const getContactTypes = async () => {
    try{
        await axios.get('/contact-types/all')
      .then(response => {
        setContactTypes(response.data.result);
    })} catch(error){
      console.log(error)
    }
  }

  const getClientById = async () => {
    try{
      const response = await axios.get(`/clients/${id}`)
      await setClientData(response.data.result)
     
    }catch(error){
      console.log(error)
    }
  }

  const toggleServices = (data) => {
    setServices(JSON.parse(data).services);
    setSelectedGroup(JSON.stringify(data));
  }

  const toggleSelectedService = (data) => {
    setSelectedService(JSON.parse(data))
    setCostAmount(data.default_unit_cost_amount)
    setCreditAmount(data.default_unit_credit_amount)
  }

  const getGroups = async () => {
    try{
        await axios.get('/service-groups/all')
      .then(response => {
        setGroups(response.data.result);
        setShowLoader(false)
    })
     
    }catch(error){
      console.log(error)
    }
  } 

  useEffect(() => {
    setShowLoader(true)
    getClientById()
    getContactTypes();
    getGroups();
  }, [id])

  const handleSubmit = async (e) => {
    e.preventDefault();

    let costReserve = (selectedService.default_session_allocation * selectedService.default_unit_cost_amount)
    const formData = {
      claimable_id: id,
      claimable_type: "Client",
      service_id : selectedService.id,
      contact_type_id: contactType,
      claim_status_id: 1,
      description: claimNotes,
      extra_sessions: extraSessions,
      unit_credit_amount: selectedService.default_unit_credit_amount,
      unit_cost_amount: selectedService.default_unit_cost_amount,
      session_allocation: selectedService.default_session_allocation,
      cost_reserve: costReserve
    };
    try {
      const response = await axios.post(`claims/create`, formData);
      if (response.data.result) {
        navigate(`/clients/${id}/referrals/${response.data.result}`);
      } else {
        console.error("Form submission failed");
      }
    } catch (error) {
      console.error("Form submission failed:", error);
    }
  };

  const toolTipData = [
    {
      title: 'Name',
      value: clientData.name,
    },
    {
      title: 'Date of birth',
      value: clientData?.client_details?.date_of_birth,
    },
    {
      title: 'Email',
      value: clientData.email,
    }
  ]
 

  return (
    <Transition.Root show={claimOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[100]" onClose={setClaimOpen}>
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl relative">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="bg-nav px-4 py-6 sm:px-6">
                      <div className="flex items-center justify-between">
                        <div>
                          <Dialog.Title className="text-lg font-semibold leading-6 text-white">
                            Create Claim
                          </Dialog.Title>
                        </div>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-nav text-white hover:text-nav-dark focus:outline-none"
                            onClick={() => setClaimOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <FontAwesomeIcon icon={faCircleXmark} />
                          </button>
                        </div>
                      </div>
                    </div>
                   
                    <div className='w-full p-6'>
                      <div className="mb-8 w-full">
                          <div className="">
                            <div className=" rounded-lg border p-4">
                              <h2 className="text-slate-800 font-bold mb-4">
                                Client Details
                              </h2>
                                <div className="grid grid-cols-2 gap-4">
                                  <div>
                                    <label htmlFor="" className="text-slate-800 font-semibold">
                                      Name
                                    </label>
                                    <p className="text-md text-violet-800">
                                      {clientData?.name}
                                    </p>
                                  </div>
                                  <div>
                                    <label htmlFor="" className="text-slate-800 font-semibold">
                                      Organisation
                                    </label>
                                    <p className="text-md text-violet-800">
                                      {clientData?.organisation?.name}
                                    </p>
                                  </div>
                                </div>
                            </div>
                          
                          </div>
                      </div>
                      <div className='mb-8 w-full'>
                        <form className="" onSubmit={handleSubmit}>
                          <div className='rounded-lg w-full'>
                              <div className='grid grid-cols-1 gap-4'>
                                <div>
                                  <label htmlFor="" className='text-slate-800'>Communication type</label>
                                  <select onChange={(e) => setContactType(e.target.value)} defaultValue="0" className='" border border-nav-dark text-md rounded-md focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-violet-500 dark:focus:border-violet-500'>
                                  <option value="0" disabled>
                                  Select a type
                                  </option>
                                  {contactTypes.map((type) => {
                                    return (
                                      <option key={type.id} value={type.id}>{type.title}</option>
                                    )
                                  })}
                                  </select>
                                </div>
                                <div>
                                  <label className='text-slate-800' htmlFor="">Claim Notes</label>
                                  <textarea
                                  onChange={(e) => setClaimNotes(e.target.value)}
                                  className="px-4 py-2 block w-full border border-nav-dark text-md bg-gray-50 rounded-md shadow-md focus:ring-nav-dark focus:border-nav-dark"
                                />
                                </div>
                                <div>
                                  <label htmlFor="" className='text-slate-800'>Choose type of service</label>
                                  <select onChange={(e) => toggleServices(e.target.value)} defaultValue="0" className='border border-nav-dark text-md rounded-md focus:ring-nav-dark focus:border-nav-dark block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-violet-500 dark:focus:border-violet-500'>
                                  <option value="0" disabled>
                                  Select a service
                                  </option>
                                  {groups?.map((group) => {
                                    return (
                                      <option key={group.id} value={JSON.stringify(group)}>{group.title}</option>
                                    )
                                  })}
                                  </select>
                                </div>
                                {selectedGroup?.length > 0 && (
                                  <div>
                                    <label htmlFor="" className='text-slate-800'>Choose Supplier</label>
                                    <select onChange={(e) => toggleSelectedService(e.target.value)} defaultValue="0" className='border border-nav-dark text-md rounded-md focus:ring-nav-dark focus:border-nav-dark block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-violet-500 dark:focus:border-violet-500'>
                                    <option value="0" disabled>
                                    Select a supplier
                                    </option>
                                      {services?.map((service) => {
                                        return (
                                          <option key={service.id} value={JSON.stringify(service)}>{service.provider.name}</option>
                                        )
                                      })}
                                    </select>
                                  </div>
                                )}

                                {selectedService.id && (
                                  <div className='grid grid-cols-2 gap-4'>
                                    <div>
                                      <label htmlFor="" className='text-slate-800'>No. Sessions</label>
                                      <input
                                          type="number"
                                          id="name"
                                          required
                                          disabled
                                          value={selectedService.default_session_allocation}
                                          className=" border border-nav-dark text-gray-900 text-md rounded-md focus:ring-nav-dark focus:border-nav-dark block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-violet-500 dark:focus:border-violet-500"
                                      />
                                    </div>
                                    <div>
                                      <label htmlFor="" className='text-slate-800'>Cost per session</label>
                                      <input
                                        type="number"
                                        id="name"
                                        required
                                        disabled
                                        value={selectedService.default_unit_cost_amount}
                                        onChange={(e) => setCostAmount(e.target.value)}
                                        className=" border border-nav-dark text-gray-900 text-md rounded-md focus:ring-nav-dark focus:border-nav-dark block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-violet-500 dark:focus:border-violet-500"
                                      />
                                    </div>
                                    {can('add extra sessions') && (<div>
                                      <label htmlFor="" className='text-slate-800'>Extra Sessions</label>
                                      <input
                                        type="number"
                                        id="name"
                                        defaultValue={extraSessions}
                                        onChange={(e) => {setExtraSessions(e.target.value)}}
                                        className="border border-nav-dark text-gray-900 text-md rounded-md focus:ring-nav-dark focus:border-nav-dark block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-violet-500 dark:focus:border-violet-500"
                                      />
                                    </div>)}
                                    {clientData?.policy?.product === 'SWB Bespoke (Credits)' && (<div>
                                      <label htmlFor="" className='text-slate-800'>Credit cost</label>
                                      <input
                                        type="number"
                                        id="name"
                                        defaultValue={creditAmount}
                                        onChange={(e) => {setCreditAmount(e.target.value)}}
                                        className="border border-nav-dark text-gray-900 text-md rounded-md focus:ring-nav-dark focus:border-nav-dark block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-violet-500 dark:focus:border-violet-500"
                                      />
                                    </div>)}
                                  </div>
                                )}
                              </div>  
                          </div>
                          <div className="w-11/12 mb-10 absolute bottom-0">
                            <button
                              type="submit"
                              className='border flex items-center rounded-md h-[45px] w-full mx-auto'
                            >
                              <div className="text-nav h-full flex justify-center items-center px-10 font-medium w-full">
                                Create
                              </div>
                              <div className="bg-nav text-white h-full flex w-[45px] justify-center items-center rounded-r-md">
                                <FontAwesomeIcon icon={faPlus} />
                              </div>
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ViewClaimSlideOut
