import MainTitle from '../../components/MainTitle'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {faTimes, faPlusCircle, faEye} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Calendar from './Partials/MonthCalendar';
import Tasks from './Partials/Tasks';
import moment from 'moment'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import Modal from '../../components/Modal'
import useAuth from '../../hooks/useAuth';
import Todo from '../../components/TodoSlideOut'

function Dashboard() {
  const { setShowLoader, createInfo, can, auth } = useAuth()
  const [display, setDisplay] = useState('All')
  const axios = useAxiosPrivate();
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [tasks, setTasks] = useState([])
  const [newTask, setNewTask] = useState({})
  const [addTask, setAddTask] = useState(false)
  const [availabilities, setAvailabilities] = useState({})
  const [taskListSearch, setTaskListSearch] = useState("")
  const [filteredTasks, setFilteredTasks ] = useState([])
  const [enquiries, setEnquiries] = useState([])
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()

  const ShowDisplay = () => {
    return <Tasks tasks={filteredTasks} filter={display} refreshTasks={refreshTasks} />;
  };

  const isOverdue = (dueDate) => {
    const today = new Date();
    const due = new Date(dueDate);
    return due < today;
  };
  
  // Calculating task counts
  const allCount = tasks.length;
  const dueCount = tasks.filter(task => !isOverdue(task.due_date) && !task.completed && !task.isImportant).length;
  const overdueCount = tasks.filter(task => isOverdue(task.due_date) && !task.completed).length;
  const importantCount = tasks.filter(task => task.important && !task.completed).length;
  const completedCount = tasks.filter(task => task.completed).length;
  
  const addTaskClicked = () => {
    setOpen(true)
  }

  const viewEnquiryClicked = (enquiry) => {
    navigate(`/enquiries/${enquiry}`)
  }

  const ModalBody = () => {
    if(addTask) {
      return <AddTaskElement />
    }
  }

  const getEnquiries = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/enquiries`)
      if (response.data.result) {
        await setEnquiries(response.data.result)
        setShowLoader(false)
      }
    } catch (error) {
      console.log(error)
      setShowLoader(false)
    }
    setShowLoader(false)
  }

  const getGreeting = () => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      return 'Good Morning'
    } else if (currentHour < 18) {
      return 'Good Afternoon'
    } else {
      return 'Good Evening'
    }
  }

  const getTaskList = async () => {
    await axios.get('/tasks/all')
   .then(response => {

        let tasks = response.data.result;
        let filteredTasks = tasks;

        if(taskListSearch && taskListSearch.trim().length >= 3) { 
          filteredTasks = filteredTasks.filter((task) => {
            return task.title.includes(taskListSearch)
          });
        }
        
        setTasks(tasks);
        setFilteredTasks(filteredTasks)
    })
  }

  useEffect(() => {
    let taksList = tasks;
    if(taskListSearch.trim().length >= 3) {
      taksList = tasks.filter((task) => {
        return task.title.toLocaleLowerCase().includes(taskListSearch.toLocaleLowerCase())
      });
      setFilteredTasks(taksList);
      return;
    }
    setFilteredTasks(taksList)
  }, [taskListSearch])

  const refreshTasks = () => {
    getTaskList();
  };

  const getAvailabilities = async () => {
    try {
      const response = await axios.get('/availabilities/all')
      setAvailabilities(response.data.result)
    } catch (error) {
      console.error("Error fetching availabilities:", error)
    }
  }

   // Group availabilities by date
   const groupByDate = (availabilities) => {
    return availabilities.reduce((grouped, availability) => {
      const date = moment(availability.start_time).format('dddd Do MMM'); // Group by day (e.g., "Tues 22nd Oct")
      if (!grouped[date]) {
        grouped[date] = [];
      }
      grouped[date].push(availability);
      return grouped;
    }, {});
  };

  // Sort dates and group availabilities
  const sortedAvailabilities = () => {
    const grouped = groupByDate(availabilities);
    return Object.keys(grouped)
      .sort((a, b) => moment(b, 'dddd Do MMM').unix() - moment(a, 'dddd Do MMM').unix()) // Sort dates descending
      .map(date => ({ date, availabilities: grouped[date] }));
  };

  useEffect(() => {
    getAvailabilities()
    getTaskList()
    getEnquiries()
  }, []);

  const AddTaskElement = () => {
    const createTask = async (e) => {
      e.preventDefault();
      setShowLoader(true);
      try {
        const response = await axios.post(`/tasks/create`, {
          title: newTask.title,
          due_date: newTask.due_date,
          important: newTask.important,
        });
  
        if (response.data.error === false) {
          createInfo('success', `Task Added`);
          getTaskList();
          setNewTask({});
        }
      } catch (error) {
        console.log(error.message);
        createInfo('error', `Failed to create task`);
      }
      resetModal();
      setShowLoader(false);
    };

    return (
      <form onSubmit={createTask}>
          <div className="mb-3">
            <label
              htmlFor="title"
              className="block font-medium text-gray-600 text-sm"
            >
              Title
            </label>
            <div className="mt-1 relative rounded-md">
              <input
                required
                value={newTask.title}
                onChange={(e )=> newTask.title = e.target.value}
                type="text"
                name="title"
                id="title"
                className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              />
            </div>
          </div>
          <div className="mb-3">
            <label
              htmlFor="due_date"
              className="block font-medium text-gray-600 text-sm"
            >
              Due Date
            </label>
            <div className="mt-1 relative rounded-md">
              <input
                required
                type="date"
                value={newTask.due_date}
                onChange={(e )=> newTask.due_date = e.target.value}
                name="due_date"
                id="due_date"
                className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              />
            </div>
          </div>
          <div className="mb-3 flex items-center">
            <input
              value={newTask.important}
              onChange={(e )=> newTask.important = e.target.value}
              type="checkbox"
              className="bg-blue-500 focus:accent-blue-500 h-4 w-4"
            />
            <label
              htmlFor="important"
              className="block ml-2 font-medium text-gray-600 text-sm"
            >
              Important
            </label>
          </div>
          <div className="flex mt-2 w-full space-x-4">
            <button type="submit" className="btn mt-4 mr-4 w-1/2">Add Task</button>
            <button type="button" className="btn red mt-4 w-1/2" onClick={resetModal}>Cancel</button>
          </div>
        </form>
    )
  }

  const resetModal = () => {
    setAddTask(false)
    setShowModal(false)
    setNewTask({});
  }

  return (
    <section>
      <MainTitle title={`${getGreeting()}, ${auth?.user?.name}`} status links />
      {showModal && (
          <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}
      <Todo open={open} setOpen={setOpen} />
      <div className="grid grid-cols-2 gap-6 my-10 ">
      <div className='col-span-1'>
            <div className="shadow py-6 px-4 rounded-xl bg-white">
                <div>
                    <h2 className="text-2xl font-medium leading-6 text-dark-blue">Task List</h2>
                    <p className="mb-4 text-gray-600 text-lg"></p>
                </div>
                <div className="flex items-center relative mb-6">
                  <input
                    type="text"
                    id="tasks_search"
                    onChange={(event) => { setTaskListSearch(event.target.value);}}
                    className="border border-gray-200 rounded-md h-9 w-full px-2 text-sm text-slate-800 focus:ring-violet-400 focus:border-violet-400 "
                    placeholder="Search"
                  />
                  <span
                    className="flex justify-center items-center bg-hub-primary-hover rounded-md text-white h-9 w-9 mx-2 px-2 hover:bg-hub-primary cursor-pointer"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </span>
                </div>
                <div >
                <div className=''>
                  <div className="flex justify-between border-b-2 border-dark-blue">
                    <div className="flex items-center space-x-2 xl:text-base text-sm">
                      <div
                        onClick={() => setDisplay('All')}
                        className={`cursor-pointer px-2 ${display === 'All' ? 'cursor-pointer font-medium text-white bg-dark-blue rounded-t py-1' : 'cursor-pointer hover:text-white hover:bg-dark-blue rounded-t py-1'}`}>
                        All ({allCount})
                      </div>
                      <div
                        onClick={() => setDisplay('Due')}
                        className={`cursor-pointer px-2 ${display === 'Due' ? 'cursor-pointer font-medium text-white bg-dark-blue rounded-t py-1' : 'cursor-pointer hover:text-white hover:bg-dark-blue rounded-t py-1'}`}>
                        Due ({dueCount})
                      </div>
                      <div
                        onClick={() => setDisplay('Overdue')}
                        className={`cursor-pointer px-2 ${display === 'Overdue' ? 'cursor-pointer font-medium text-white bg-dark-blue rounded-t py-1' : 'cursor-pointer hover:text-white hover:bg-dark-blue rounded-t py-1'}`}>
                        Overdue ({overdueCount})
                      </div>
                      <div
                        onClick={() => setDisplay('Important')}
                        className={`cursor-pointer px-2 ${display === 'Important' ? 'cursor-pointer font-medium text-white bg-dark-blue rounded-t py-1' : 'cursor-pointer hover:text-white hover:bg-dark-blue rounded-t py-1'}`}>
                        Important ({importantCount})
                      </div>
                      <div
                        onClick={() => setDisplay('Done')}
                        className={`cursor-pointer px-2 ${display === 'Done' ? 'cursor-pointer font-medium text-white bg-dark-blue rounded-t py-1' : 'cursor-pointer hover:text-white hover:bg-dark-blue rounded-t py-1'}`}>
                        Done ({completedCount})
                      </div>
                    </div>
                    <div onClick={addTaskClicked} title='Add Task'>
                      <FontAwesomeIcon icon={faPlusCircle} className="text-hub-primary-hover cursor-pointer h-5 w-5 hover:text-hub-primary" />
                    </div>
                  </div>
                    <div className='overflow-y-scroll h-[22rem]'>
                      <ShowDisplay />
                    </div>
                  </div>
                </div>
            </div>
        </div>
        <div className='col-span-1 w-full'>  
          <div className='rounded-xl bg-white'>
            <Calendar />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-6 my-10 ">
        <div className='col-span-1'>
          <section className="shadow py-6 px-4 rounded-xl bg-white">
            <div className=''>
              <div className='space-y-4'>
                <div className='flex items-center justify-between mb-6'>
                  <h2 className="text-2xl font-medium leading-6 text-dark-blue">Nurse Availability</h2>
                  <p className="text-gray-600">{moment().format('DD/MM/YYYY')}</p>
                </div>
                <div className='h-[28rem] overflow-y-scroll'>
                  {availabilities.length > 0 ? (
                    sortedAvailabilities().map((group) => (
                      <div key={group.date}>
                        <div className='font-semibold mb-2'>{group.date}</div>
                        {group.availabilities.map((availability) => (
                          <div className='flex items-start mb-4' key={availability.id}>
                            <div className='w-14 mr-4'>
                              <img
                                src={availability.userProfile}
                                alt="User Profile"
                                className="w-12 h-12 rounded-full border border-gray-100"
                              />
                            </div>
                            <div className='w-full'>
                              <div className='font-medium'>{availability.user}</div>
                              <div className='flex items-center justify-between text-[12px]'>
                                <div>{moment(availability.start_time).format('h:mm a')} - {moment(availability.end_time).format('h:mm a')}</div>
                                <div className='flex justify-end'>
                                  <span className='text-emerald-600 text-xs text-right'>{availability.type}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))
                  ) : (
                    <div className='bg-white rounded-2xl shadow-md p-4 flex items-center space-x-4 w-full'>
                      <p className="no-records">No listed nurse availability</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div> 
    </section>
  )
}

export default Dashboard
