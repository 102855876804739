import { Link } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'

function ClientNav({ activeTab, setActiveTab, organisation = {} }) {
  const { admin } = useAuth()

  return (
    <div className="mb-8">
      <ul className="flex flex-col md:flex-row flex-wrap list-none px-0">
        <li 
            className={`tab-btn ${activeTab === 'details' ? 'active' : ''}`} 
            onClick={() => setActiveTab('details')}
          >
          Profile
        </li>
        <li 
          className={`tab-btn ${activeTab === 'enquiries' ? 'active' : ''}`} 
          onClick={() => setActiveTab('enquiries')}
        >
          Enquiries
        </li>
        <li 
          className={`tab-btn ${activeTab === 'comms' ? 'active' : ''}`} 
          onClick={() => setActiveTab('comms')}
        >
          Comms
        </li>
        <li 
          className={`tab-btn ${activeTab === 'documents' ? 'active' : ''}`} 
          onClick={() => setActiveTab('documents')}
        >
          Documents
        </li>
      </ul>
    </div>
  )
}

export default ClientNav
