/* eslint-disable */
import { useState, useEffect } from 'react'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import MainTitle from '../../../components/MainTitle'
import OrgTitle from '../../../components/OrgTitle'
import { useParams } from 'react-router-dom'
import useAuth from '../../../hooks/useAuth'
import OrganisationNav from '../../../components/Admin/OrganisationNav'
import moment from 'moment'
import { faPenToSquare, faRefresh, faSpinner, faUpload, faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PhoneNumberUtil } from 'google-libphonenumber'
import PossibleMatchCards from '../../../components/PossibleMatchCards'
import Modal from '../../../components/Modal'
import Notes from './Notes'
import History from './History'
import Enquiries from './Enquiries'
import Staff from './Staff'
import Referrals from './OrgReferrals'
import Contacts from './OrgContacts'
import OrgPPQ from './OrgPPQ'

const Organisation = () => {
  const { id } = useParams()
  const axios = useAxiosPrivate()
  const [editMode, setEditMode] = useState(false)
  const [editOrganisationData, setEditOrganisationData] = useState({})
  const [editOrganisationDetails, setEditOrganisationDetails] = useState({})
  const [contactMethods, setContactMethods] = useState([])
  const [editContactMethod, setEditContactMethod] = useState([])
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isValid, setIsValid] = useState(true)
  //Modal consts
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [link, setLink] = useState({})
  const [type, setType] = useState('')
  //Banner consts
  const [organisationPolicy, setOrganisationPolicy] = useState({})
  const [bannerImage, setBannerImage] = useState('')
  const [bannerColor, setBannerColor] = useState('')
  const [bannerBorderColor, setBannerBorderColor] = useState('')
  const [activeTab, setActiveTab] = useState('')

  const [openLinks, setOpenLinks] = useState(false)
  const [organisationData, setOrganisationData] = useState({
    name: '',
    reference: 'HUB-1',
  })
  const { setShowLoader, createInfo } = useAuth()

  const handlePhoneNumberChange = (event) => {
    const { value } = event.target
    setPhoneNumber(value)
    const isValidNumber = validatePhoneNumber(value)
    setIsValid(isValidNumber)
  }

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberUtil = PhoneNumberUtil.getInstance()
    try {
      const parsedNumber = phoneNumberUtil.parse(phoneNumber, '+44')
      const isValidNumber = phoneNumberUtil.isValidNumber(parsedNumber)
      return isValidNumber
    } catch (e) {
      console.error('Error parsing phone number:', e)
      return false
    }
  }

  const handleInputChange = (fieldName, e) => {
    setEditOrganisationData({ ...editOrganisationData, [fieldName]: e.target.value })
  }

  const handleDetailsChange = (fieldName, e) => {
    setEditOrganisationDetails({ ...editOrganisationDetails, [fieldName]: e.target.value })
  }

  const handleContactMethod = (method) => {
    const updatedContactMethods = [...editContactMethod]
    const index = updatedContactMethods.findIndex(
      (contactMethod) => contactMethod.id === method.id
    )

    if (index !== -1) {
      updatedContactMethods.splice(index, 1)
    } else {
      updatedContactMethods.push(method)
    }

    setEditContactMethod(updatedContactMethods)
  }

  const handleEditClick = () => {
    setEditMode((prevState) => !prevState)
    setEditOrganisationData({ ...organisationData })
    setEditOrganisationDetails({ ...organisationData.organisation_details })
    setIsValid(true)
  }

  useEffect(() => {
    if (organisationData.organisation_details?.phone_number) {
      setPhoneNumber(organisationData.organisation_details.phone_number)
    }
  }, [organisationData?.organisation_details])

  const handleSaveClick = async () => {
    let data = {}
    data.organisation = editOrganisationData
    data.organisation_details = editOrganisationDetails

    // const contactMethodIds = editContactMethod.map((method) => method.id)
    // data.contact_method_ids = contactMethodIds

    try {
      setShowLoader(true)
      const response = await axios.post(`/organisations/update/${id}`, data)
      setOrganisationData(response.data.result)
      setEditMode(false)
    } catch (error) {
      console.error(error)
    }
    setShowLoader(false)
  }

  const getOrganisationById = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/organisations/${id}`)
      await setOrganisationData(response.data.result)
      
      let orgPolicy = JSON.parse(response.data.result.policy_information)[0]
      setOrganisationPolicy(orgPolicy)
      setActiveTab('details')

      getDepartmentBannerAttributes(orgPolicy.department)
      if(orgPolicy.status == 'pending') {
        setBannerColor('bg-[#d4d5da]')
        setBannerBorderColor('border-[#d4d5da]')
      }

    //   const contactMethodsArray = Object.values(
    //     response.data.result.contact_methods
    //   )
    //   setEditContactMethod(contactMethodsArray)
      setShowLoader(false)
    } catch (error) {
      console.log('failed to get organisation')
     }
  }
  
  const getDepartmentBannerAttributes = (department) => {
    switch(department) {
      case "SAS":
        setBannerImage("/images/logo_sas.png")
        setBannerColor("bg-[#31aae1]")
        setBannerBorderColor("border-[#31aae1]")
        return
      case "AIS":
        setBannerImage("/images/logo_ais.png")
        setBannerColor("bg-[#FFD324]")
        setBannerBorderColor("border-[#FFD324]")
        return
      case "Mutual":
        setBannerImage("/images/logo_schools_mutual.png")
        setBannerColor("bg-[#31aae1]")
        setBannerBorderColor("border-[#31aae1]")
        return
      case "SWB":
        setBannerImage("/images/swb-logo-heart.png")
        setBannerColor("bg-[#e7a3ca]")
        setBannerBorderColor("border-[#e7a3ca]")
        return
      case "GBS":
        setBannerImage("/images/logo_gbs.png")
        setBannerColor("bg-[#58cc6c]")
        setBannerBorderColor("border-[#58cc6c]")
        return
      default:
        setBannerImage("/images/logo_naht.png")
        setBannerColor("bg-[#4E5F80]")
        setBannerBorderColor("border-[#4E5F80]")
        return
    }
  }

  const getContactMethods = async () => {
    try {
      const response = await axios.get(`/contact-methods/all`)
      setContactMethods(response.data.result)
    } catch (error) { }
  }

  useEffect(() => {
    getOrganisationById(id)
    getContactMethods()
  }, [id])

  const openLinkClicked = (link, type) => {
    setOpenLinks(true);
    setLink(link);
    setType(type);
    const jsxTitle = (
      <span className='font-medium'>
        You are viewing {type === 'kis' ? 'KIS' : 'WBA'} link: {link.name}
        </span>
    );
    setModalTitle(jsxTitle);
    setShowModal(true);
  };

  const ModalBody = () => {
    if(openLinks){
      return <LinkDetailsElement />
    }
  }

  const refreshOrganisation = async () => {
    setShowLoader(true);
    let url = "https://test.kisdigital.co.uk/api/v1/singl/organisation/refresh?api_token=9e12909f-7aca-4513-aae7-28fd193cd3bb"
    await axios.post(url, organisationData).then((res) => {
      axios.post(`/organisations/refresh/${id}`, res.data.data).then(() => {
        setShowLoader(false);
        createInfo('success', `Organisation Updated`)

        setTimeout(() => {
          window.location.reload();
        }, 5000)
      });
    })
  }

  const LinkDetailsElement = () => {
    return (
      <div>
         {type === 'kis' && (
           <>
            <div className="mb-3">
              Name: {JSON.parse(link.kis_link).name}
            </div>
            <div className="mb-3"><span>Email: {JSON.parse(link.kis_link).email}</span> </div>
            <div className="mb-3"><span>DOB: </span> {moment(JSON.parse(link.kis_link).date_of_birth).format('DD/MM/YYYY')} </div>
            <div className="mb-3"><span>Academy name: </span>{JSON.parse(link.kis_link)?.academy_name}</div>
            <div className="mb-3"><span>Academy URN: </span>{JSON.parse(link.kis_link)?.academy_urn}</div>
            <div className="mb-3"><span>Policy Reference: </span>{JSON.parse(link.kis_link)?.policy_reference}</div>
            <div className="mb-3"><span>Policy Expire: </span>{JSON.parse(link.kis_link)?.policy_expire}</div>
          </>
        )} 
         {type === 'wba' && (
           <>
           <div className="mb-3">
             Name: {JSON.parse(link.wba_link).name}
           </div>
           <div className="mb-3"><span>Email: {JSON.parse(link.wba_link).email}</span> </div>
           <div className="mb-3"><span>DOB: </span> {moment(JSON.parse(link.wba_link).date_of_birth).format('DD/MM/YYYY') ?? ''} </div>
           <div className="mb-3"><span>Reference: </span>{JSON.parse(link.wba_link)?.reference}</div>
         </>
        )} 
        <div className="flex mt-2 w-full">
          <button
            type="button"
            className="btn red mt-4 w-full"
            onClick={resetModal}>
            Close
          </button>
        </div>
      </div>
    )
  }

  const resetModal = () => {
    setShowModal(false)
    setOpenLinks(false)
    setType('');
    setModalTitle('')
  }

  return (
    <div className='flex items-start space-x-4'>
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}
      <div className='w-2/3 max-w-3xl 2xl:max-w-none'>
        <OrgTitle
          org={organisationData}
          status={organisationData?.status}
          orgPolicy={organisationPolicy}
          backButton
        />
        <OrganisationNav activeTab={activeTab} setActiveTab={setActiveTab} organisation={organisationData} />
        {activeTab === 'details' && <Referrals policy={organisationPolicy} />}
        {activeTab === 'claims' && <OrgPPQ policy={organisationPolicy} />}
        {activeTab === 'contacts' && <Contacts />}
        {activeTab === 'history' && <History />}
        {activeTab === 'enquiries' && <Enquiries />}
        {activeTab === 'staff' && <Staff />}
      </div>
      <div className='w-1/3'>
        {organisationPolicy && (
          <div className={"w-full flex justify-center mb-4 mt-8"}>
            <div id="policyBanner" className={"w-full items-center p-4 border rounded-lg shadow-md text-wrap flex justify-between text-white " + bannerColor + " " + bannerBorderColor}>

              {organisationPolicy?.department == 'SWB' ? (
                <div className={" text-center"}>
                  <img className={"mx-auto"} width="40" src={bannerImage} />
                </div>
              ) : (
                <div className={" text-center"}>
                  <img className={"mx-auto"} width="120" src={bannerImage} />
                </div>
              )}
              
              <div className={" text-center"}>
                <div>
                  <p className={"text-lg font-bold mb-2"}>{organisationPolicy?.product}</p>
                </div>

                <div>
                  <p className={"text-sm font-semibold"}>{organisationPolicy.inception} - {organisationPolicy.expire}</p>
                </div>
              </div>

              <div className={" text-center"}>
                  <div className={"text-center flex justify-center items-center mb-2"}>
                    {organisationPolicy.status == 'live' && (
                      <FontAwesomeIcon icon={faThumbsUp} transform={"grow-5"}></FontAwesomeIcon>
                    )}

                    {organisationPolicy.status == 'previous' && (
                      <FontAwesomeIcon icon={faThumbsDown} transform={"grow-5"}></FontAwesomeIcon>
                    )}

                    {organisationPolicy.status != 'live' && organisationPolicy.status != 'previous' && (
                      <FontAwesomeIcon icon={faThumbsUp} className={"rotate-90"} transform={"grow-5"}></FontAwesomeIcon>
                    )}
                  </div> 
                  <p className={"text-base font-semibold mt-2"}>{organisationPolicy.status}</p>
              </div>
            </div>
          </div>
        )}

        {/* {organisationData.id && (
          <section className="w-full bg-white rounded-xl shadow-md py-4 px-4 mb-8 text-gray-700">
            {editMode && (
              <div className="py-3 px-4 bg-teal-200 rounded-lg mb-4 text-xs">
                <span className="font-medium">You are in Editing Mode.</span>{' '}
                Click the pen icon to return to viewing or save to update
                details.{' '}
              </div>
            )}
            <div className="flex items-center justify-between">
            <h2 className="font-bold text-lg mb-2">Contact Preferences</h2>
              <div>
                <FontAwesomeIcon
                  title="Edit Organisation"
                  onClick={handleEditClick}
                  icon={faPenToSquare}
                  className={`text-xl hover:text-blue-500 cursor-pointer ${editMode ? 'text-blue-500' : ''
                    }`}
                />
              </div>
            </div>
            <div className='text-sm'>
              <p className="text-wba-primary font-medium mr-2 pb-2">Preferred Method of Contact:</p>
            </div>
            <div className="mb-2 flex items-center space-x-4">
              <div>
                {editMode ? (
                  <div className="flex">
                    {contactMethods?.map((method) => {
                      return (
                        <div key={method.id} className="items-center flex">
                          <input
                            type="checkbox"
                            id="contact_method"
                            value={method.title}
                            defaultChecked={
                              Array.isArray(editContactMethod) &&
                              editContactMethod
                                .map((e) => e.title)
                                .includes(method.title)
                            }
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                            onChange={() => handleContactMethod(method)}
                          />
                          <label
                            htmlFor="clinic_details"
                            className="ml-2 mr-4 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            {method.title}
                          </label>
                        </div>
                      )
                    })}
                  </div>
                ) : (
                  <div>
                    {organisationData?.contact_methods?.length > 0 && (
                      <div className="flex space-x-4">
                        {organisationData?.contact_methods?.map((method) => {
                          return <div key={method.id}>{method.title}</div>
                        })}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="mb-2 pt-2 text-sm">
              <span className="text-wba-primary font-medium mr-2">
                Preferred Contact Time:
              </span>
              {editMode ? (
                <input
                  type="text"
                  className="border-2 border-slate-400 rounded-lg px-2 py-1"
                  defaultValue={editOrganisationDetails.preferred_contact_time}
                  onChange={(e) => handleDetailsChange('preferred_contact_time', e)}
                />
              ) : (
                <span>
                  {organisationData.organisation_details?.preferred_contact_time ?? ''}
                </span>
              )}
            </div>
            <hr className="mt-4 pb-4" />
            <div className='flex my-4 space-x-4 justify-end'>
              {editMode &&  (
                  <button
                    onClick={handleSaveClick}
                    className="btn primary"
                  >
                    <FontAwesomeIcon icon={faUpload} />
                    <span className="pl-1">Save</span>
                  </button>
              )}
            </div>
          </section>
        )} */}
        <Notes />
      </div>
    </div>
  )
}

export default Organisation
