import React, { useRef, useState, useEffect } from 'react'
import useAuth from '../hooks/useAuth'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import axios from '../api/axios'

const LOGIN_URL = '/login'

const Login = () => {
  const { setAuth } = useAuth()

  const navigate = useNavigate()
  const location = useLocation()
  const from = location.state?.from?.pathname || '/'

  const emailRef = useRef()
  const errRef = useRef()

  const [email, setEmail] = useState('')
  const [pwd, setPwd] = useState('')

  const [errMsg, setErrMsg] = useState('')

  useEffect(() => {
    emailRef.current.focus()
  }, [])

  useEffect(() => {
    setErrMsg('')
  }, [email, pwd])

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const response = await axios.post(LOGIN_URL, { email, password: pwd })
      // const token = response.data.result.token
      const user = response.data.result.user
      let id = user.id
      // setAuth({ email, pwd, user, token })
      localStorage.setItem('login', true)
      setEmail('')
      setPwd('')
      navigate('/2fa', { state: id })
    } catch (error) {
      if (!error?.response) {
        setErrMsg('No Server Response')
      } else if (error.response?.status === 400) {
        setErrMsg('Email or Password Missing.')
      } else if (error.response?.status === 401) {
        setErrMsg('Incorrect Email or Password entered.')
      } else {
        setErrMsg('Error Occured. Could not log you in.')
      }
      errRef.current.focus()
    }
  }

  // const togglePersist = () => {
  //   setPersist((prev) => !prev)
  // }

  // useEffect(() => {
  //   localStorage.setItem('persist', persist)
  // }, [persist])

  return (
    <div className='p-6 space-y-4 md:space-y-6 sm:p-8'>
      <h1 className="text-2xl font-bold text-center pb-4 leading-tight tracking-tight text-gray-700 md:text-2xl dark:text-white">
        Login
      </h1>
      <h2 className='text-gray-400 text-md'>Welcome! To login, enter your details below and click 'login'.</h2>
      <p
        ref={errRef}
        className={errMsg ? 'errmsg' : 'offscreen'}
        aria-live="assertive"
      >
        {errMsg}
      </p>
      <form onSubmit={handleSubmit} className='space-y-4 md:space-y-6'>
        <div className=" w-full">
          <label htmlFor="email" className='block mb-2 text-sm font-medium text-gray-700 dark:text-white'>Email</label>
          <input
            type="email"
            ref={emailRef}
            id="email"
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Email"
            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:outline-none focus:border-violet-300 focus:ring-1 focus:ring-violet-300 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>

        <div className="">
          <label htmlFor="pwd" className='block mb-2 text-sm font-medium text-gray-700 dark:text-white'>Password</label>
          <input
            type="password"
            id="pwd"
            onChange={(e) => setPwd(e.target.value)}
            required
            placeholder="Password"
            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:outline-none focus:border-violet-300 focus:ring-1 focus:ring-violet-300 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>

        <div className="mt-4 flex justify-end text-sm font-medium text-gray-700  dark:text-primary-500">
          {/* <div className='flex items-center'>
                <label htmlFor='persist' className='mr-2'>
                  Remember Me
                </label>
                <input type='checkbox' id='persist' onChange={togglePersist} placeholder='Remember' className='field' checked={persist} />
              </div> */}

          <Link to="/forgot-password" className=" hover:text-violet-400">
            Forgot Password?
          </Link>
        </div>

        <div className="input-wrap ">
          <input className='' type="submit" value="Login" />
        </div>
      </form>
    </div>
  )
}

export default Login
