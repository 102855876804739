import { useState } from "react";
import MainTitle from "../../components/MainTitle";
import useAuth from "../../hooks/useAuth";
import CardButton from "../../components/CardButton";
import AccountSettings from "./AccountSettings";
import User from './Settings/Users'
import Permissions from "./Settings/Permissions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSliders, faUsers } from "@fortawesome/free-solid-svg-icons";

function Settings() {
  const { can } = useAuth();
  const [activeTab, setActiveTab] = useState("permissions")

  return (
    <section>
      <MainTitle title="Settings" />

      <ul className="flex items-center flex-wrap">
        {can("access permissions") && (
          <li
            className={`w-1/4 mb-6 mr-3 cursor-pointer ${
              activeTab === "permissions" ? "bg-light-purple" : "bg-white"
            }`}
            onClick={() => setActiveTab("permissions")}
          >
            <div className={`p-3 border-dark-purple rounded-md shadow flex items-center hover:bg-light-purple ${
              activeTab === "permissions" ? "bg-light-purple" : "bg-white"
            }`}>
              <div className="bg-nav rounded-lg px-4 py-3">
                <FontAwesomeIcon icon={faSliders} className="text-3xl text-white" />
              </div>
              <h5 className="text-xl font-semibold tracking-tight text-nav ml-4">
                Permissions
              </h5>
            </div>
          </li>
        )}

        {can("access all users") && (
          <li
            className={`w-1/4 mb-6 mr-3 cursor-pointer ${
              activeTab === "users" ? "bg-light-purple" : "bg-white"
            }`}
            onClick={() => setActiveTab("users")}
          >
            <div className={`p-3 border-dark-purple rounded-md shadow flex items-center hover:bg-light-purple ${
              activeTab === "users" ? "bg-light-purple" : "bg-white"
            }`}>
              <div className="bg-nav rounded-lg px-4 py-3">
                <FontAwesomeIcon icon={faUsers} className="text-3xl text-white" />
              </div>
              <h5 className="text-xl font-semibold tracking-tight text-nav ml-4">
                Users
              </h5>
            </div>
          </li>
        )}
      </ul>

      <div className="mt-6">
        {activeTab === "permissions" && <Permissions />} 
        {activeTab === "users" && <User />}
      </div>
    </section>
  );
}

export default Settings;

