/* eslint-disable */
import { useState, useEffect, Fragment } from 'react'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import MainTitle from '../../../components/MainTitle'
import { useParams } from 'react-router-dom'
import useAuth from '../../../hooks/useAuth'
import Pagination from '../../../components/Pagination';
import PolicyCards from '../../../components/PolicyCards'
import ClientNav from '../../../components/Admin/ClientNav'
import CaseCards from '../../../components/CaseCards'
import { Menu, Transition, Dialog } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faEye, faSortUp, faSort, faSortDown, faPlus, faChevronDown, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

const ClientHistory = () => {
  const { id } = useParams()
  const axios = useAxiosPrivate()

  const { setShowLoader } = useAuth()
  const [latestPolicy, setLatestPolicy] = useState([])
  const [clientData, setClientData] = useState({
    name: '',
    reference: 'HUB-1',
  })

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending',
  });
  
  // search
  const [filteredRecords, setFilteredRecords] = useState([])
  const [nPages, setNumberOfPages] = useState(0)
  const [currentContacts, setCurrentContacts] = useState([])
  const [currentClaims, setCurrentClaims] = useState([])
  const [showModal, setShowModal] = useState(false)

  // No of Records to be displayed on each page
  const [currentPage, setCurrentPage] = useState(1)
  const [recordsPerPage] = useState(10)
  const [indexOfLastRecord, setIndexOfLastRecord] = useState(10)
  const [indexOfFirstRecord, setIndexOfFirstRecord] = useState(0)
  const [originalRecords, setOriginalRecords] = useState([])
  const [history, setHistory] = useState([])
  const [selectedHistory, setSelectedHistory] = useState()
  const [columnClickCounts, setColumnClickCounts] = useState({})

  const [isLoadingKisClaims, setIsLoadingKisClaims] = useState(false)
  const [kisClaims, setKisClaims] = useState([])
  const [selectedKisClaim, setSelectedKisClaim] = useState()

  const [tableView, setTableView] = useState('')

  const getClientById = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/clients/` + id)
         setClientData(response.data.result)
         if(response.data.result.policy) {
          setLatestPolicy(response.data.result.policy)
         }
    } catch (error) {
      console.log(error)
      setShowLoader(false)
    }
    setShowLoader(false)
  }

  const getOrganisationById = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/organisations/${id}`)
      setOrganisationData(response.data.result)
      setHistory(JSON.parse(response.data.result.policy_information))
      setSelectedHistory(JSON.parse(response.data.result.policy_information)[0])
      setShowLoader(false)
    } catch (error) {}
  }


  const nextPage = () => {
    if (currentPage < nPages) {
      const newPage = currentPage + 1;
      const indexFirst = newPage * recordsPerPage - recordsPerPage;
      const indexLast = newPage * recordsPerPage;
  
      setIndexOfFirstRecord(indexFirst);
      setIndexOfLastRecord(indexLast);
      setCurrentPage(newPage);
      const sortedRecords = sortArray(originalRecords, sortConfig.key, sortConfig.direction);
      if(tableView == 'Contacts') {
        setCurrentContacts(sortedRecords.slice(indexFirst, indexLast));
      } else {
        setCurrentClaims(sortedRecords.slice(indexFirst, indexLast));
      }
    }
  }
  
  const previousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      const indexFirst = (newPage - 1) * recordsPerPage;
      const indexLast = newPage * recordsPerPage;
  
      setIndexOfFirstRecord(indexFirst);
      setIndexOfLastRecord(indexLast);
      setCurrentPage(newPage);
  
      const sortedRecords = sortArray(originalRecords, sortConfig.key, sortConfig.direction);
      if(tableView == 'Contacts') {
        setCurrentContacts(sortedRecords.slice(indexFirst, indexLast));
      } else {
        setCurrentClaims(sortedRecords.slice(indexFirst, indexLast));
      }
      
    }
  }

  const renderSortArrow = (columnKey) => {
    if (sortConfig.key === columnKey) {
      if (sortConfig.direction === 'ascending') {
        return <FontAwesomeIcon icon={faSortUp} />;
      } else {
        return <FontAwesomeIcon icon={faSortDown} />;
      }
    }
    return <FontAwesomeIcon icon={faSort} />;
  };

  const handleSort = (key) => {
    let newColumnClickCounts = { ...columnClickCounts };
    if (!newColumnClickCounts[key]) {
      newColumnClickCounts[key] = 1;
    } else {
      newColumnClickCounts[key]++;
    }

    if (newColumnClickCounts[key] === 3) {
      setSortConfig({
        key: null,
        direction: 'descending',
      });
      newColumnClickCounts[key] = 0;
      
      if(tableView == 'Contacts') {
        setCurrentContacts(originalRecords.slice(
          indexOfFirstRecord,
          indexOfLastRecord
        ));
      } else {
        setCurrentClaims(originalRecords.slice(
          indexOfFirstRecord,
          indexOfLastRecord
        ));
      }
    } else {
      let direction =
        sortConfig.direction === 'ascending'
          ? 'descending'
          : 'ascending';
      setSortConfig({ key, direction });
  
      const sortedRecords = sortArray(
        originalRecords,
        key,
        direction
      );
      
      if(tableView == 'Contacts') {
        setCurrentContacts(sortedRecords.slice(
          indexOfFirstRecord,
          indexOfLastRecord
        ));
      } else {
        setCurrentClaims(sortedRecords.slice(
          indexOfFirstRecord,
          indexOfLastRecord
        ));
      }
    }
    setColumnClickCounts(newColumnClickCounts);
  };

  const sortArray = (array, key, direction) => {
    return [...array].sort((a, b) => {
      
      const segments = key.split('.');
      let aKey = a;
      let bKey = b;

      segments.forEach(element => {
        if(!aKey[element]) {
          aKey = '';
          return;
        }

        if(!bKey[element]) {
          bKey = '';
          return;
        }

        aKey = aKey[element];
        bKey = bKey[element];
      });

      if (aKey < bKey) return direction === 'ascending' ? -1 : 1;
      if (aKey > bKey) return direction === 'ascending' ? 1 : -1;
      return 0;
    });
  };

  const getkisClaims = async () => {
    if(isLoadingKisClaims || !clientData?.staff_id) { return; }

    try {
      const clientId = clientData.staff_id;

      setShowLoader(true)

      let url = `https://test.kisdigital.co.uk/api/v1/cases/by-client/${clientId}?api_token=9e12909f-7aca-4513-aae7-28fd193cd3bb`;

      setIsLoadingKisClaims(true)

      const response = await axios.get(url)

      await setKisClaims([...response.data.cases])
      
      setIsLoadingKisClaims(false)
      setShowLoader(false)
    } catch (error) {
      setIsLoadingKisClaims (false)
    }
  }

  useEffect(() => {
    getClientById(id)
    getOrganisationById()
  }, [id])

  useEffect(() => {
    getkisClaims()
  }, [clientData])

  return (
    <div>
      <section>
        <section className="w-full border rounded-xl py-4 px-1 mb-8">
          <h2 className="font-bold text-lg mb-2 mx-3">Policy History</h2>
          {history !== null ? (
            <>
              <ul className="flex flex-col md:flex-row flex-wrap list-none px-0 mb-4 mx-3">
                {history?.map((item) => {

                  let className = "tab-btn bg-gray-200 mb-4";
                  if(selectedHistory && item.reference === selectedHistory.reference) {
                    className += " active";
                  }

                  return (
                    <li key={item.id} className={className} onClick={() => {selectedHistory && selectedHistory.id === item.id ? setSelectedHistory(null) : setSelectedHistory(item)}}>
                      {item.reference}
                    </li>
                  )})
                }
              </ul>
              {selectedHistory && (
                <div className="flex flex-col">
                  <PolicyCards policy={selectedHistory} isKis={false} />
                </div>
              )}
            </>
          ) : (<p>There is no History for this organisation</p>) }          
        </section>
        
        <section className="w-full bg-white rounded-xl border py-4 px-4 mb-8">
          <h2 className="font-bold text-lg mb-2">Case History</h2>
          {kisClaims.length > 0 ? (
            <>
              <ul className="flex flex-col md:flex-row flex-wrap list-none px-0 mb-4 mx-3">
                { kisClaims?.map((claim) => {

                  let className = "tab-btn bg-gray-200";
                  if(selectedKisClaim && claim.id === selectedKisClaim.id) {
                    className += " active";
                  }

                  return (
                    <li key={claim.id} className={className} onClick={() => {selectedKisClaim && selectedKisClaim.id === claim.id ? setSelectedKisClaim(null) : setSelectedKisClaim(claim); setTableView('')}}>
                      {claim.case_reference}
                    </li>
                  )})
                }
              </ul>
              {selectedKisClaim && (
                <div className="flex flex-col">
                  <CaseCards 
                      key={selectedKisClaim.id} 
                      claim={selectedKisClaim} 
                      setTableView={setTableView}
                      setCurrentContacts={setCurrentContacts}
                      setCurrentClaims={setCurrentClaims} />
                </div>
              )}
            </>
          ) : (<p>There is no kis case information</p>) }
        </section>

        {/* Tables */}
        {tableView == 'Contacts' && (
          <section className="w-full bg-white rounded-xl shadow-md py-4 px-4 mb-8">
            <h2 className="font-bold text-xl mb-2">Case Contacts</h2>
            <table className="!overflow-auto table-main mt-4 mb-10">
              <thead>
                <tr>
                  <th className='cursor-pointer' scope="col" onClick={() => handleSort('contact.contact_type')}>Contact Type {renderSortArrow('contact.contact_type')}</th>
                  <th className='cursor-pointer' scope="col" onClick={() => handleSort('contact.description')}>Contact Details {renderSortArrow('contact.description')}</th>
                  <th className='cursor-pointer' scope="col" onClick={() => handleSort('contact.created_at')}>Created On {renderSortArrow('contact.created_at')}</th>
                  <th className='cursor-pointer' scope="col" onClick={() => handleSort('contact.created_by_name')}>Created By {renderSortArrow('contact.created_by_name')}</th>
                  <th className='cursor-pointer' scope="col" onClick={() => handleSort('contact.withdrawal_reason')}>Withdrawal Reason {renderSortArrow('contact.withdrawal_reason')}</th>
                  {/* <th scope="col">Actions</th> */}
                </tr>
              </thead>
              <tbody>
              {currentContacts.length > 0 ? (
                currentContacts?.map((contact) => {

                  let description = contact?.contact_description.match('^s:[0-9][0-9]+:\\"(.*?)\\";')[1]

                  return (
                    <tr key={contact.id} className={contact?.withdrawn == '1' ? ("!bg-red-200") : ("")}>
                      <td>{contact?.contact_type_id}</td>
                      <td>{description.length > 35 ? (
                        description.substring(0, 32) + '...'
                      ) : (
                        description
                      )}</td>
                      <td>{moment(contact.created_at).utc().format('DD/MM/YYYY HH:mm:ss')}</td>
                      <td>{contact.created_by_user}</td>
                      <td>{contact.withdraw_reason ? contact.withdraw_reason : ''}</td>
                      {/* <td>
                      <Menu as="div" className="relative inline-block text-left">
                        <div>
                          <Menu.Button className="flex items-center rounded-md cursor-pointer ml-2 hover:bg-gray-300 px-2 py-1">
                            <span className="font-semibold text-sm mr-2">
                              Actions
                            </span>
                            <FontAwesomeIcon icon={faChevronDown} />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >                            
                          <Menu.Items className="z-50 absolute right-0 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="p-2 space-y-2 relative">
                              <Menu.Item>
                                <div onClick={() => viewContactClicked(
                                  contact.id, 
                                  contact.contact_type_id, 
                                  contact.description, 
                                  contact.contact_outcome_id,
                                  contact.created_at,
                                  contact.created_by_name,
                                  contact.withdrawn,  
                                  contact.withdrawn_reason
                                  )}  title="View Contact" className="flex justify-start items-center px-2 py-1 rounded-md hover:bg-blue-200 cursor-pointer">
                                  <FontAwesomeIcon icon={faEye} className='text-blue-800' /> <div className='pl-2'>View</div>
                                </div>
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                      </td> */}
                    </tr>
                    )
                })
              ) : (
                <tr>
                  <td colSpan={8}>
                    <p className="no-records">No contacts found</p>
                  </td>
                </tr> 
              )}
              </tbody>
            </table>

            <Pagination
              next={nextPage}
              prev={previousPage}
              first={indexOfFirstRecord}
              last={indexOfLastRecord}
              total={filteredRecords.length}
            />
          </section> 
        )}
        
        {tableView == 'Claims' && (
          <section className="w-full bg-white rounded-xl shadow-md py-4 px-4 mb-8">
            <h2 className="font-bold text-xl mb-2">Case Claims</h2>
            <table className="!overflow-auto table-main mt-4 mb-10">
              <thead>
                <tr>
                    <th className='cursor-pointer' scope="col" onClick={() => handleSort('claim_reference')}>Reference {renderSortArrow('claim_reference')}</th>
                    <th className='cursor-pointer' scope="col" onClick={() => handleSort('case_service.wbs_service.service_name')}>Service {renderSortArrow('case_service.wbs_service.service_namervice')}</th>
                    <th className='cursor-pointer' scope="col" onClick={() => handleSort('case_service.sessions_allocated')}>Sessions Accessed {renderSortArrow('case_service.sessions_allocated')}</th>
                    <th className='cursor-pointer' scope="col" onClick={() => handleSort('claim_status')}>Status {renderSortArrow('claim_status')} </th>
                    <th className='cursor-pointer' scope="col" onClick={() => handleSort('created_at')}>Created At {renderSortArrow('created_at')}</th>
                    {/* <th scope="col">Actions</th> */}
                  </tr>
              </thead>
              <tbody>
              {currentClaims.length > 0 ? (
                currentClaims?.map((claim) => {
                  return (
                    <tr key={claim.id}>
                      <td>{claim.claim_reference}</td>
                      <td>{claim?.case_service.wbs_service.service_name}</td>
                      <td>{Number(claim?.case_service.sessions_allocated) + Number(claim?.case_service.extra_sessions)}</td>
                      <td>{claim.claim_status}</td>
                      <td>{moment(claim.created_at).format('DD/MM/YYYY')}</td>
                      {/* <td>
                        <Menu as="div" className="relative inline-block text-left">
                          <div>
                            <Menu.Button className="flex items-center rounded-md cursor-pointer ml-2 hover:bg-gray-300 px-2 py-1">
                              <span className="font-semibold text-sm mr-2">
                                Actions
                              </span>
                              <FontAwesomeIcon icon={faChevronDown} />
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="z-50 absolute right-0 mt-2  origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <div className="p-2 space-y-2 relative">
                                  <Menu.Item>
                                    <div onClick={() => viewClaimClicked(claim.id)} title="View Claim" className="flex justify-start items-center px-2 py-1 rounded-md hover:bg-blue-200 cursor-pointer">
                                      <FontAwesomeIcon icon={faEye} className='text-blue-800' /> <div className='pl-2'>View</div>
                                    </div>
                                  </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </td> */}
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={8}>
                    <p className="no-records">No contacts found</p>
                  </td>
                </tr> 
              )}
              </tbody>
            </table>

            <Pagination
              next={nextPage}
              prev={previousPage}
              first={indexOfFirstRecord}
              last={indexOfLastRecord}
              total={filteredRecords.length}
            />
          </section> 
        )}
        
      </section> 
    </div>
  )
}

export default ClientHistory

