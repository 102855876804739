import React, { useEffect, useState } from 'react';
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import useAuth from "../../../../hooks/useAuth";

const PRSForm = (setShowForm) => {
    const axios = useAxiosPrivate();
    const { setShowLoader, createInfo } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setShowLoader(true)
        const formData = {
            claimNumber:        e.target.claimNumber.value,
            name:               e.target.name.value,
            address:            e.target.address.value,
            postcode:           e.target.postcode.value,
            dateOfBirth:        e.target.dateOfBirth.value,
            gender:             e.target.gender.value,
            mobileNumber:       e.target.mobileNumber.value,
            landLineNumber:     e.target.landLineNumber.value,
            emailAddress:       e.target.emailAddress.value,
            nameOfInsurer:      e.target.nameOfInsurer.value,
            condition:          e.target.condition.value,
            onsetCondition:     e.target.onsetCondition.value,
            currentWorkStatus:  e.target.currentWorkStatus.value,
            additionalInfo:     e.target.additionalInfo.value,
            agreedSessions:     e.target.agreedSessions.value,
        }

        await axios.post('claims/send-wbs-form/prs', formData).then((response) => {
            createInfo('success', 'Form Sent')
            setShowForm(false);
            setShowLoader(false)
        })

        setShowLoader(false)
    }
    
    return (
        <section>
            <form onSubmit={handleSubmit}>
                <div>
                    <div className={"flex flex-col mb-2"}>
                        <label className={"text-md"}>Claim Number</label>
                        <input className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block pl-2 p-2.5"} type="text" id="claimNumber"></input>
                    </div>
                </div>

                <div>
                    <div className={"flex flex-col mb-2"}>
                        <label className={"text-md"}>Name</label>
                        <input className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block pl-2 p-2.5"} type="text" id="name"></input>
                    </div>
                </div>

                <div className={"flex mb-2"}>
                    <div className={"flex flex-col w-1/2 pr-1"}>
                        <label className={"text-md"}>Address</label>
                        <input className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block pl-2 p-2.5"} type="text" id="address"></input>
                    </div>

                    <div className={"flex flex-col w-1/2 pl-1"}>
                        <label className={"text-md"}>Postcode</label>
                        <input className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block pl-2 p-2.5"} type="text" id="postcode"></input>
                    </div>
                </div>

                <div>
                    <div className={"flex flex-col mb-2"}>
                        <label className={"text-md"}>Date of Birth</label>
                        <input className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block pl-2 p-2.5"} type="date" id="dateOfBirth"></input>
                    </div>
                </div>

                <div className={"flex mb-2"}>
                    <div className={"flex flex-col w-1/2 pr-1"}>
                        <label className={"text-md"}>Mobile Number</label>
                        <input className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block pl-2 p-2.5"} type="text" id="mobileNumber"></input>
                    </div>

                    <div className={"flex flex-col w-1/2 pl-1"}>
                        <label className={"text-md"}>Landline Number</label>
                        <input className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block pl-2 p-2.5"} type="text" id="landLineNumber"></input>
                    </div>
                </div>
                
                <div>
                    <div className={"flex flex-col mb-2"}>
                        <label className={"text-md"}>Email Address</label>
                        <input className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block pl-2 p-2.5"} type="text" id="emailAddress"></input>
                    </div>
                </div>

                <div className={"flex mb-2"}>
                    <div className={"flex flex-col w-1/2 pr-1"}>
                        <label className={"text-md"}>Name of Insurer</label>
                        <input className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block pl-2 p-2.5"} type="text" id="nameOfInsurer"></input>
                    </div>

                    <div className={"flex flex-col w-1/2 pl-1"}>
                        <label className={"text-md"}>Condition / Injury</label>
                        <input className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block pl-2 p-2.5"} type="text" id="condition"></input>
                    </div>
                </div>

                <div>
                    <div className={"flex flex-col mb-2"}>
                        <label className={"text-md"}>Onset of Condition</label>
                        <input className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block pl-2 p-2.5"} type="text" id="onsetCondition"></input>
                    </div>
                </div>

                <div>
                    <div className={"flex flex-col mb-2"}>
                        <label className={"text-md"}>Current Work Status</label>
                        <select className={"bg-white border-2 text-md rounded-md focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-violet-500 dark:focus:border-violet-500"} id="currentWorkStatus">
                            <option value="Full Duties">Full Duties</option>
                            <option value="Restricted Duties">Restricted Duties</option>
                            <option value="Off Sick">Off Sick</option>
                        </select>
                    </div>
                    
                </div>

                <div>
                    <div className={"flex flex-col mb-2"}>
                        <label className={"text-md"}>Additional Information</label>
                        <textarea className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block pl-2 p-2.5"} id="additionalInfo"></textarea>
                    </div>
                </div>

                <div>
                    <div className={"flex flex-col mb-2"}>
                        <label className={"text-md"}>Agreed Sessions</label>
                        <input className={"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block pl-2 p-2.5"} type="text" id="agreedSessions"></input>
                    </div>
                </div>

                <button className={"px-4 py-2 rounded-lg bg-violet-400 font-medium text-white hover:bg-violet-500 mr-2 mt-2"}>Send PRS Referral Form</button>
            </form> 
        </section>
        
    )
}

export default PRSForm