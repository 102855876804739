import {
  faTimes,
  faSearch,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useAuth from '../hooks/useAuth'
import moment from 'moment'
import { useState, useEffect } from 'react'
import {useNavigate } from 'react-router-dom'
import useLogout from '../hooks/useLogout'
import useAxiosPrivate from '../hooks/useAxiosPrivate'

function Header({ collapsed }) {
  const {  setShowLoader } = useAuth()
  const axios = useAxiosPrivate()
  const navigate = useNavigate()

  const [searchQuery, setSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const [searchType, setSearchType] = useState('staff')
  const [mediaWidth, setMediaWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setMediaWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const headerStyles = {
    width: 'calc(100% - 16rem)',
  }

  const toggleSearchType = (type) => 
  {
    if(searchType === type)
    {
      setSearchType(type)
    }else{
    setSearchType(type)
    }
    setSearchQuery('');
    setSearchResults([])
  }

  const runSearch = async () => {
    try {
      let kisResults = [];
      let localResults = [];
      let combinedResults = [];
      let url = "";
  
      if (searchType === "staff") {
        url = `https://test.kisdigital.co.uk/api/v1/singl/search/staff?api_token=9e12909f-7aca-4513-aae7-28fd193cd3bb`;
        const kisResponse = await axios.post(url, { q: searchQuery });
        kisResults = kisResponse?.data?.data || [];
  
        url = `/search/clients/${searchQuery}`;
        const localResponse = await axios.post(url);
        localResults = localResponse?.data?.result?.original?.result || [];
  
        combinedResults = [...localResults, ...kisResults];
        const filteredResults = combinedResults.filter(
          (item, index) =>
            index ===
            combinedResults.findIndex(
              (elem) =>
                elem.name === item.name && elem.date_of_birth === item.date_of_birth
            )
        );
  
        setSearchResults(filteredResults);
      } else if (searchType === "organisation") {
        url = `https://test.kisdigital.co.uk/api/v1/singl/org-search?api_token=9e12909f-7aca-4513-aae7-28fd193cd3bb`;
        const kisResponse = await axios.post(url, { q: searchQuery });
        kisResults = kisResponse?.data?.data || [];

        url = `/search/organisations/${searchQuery}`;
        const localResponse = await axios.post(url);
        localResults = localResponse?.data?.result?.original?.result || [];

        combinedResults = [...localResults, ...kisResults];
        const filteredResults = combinedResults.filter(
          (item, index) =>
            index ===
            combinedResults.findIndex(
              (elem) =>
                elem.name === item.name &&
                elem.unique_reference === item.unique_reference
            )
        );
  
        setSearchResults(filteredResults);
      }
    } catch (error) {
      console.error("Error during search:", error);
      setSearchResults([]);
    }
  };
  
  // const runSearch = async () => {
  //   let url = `
  //   let kisStaff = [];
  //   let localStaff = [];
  //   let results;
  //   if(searchType === 'staff')
  //   {
  //     url = https://test.kisdigital.co.uk/api/v1/singl/search/staff?api_token=9e12909f-7aca-4513-aae7-28fd193cd3bb
  //     await axios.post(url, {q: searchQuery}).then((response) => {
  //     kisStaff = response.data.data;
  //     url = /search/clients/${searchQuery}
  //     axios.post(url).then((response) => {
  //       if(response.data.result.original.result.length > 0)
  //       {
  //         localStaff = response.data.result.original.result;
  //         localStaff.map((item) => {
  //           return kisStaff.unshift(item);
  //         })
  //         let filtered = kisStaff.filter( (ele, ind) => ind === kisStaff.findIndex( elem => elem.name === ele.name && elem.date_of_birth === ele.date_of_birth))
  //         setSearchResults(filtered)
  //       }else
  //       {
  //         setSearchResults(kisStaff);
  //       }
  //     })
  //     });
  //   }

  //   if(searchType === 'organisation')
  //   {
  //     let localOrgs = ''
  //     let kisOrgs = ''
  //     url = https://test.kisdigital.co.uk/api/v1/singl/org-search?api_token=9e12909f-7aca-4513-aae7-28fd193cd3bb
  //     await axios.post(url, {q: searchQuery}).then((response) => {
  //     kisOrgs = response.data.data;
  //     url = /search/organisations/${searchQuery}
  //     axios.post(url).then((response) => {
  //       if(response.data.result.original.result.length > 0)
  //       {
  //         localOrgs = response.data.result.original.result;
  //         localOrgs.map((item) => {
  //           return kisOrgs.unshift(item);
  //         })
  //         let filtered = kisOrgs.filter( (ele, ind) => ind === kisOrgs.findIndex( elem => elem.name === ele.name && elem.unique_reference === ele.unique_reference))
  //         setSearchResults(filtered)
  //       }else
  //       {
  //         setSearchResults(kisStaff);
  //       }
  //     })
  //     })
  //   }
  // }


  useEffect(() => {
    setSearchResults([])
    if (searchQuery.length >= 3) {
      runSearch()
    }
  }, [searchQuery])

  const viewClient = async (data) => {
    try {
    setShowLoader(true);
      
    if(searchType === 'staff')
    {
      const response = await axios.post(`clients/exists`, {data: data})
      setShowLoader(false);
      navigate(`/clients/${response.data.result}`);
    }

    if(searchType === 'organisation'){
      const response = await axios.post(`organisations/exists`, {data: data}).then((response) => {
      setShowLoader(false);
        navigate(`/organisations/${response.data.result}`);
      })
    }
    setSearchQuery('')
    setSearchResults([])
    setSearchType('staff')
    } catch (error) {

    }

   
  }

  return (
    <header
    className="w-full px-4 py-3 flex justify-between items-center"
    style={headerStyles}
  >
      <div className="flex items-center relative">
        {!isSearchExpanded ? (
          <span
            onClick={() => setIsSearchExpanded(true)}
            className="cursor-pointer text-2xl text-gray-600 hover:text-gray-800 transition"
          >
            <FontAwesomeIcon icon={faSearch} />
          </span>
        ) : (
          <div className="flex items-center space-x-2">
            <div
              className={`flex items-center border rounded-full px-4 bg-white transition-all duration-300 ${
                isSearchExpanded ? "w-64 opacity-100" : "w-0 opacity-0"
              }`}
            >
              <input
                type="text"
                id="main_search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full h-9 pl-2 text-sm text-slate-800 focus:outline-none"
                placeholder="Search"
              />
              <span
                className="cursor-pointer ml-2"
                onClick={() => {
                  setSearchQuery("");
                  setSearchResults([]);
                  setIsSearchExpanded(false)
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </span>
            </div>

            <span
              className={`flex justify-center items-center rounded-md h-9 w-16 ml-2 cursor-pointer transition ${
                searchType === "staff"
                  ? "bg-violet-400 text-white hover:bg-violet-300"
                  : "bg-slate-100 text-slate-500 hover:bg-slate-200"
              }`}
              onClick={() => toggleSearchType("staff")}
            >
              Staff
            </span>
            <span
              className={`flex justify-center items-center rounded-md h-9 w-32 mx-2 cursor-pointer transition ${
                searchType === "organisation"
                  ? "bg-violet-400 text-white hover:bg-violet-300"
                  : "bg-slate-100 text-slate-500 hover:bg-slate-200"
              }`}
              onClick={() => toggleSearchType("organisation")}
            >
              Organisation
            </span>
          </div>
        )}

      
        {searchResults && searchResults.length > 0 ? (
          <div
            className="absolute -left-4 bg-white p-3 pb-1 rounded-lg shadow-md min-w-[500px] z-10 overflow-y-scroll"
            style={{ top: 'calc(100% + 30px)' }}
          >
            {searchType === 'staff' && (<ul className='max-h-[500px]'>
              {searchResults.map((result, index) => (
                <li
                  key={result.id || index} 
                  className={`flex flex-col p-3 rounded mb-2 hover:bg-blue-200/80 cursor-pointer ${result.id ? 'bg-blue-100 hover:bg-blue-200/80' : 'bg-violet-100 hover:bg-violet-200/80' }`}
                  onClick={() => viewClient(result)}
                >
                  <span className="font-bold text-lg">{result?.name ?? result[0].name}</span>
                  <div className="flex">
                      <span className="font-semibold mr-1">Date of Birth:</span>
                      {moment(result?.date_of_birth ?? result[0].date_of_birth).format('DD/MM/YYYY')}
                    </div>
                    <div className="flex">
                      <span className="font-semibold mr-1">Organisation:</span>
                      {result?.school_name ?? result[0].school_name}
                    </div>
                </li>
              ))}
            </ul>)}

            {searchType === 'organisation' && (<ul className='max-h-[500px]'>
              {searchResults.map((result, index) => (
                <li
                  key={result.id || index} 
                  className={`flex flex-col p-3 bg-blue-100 rounded mb-2 hover:bg-blue-200/80 cursor-pointer ${result.id ? 'bg-blue-100 hover:bg-blue-200/80' : 'bg-violet-100 hover:bg-violet-200/80' }`}
                  onClick={() => viewClient(result)}
                >
                  <span className="font-bold text-lg">{result.name}</span>
                  <div className="flex">
                      <span className="font-semibold mr-1">Type:</span>
                      {result?.type === 1 ? 'Academy' : (result.type === 2 ? 'Trust' : 'Company')}
                    </div>
                    <div className="flex">
                      <span className="font-semibold mr-1">Reference:</span>
                      {result?.unique_reference}
                    </div>
                </li>
              ))}
            </ul>)}

          </div>
        ) : searchQuery.length > 0 ? (
          <div
            className="absolute -left-4 bg-white p-3 pb-1 rounded-lg shadow-md min-w-[500px] z-10"
            style={{ top: 'calc(100% + 30px)' }}
          >
            <ul>
              <li className="flex flex-col p-3 bg-light-purple rounded mb-2 hover:bg-[#E5D5EF] cursor-pointer">
                <span className="font-bold text-lg">No results found</span>
              </li>
            </ul>
          </div>
        ) : null}
      </div>
  </header>
  )
}

export default Header
