import { NavLink } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'

import {
  faGears,
  faHouse,
  faScrewdriverWrench,
  faEnvelopesBulk,
  faUserGroup,
  faBuilding,
  faChartColumn,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import useAuth from '../../hooks/useAuth'

const navClasses =
  'w-full text-white p-1 flex relative rounded-md items-center'

function Nav() {
  const { can } = useAuth()

  return (
    <ul>
        <li className="mb-2 text-white">
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive
                ? 'text-nav-dark ' + navClasses
                : navClasses + ' hover:text-nav-dark'
            }
          >
            <div className="flex items-center w-10" data-tooltip-id="home" data-tooltip-content="Home" data-tooltip-place="right">
              <FontAwesomeIcon icon={faHouse} className="text-xl" />
            </div>
            <div>Home</div>
          </NavLink>
        </li>
        <li className="mb-2 text-white">
          <NavLink
            to="/enquiries"
            className={({ isActive }) =>
              isActive
                ? 'text-nav-dark ' + navClasses
                : navClasses + ' hover:text-nav-dark'
            }
          >
            <div className="flex items-center w-10">
              <FontAwesomeIcon icon={faEnvelopesBulk} className="text-xl" />
            </div>
            <div>Enquiries</div>
          </NavLink>
        </li>

        {can('view clients') && (<li className="mb-2 text-white">
          <NavLink
            to="/clients"
            className={({ isActive }) =>
              isActive
                ? 'text-nav-dark ' + navClasses
                : navClasses + ' hover:text-nav-dark'
            }
          >
            <div className="flex items-center w-10" >
              <FontAwesomeIcon icon={faUserGroup} className="text-xl" />
            </div>
            <div>Clients</div>
          </NavLink>
        </li>)}
        
        <li className="mb-2">
          <NavLink
            to="/organisations"
            className={({ isActive }) =>
              isActive
                ? 'text-nav-dark ' + navClasses
                : navClasses + ' hover:text-nav-dark'
            }
          >
            <div className="flex items-center w-10" >
              <FontAwesomeIcon icon={faBuilding} className="text-xl" />
            </div>
            <div>Organisations</div>
          </NavLink>
        </li>

      {/* {can('access accounts') ? (
        <li className="mb-2">
          <NavLink
            to="/accounts"
            className={({ isActive }) =>
              isActive
                ? 'bg-blue-500 ' + navClasses
                : navClasses + ' hover:bg-slate-700'
            }
          >
            <span className="flex justify-start w-10">
              <FontAwesomeIcon icon={faSterlingSign} className="mr-4 text-xl" />
            </span>
            Accounts
          </NavLink>
        </li>
      ) : null} */}

      {can('access reports') ? (
        <li className="mb-2">
          <NavLink
            to="/reports"
            className={({ isActive }) =>
            isActive
                ? 'text-nav-dark ' + navClasses
                : navClasses + ' hover:text-nav-dark'
            }
          >
            <span className="flex justify-start w-10">
              <FontAwesomeIcon icon={faChartColumn} className="mr-4 text-xl" />
            </span>
            Reports
          </NavLink>
        </li>
      ) : null}

      {can('access settings') ? (
        <li className="mb-2">
          <NavLink
            to="/settings"
            className={({ isActive }) =>
              isActive
                ? 'text-nav-dark ' + navClasses
                : navClasses + ' hover:text-nav-dark'
            }
          >
            <div className="flex items-center w-10" >
              <FontAwesomeIcon icon={faGears} className="text-xl" />
            </div>
            <div>Settings</div>
          </NavLink>
        </li>
      ) : null}

      {can('access tools') ? (
        <li className="mb-2">
          <NavLink
            to="/tools"
            className={({ isActive }) =>
              isActive
                ? 'text-nav-dark ' + navClasses
                : navClasses + ' hover:text-nav-dark'
            }
          >
            <div className="flex items-center w-10" >
              <FontAwesomeIcon
                icon={faScrewdriverWrench}
                className="text-xl"
              />
            </div>
            <div>Tools</div>
          </NavLink>
        </li>
      ) : null} 
    </ul>
  )
}

export default Nav
