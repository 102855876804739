/* eslint-disable */
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import Modal from '../../components/Modal'
import PPQTitle from '../../components/PPQTitle'
import RequestNotes from './Partials/RequestNotes'
import RequestInfo from './Partials/RequestInfo'
import moment from 'moment'

function PPQReview() {
  const axios = useAxiosPrivate()
  const { id, taskId } = useParams()

  const [showModal, setShowModal] = useState(false)
  const [submissionDate, setSubmissionDate] = useState(null)
  const [taskInfo, setTaskInfo] = useState([])
  const [requestInfo, setRequestInfo] = useState([])
  const [staffDetails, setStaffDetails] = useState([])
  const [claimReference, setClaimReference] = useState('')
  const [notes, setNotes] = useState([])

  useEffect(() => {
    if(taskId) {
      getTaskInfo()
      getNotes()
    }
  }, [])

  const getTaskInfo = async () => {
    const response = await axios.get(`tasks/get-task-info/${taskId}`)
    let data = response.data.result[0]
    let task_info = JSON.parse(data.task_info)
    let form_data = task_info.request_information.form_data

    let request_info = []
    for (const [key, value] of Object.entries(form_data)) {
      request_info.push({
        title: value.title,
        value: value.value
      })
    }

    setTaskInfo(task_info)
    setRequestInfo(request_info)
    setStaffDetails(task_info.request_information.staff_details)
    setClaimReference(task_info.request_information.claim_reference)
    setSubmissionDate(moment('2024-11-12T16:04:08.062228Z', 'YYYY-MM-DDTHH:mm:ss.SSSSSS').format('DD/MM/YYYY'))
  }

  const getNotes = async () => {
    const response = await axios.get(`tasks/get-request-notes/${id}`)
    let data = response.data.result
    setNotes(data);
  }

  const ModalBody = () => {
    if (addNote) {
      return <AddNoteElement noteType={noteType} />
    }

    if (editNote) {
      return <DeleteNoteElement />
    }
  }

  const AddNoteElement = ({ noteType }) => {
    const createNote = async (e) => {
      e.preventDefault();
      setShowLoader(true);
      try {
        const response = await axios.post(`/organisations/${id}/notes/create`, {
          body: addNoteBody.body ?? "Test String",
          type: noteType === 'general' ? 1 : 2,
        });
  
        if (response.data.error === false) {
          createInfo('success', `Note Added`);
          setAddNoteBody({});

          const newNote = response.data.result;
          if (noteType === 'general') {
            setGeneralNotes(prevNotes => [...prevNotes, newNote]);
          } else if (noteType === 'nurse') {
            setNurseNotes(prevNotes => [...prevNotes, newNote]);
          }
        }
      } catch (error) {
        console.log(error.message);
        createInfo('error', `Failed to create note`);
      }
      resetModal();
      setShowLoader(false);
    };

    return (
      <form onSubmit={createNote}>
        <label className='text-xs' htmlFor="">Description <span className='text-red-500'>*</span></label>
        <textarea
          value={addNoteBody.body}
          onChange={(e )=> addNoteBody.body = e.target.value}
          required
          className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md focus:ring-violet-500 focus:border-violet-500"
        />
        <div className="text-xs mt-2 flex justify-end">
          <span className="text-red-500">*</span> denotes required fields.
        </div>
        <div className="flex mt-2 w-full">
          <button type="submit" className="btn mt-4 mr-4 w-1/2">Add Note</button>
          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const DeleteNoteElement = () => {
    const destroyNote = async (e) => {
      e.preventDefault();
      setShowLoader(true);
      try {
        const response = await axios.get(`/organisations/notes/delete/${note.id}`);
        if (response.status === 200) {
          createInfo('success', 'Note deleted successfully');
          if (note.note_type_id === 1) {
            setGeneralNotes(currentNotes => currentNotes.filter(n => n.id !== note.id));
          } else if (note.note_type_id === 2) { 
            setNurseNotes(currentNotes => currentNotes.filter(n => n.id !== note.id));
          }
        }
      } catch (error) {
        console.error(error.message);
        createInfo('error', 'Failed to delete note');
      }
      setShowLoader(false);
      resetModal();
    };

    return (
      <form onSubmit={destroyNote}>
        <div className="mb-3">
         Are you sure you want to delete <span className='font-medium'>"{note.body}"</span>?
        </div>
        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Delete note</button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const resetModal = () => {
    setShowModal(false)
  }

  return (
    <section>
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}

      <div className='text-gray-600 max-w-[74rem] 2xl:max-w-none'>
        <div className='w-full'>
          <PPQTitle
            submissionDate={submissionDate}
            claimReference={claimReference}
            backButton
          />
        </div>
        <div className='flex w-full space-x-4'>
          <div className="w-2/3">
            <RequestInfo requestInfo={requestInfo} />
          </div>
          <div className='w-1/3'>
            <div className="w-full bg-white rounded-xl shadow-md p-4 mb-4">
              <h2 className="font-bold text-xl mb-2">Actions</h2>
              <button
                className="bg-nav hover:bg-nav-dark text-white font-bold py-2 px-4 rounded mr-2"
                onClick={() => setShowModal(true)}
              >
                Add Note
              </button>
              <button
                className="bg-nav hover:bg-nav-dark text-white font-bold py-2 px-4 rounded"
                onClick={() => setShowModal(true)}
              >
                Complete Review
              </button>
            </div>
            <RequestNotes notes={notes} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default PPQReview
