/* eslint-disable */
import { useState, useEffect, Fragment, useRef, createRef } from 'react'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import parse from 'html-react-parser'
import MainTitle from '../../../components/MainTitle'
import {
  Link,
  useParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import useAuth from '../../../hooks/useAuth'
import ClientNav from '../../../components/Admin/ClientNav'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Menu, Transition, Listbox } from '@headlessui/react'
import moment from 'moment'
import {
  faEnvelope,
  faComment,
  faTrash,
  faMagnifyingGlass,
  faChevronCircleDown,
  faChevronDown,
  faCheck,
  faPaperPlane,
  faPaperclip,
  faPhone,
} from '@fortawesome/free-solid-svg-icons'

const publishingOptions = [
  // { id: 1, title: 'SMS', current: true },
  { id: 2, title: 'Email', current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Comms = () => {
  const { id } = useParams()
  const { auth, setShowLoader, can, createInfo } = useAuth()
  const location = useLocation()
  const commsRef = useRef([])
  const [clientData, setClientData] = useState({
    name: '',
    reference: 'WBA-1000',
  })
  const [comms, setComms] = useState([])
  const [messageTypes, setMessageTypes] = useState([])
  const [threads, setThreads] = useState([])
  const [threadIndex, setThreadIndex] = useState('');
  const [showComms, setShowComms] = useState(false)
  const [addMessage, setAddMessage] = useState(false)
  const [newMessage, setNewMessage] = useState({})
  const [addMessageType, setAddMessageType] = useState(2)
  const [currentThread, setCurrentThread] = useState([])
  const [messageAttachments, setMessageAttachments] = useState([])
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedThread, setSelectedThread] = useState(0)
  const navigate = useNavigate()
  const axios = useAxiosPrivate()
  const [selected, setSelected] = useState(publishingOptions[0])

  const scrollToBottom = () => {
    commsRef.current[commsRef.current.length - 1].current.scrollIntoView({
      behavior: 'smooth',
    })
  }

  const getClientById = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/clients/${id}`)
      await setClientData(response.data.result)
      await setThreads(response.data.result.threads)
      setShowLoader(false)
    } catch (error) {}
  }

  const deleteAttachment = (attachment) =>
  {
    setMessageAttachments((messageAttachments) =>
    messageAttachments.filter((item) => item !== attachment)
  );

  }

  useEffect(() => {
   getClientById()
  }, [id])

  useEffect(() => {
    commsRef.current = comms.map((message, index) => {
      return commsRef.current[index] ?? createRef()
    })
  }, [comms])

  useEffect(() => {
    if (searchParams.get('thread')) {
      setSelectedThread(searchParams.get('thread'))
      toggleThreadComms(searchParams.get('thread'))
    }
  }, [threads])

  const handleSearch = (handle) => {
    setIndexOfFirstRecord(0)
    setIndexOfLastRecord(10)
    setCurrentPage(0)
    setCurrentRecords(filteredRecords.slice(0, 10))

    let searchValue = handle.target.value
    let searchString = searchValue.trim().toLowerCase()
    if (searchString.length > 0) {
      let records = comms.filter((e) => {
        return (
          // e.external_reference.toLowerCase().match(searchString) ||
          // e.company_relationship.title.toLowerCase().match(searchString) ||
          e.service.title.toLowerCase().match(searchString)
        )
      })
      setFilteredRecords(records)
      setCurrentRecords(records.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(records.length / recordsPerPage))
    } else {
      setFilteredRecords(comms)
      setCurrentRecords(comms.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(comms.length / recordsPerPage))
    }
  }

  const getThreads = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/clients/${id}/threads`)
      if (response.data.result) {
        setThreads(response.data.result)
        setShowLoader(false)
      }
    } catch (error) {
      setShowLoader(false)
    }
    // setShowComms(true)

  }

  const toggleThreadComms = async (thread) => {
    setSelectedThread(thread);
    await setComms(threads[thread].comms)
    setCurrentThread(threads[thread])
    setShowComms(true)
    window.setTimeout(() => {
      scrollToBottom()
    }, 3050)
  }

  const sendMessage = async (e) => {
    e.preventDefault()
    setShowLoader(true)
    const fd = new FormData()
    for (let i = 0; i < messageAttachments.length; i++) {
      fd.append(
        'attachments-' + i,
        messageAttachments[i],
        messageAttachments[i].name
      )
    }
    fd.append(
      'data',
      JSON.stringify({
        delivery: 'outbound',
        body: newMessage.body,
        recipient_id: clientData.id,
        sender_id: auth.user.id,
        thread_id: clientData.threads[selectedThread].id,
        status_id: 1,
        type_id: addMessageType,
      })
    )
    try {
      await axios.post(`/comms/new`, fd).then((response) => {
          createInfo('success', `Message is sending`)
          setComms([...comms, response.data.result]);
          setAddMessage(false)
          setNewMessage({ body: '' }) // Reset the text area to blank
          setMessageAttachments([])
      })
    } catch (error) {
      createInfo('error', `Failed to create message`)
    }
    newMessage.body = ''
    setShowLoader(false)
  }

  return (
    <div>
      <section>
        <section className="relative w-full bg-white rounded-xl shadow-md py-4 px-4 mb-8">
          <div className="w-1/2 p-4">
            <h4>Threads</h4>
            <select
              defaultValue={selectedThread ?? 0}
              className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              required
              onChange={(e) => toggleThreadComms(e.target.value)}
            >
              <option disabled value={0}>
                Select a Thread
              </option>
              {threads?.map((thread, index) => {
                  return (
                    <option
                    key={thread.id}
                    value={index}
                    selected={selectedThread === thread.id}
                  >
                    {thread.subject}
                  </option>
                  )
               
              })}
            </select>
          </div>
          {showComms ? (
            <section className="d-flex row justify-content-between px-3 align-items-center">
              <aside className="d-flex align-items-center text-right border">
                <div className="rounded overflow-hidden shadow-md">
                  <div className="p-4 text-md flex justify-between">
                    <div className="p-4">
                      <h4 className="text-lg">Comms</h4>
                    </div>
                    <div className="relative mt-1 hidden">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <FontAwesomeIcon
                          icon={faMagnifyingGlass}
                          onClick={handleSearch}
                        />
                      </div>
                      <input
                        type="text"
                        id="table-search"
                        onChange={handleSearch}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Search Comms"
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="px-6 py-4 comms-container overflow-y-scroll w-full max-h-96 bg-slate-100 flex flex-col">
                    {comms?.map((message, index) => {
                      return (
                        <div
                          ref={commsRef.current[index]}
                          className={`comms-history p-4 w-3/5 relative block ${
                            message.delivery === 'inbound'
                              ? 'mr-auto'
                              : 'ml-auto'
                          }`}
                        >
                          <div
                            className={`message-time ${
                              message.delivery === 'inbound'
                                ? 'text-left'
                                : 'text-right'
                            }`}
                          >
                            <span className="text-xs font-medium">
                              {moment(message.created_at).format(
                                'DD/MM/YYYY HH:mm'
                              )}
                              <span> By {message.sender.name}</span>
                              {message.delivery == 'outbound' && (
                                <>
                                  {' - '}
                                  <span className="font-bold">
                                    Status: {message.status}
                                  </span>
                                </>
                              )}
                            </span>
                          </div>
                          <div
                            className={`flex items-center ${
                              message.delivery === 'inbound'
                                ? 'flex-row-reverse -ml-6'
                                : '-mr-6'
                            }`}
                          >
                            <div
                              className={`message-bubble --${
                                message.delivery === 'outbound'
                                  ? message.status
                                  : ''
                              } py-3 px-4 rounded w-full ${
                                message.delivery === 'inbound'
                                  ? 'text-left bg-blue-300'
                                  : 'text-right bg-pink-300'
                              }`}
                            >
                              <p>{parse(message.body)}</p>
                            </div>
                            <div className="message-type">
                              <span
                                className={`flex ${
                                  message.delivery === 'inbound'
                                    ? 'text-left mr-2'
                                    : 'text-right ml-2'
                                }`}
                              >
                                {message.type_id == 1 ? (
                                  <FontAwesomeIcon
                                    icon={faEnvelope}
                                    className="text-slate-400"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    icon={faComment}
                                    className="text-slate-400"
                                  />
                                )}
                              </span>
                            </div>
                          </div>
                          <div
                            className={`w-full message-attachments ${
                              message.delivery === 'inbound'
                                ? 'text-left'
                                : 'text-right'
                            }`}
                          >
                            {message.attachments.length > 0 && (
                              <div>
                                <Menu
                                  as="div"
                                  className="relative inline-block text-left z-10"
                                >
                                  <div>
                                    <Menu.Button className="flex items-center rounded-md cursor-pointer">
                                      <span className="font-semibold text-sm text-left flex">
                                        <span className="mr-2">
                                          Attachments
                                        </span>
                                        <FontAwesomeIcon
                                          icon={faChevronCircleDown}
                                        />
                                      </span>
                                    </Menu.Button>
                                  </div>
                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items
                                      className={`absolute ${
                                        message.delivery === 'inbound'
                                          ? 'left-0'
                                          : 'right-0'
                                      } mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none`}
                                    >
                                      <div className="p-2">
                                        {message.attachments.map(
                                          (attachment) => {
                                            return (
                                              <Menu.Item>
                                                <a
                                                  href={attachment.url}
                                                  target="_blank"
                                                >
                                                  <button
                                                    className={`group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                  >
                                                    {attachment.name}
                                                  </button>
                                                </a>
                                              </Menu.Item>
                                            )
                                          }
                                        )}
                                      </div>
                                    </Menu.Items>
                                  </Transition>
                                </Menu>
                              </div>
                            )}
                          </div>
                        </div>
                      )
                    })}
                    {comms.length <= 0 && (
                      <div className="text-center py-5 px-5">
                        No Comms Found
                      </div>
                    )}
                  </div>
                  {comms.length > 0 && (
                    <form
                      className=" bg-slate-100 px-4 pt-4 pb-6"
                      onSubmit={sendMessage}
                    >
                      <div className="flex items-start border-2 border-gray-300 bg-white rounded-lg shadow-md">
                        <Listbox value={selected} onChange={setSelected}>
                          {({ open }) => (
                            <>
                              <Listbox.Label className="sr-only">
                                Change comm method
                              </Listbox.Label>
                              <div className="">
                                <div className="inline-flex">
                                  <div className="h-16 inline-flex rounded-l-lg shadow-sm items-center bg-slate-800 p-2 hover:bg-slate-600  focus:outline-none focus:ring-2 focus:ring-slate-700 focus:ring-offset-2 focus:ring-offset-gray-50">
                                    <div className="inline-flex items-center px-3 text-white text-sm">
                                      <p className=" font-semibold">
                                        {selected.title}
                                      </p>
                                    </div>

                                    {/* <span className="sr-only">
                                      Change comm method
                                    </span>
                                    <FontAwesomeIcon
                                      icon={faChevronDown}
                                      className="text-white"
                                    /> */}
                                  </div>
                                </div>

                                <Transition
                                  show={open}
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Listbox.Options className="absolute left-4 mt-2 w-20 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                                    {/* {publishingOptions.map((option) => (
                                      <Listbox.Option
                                        onClick={() =>
                                          setAddMessageType( option.id ?? 1)
                                        }
                                        key={option.id}
                                        className={({ active }) =>
                                          classNames(
                                            active
                                              ? 'bg-slate-800 text-white'
                                              : 'text-gray-900',
                                            'cursor-default select-none p-2 text-xs'
                                          )
                                        }
                                        value={option}
                                      >
                                        {({ selected, active }) => (
                                          <div className="flex flex-col">
                                            <div className="flex justify-between">
                                              <p
                                                className={
                                                  selected
                                                    ? 'font-semibold'
                                                    : 'font-normal'
                                                }
                                              >
                                                <span>
                                                  {' '}
                                                  {option.title === '1' ? (
                                                    <FontAwesomeIcon
                                                      icon={faPhone}
                                                      className="text-slate-600"
                                                    />
                                                  ) : (
                                                    <FontAwesomeIcon
                                                      icon={faEnvelope}
                                                      className="text-slate-600"
                                                    />
                                                  )}
                                                </span>
                                                <span className="pl-1">
                                                  {option.title}
                                                </span>
                                              </p>
                                              {selected ? (
                                                <span
                                                  className={
                                                    active
                                                      ? 'text-white'
                                                      : 'text-slate-800'
                                                  }
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faCheck}
                                                    className="h-3 w-3"
                                                  />
                                                </span>
                                              ) : null}
                                            </div>
                                          </div>
                                        )}
                                      </Listbox.Option>
                                    ))} */}
                                     <Listbox.Option
                                        key={2}
                                        className={({ active }) =>
                                          classNames(
                                            active
                                              ? 'bg-slate-800 text-white'
                                              : 'text-gray-900',
                                            'cursor-default select-none p-2 text-xs'
                                          )
                                        }
                                        value={'Email'}
                                      >
                                      </Listbox.Option>
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </>
                          )}
                        </Listbox>

                        <div className="w-full flex border-l-2">
                          <textarea
                            type="text"
                            placeholder="Write your message..."
                            required
                            name="body"
                            id="body"
                            value={newMessage.body}
                            onChange={(e) =>
                              setNewMessage({
                                ...newMessage,
                                body: e.target.value,
                              })
                            }
                            className="px-4 py-2 block w-full resize-none"
                          />
                          <div className="flex justify-end items-center pr-2 space-x-1 bg-white rounded-r-lg">
                            {addMessageType == 2 ? (
                              <div className="" title="Add attachment">
                                <label
                                  className="flex items-center hover:cursor-pointer rounded-lg bg-slate-400 text-white px-4 font-medium py-3"
                                  htmlFor="upload_file"
                                >
                                  <FontAwesomeIcon icon={faPaperclip} />
                                </label>
                                <input
                                  className="hidden w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                  id="upload_file"
                                  onChange={async (e) => {
                                    const files = Array.prototype.slice.call(
                                      e.target.files
                                    )
                                    const attachments = [...messageAttachments]
                                    files.some((file) => {
                                      attachments.push(file)
                                    })
                                    setMessageAttachments(attachments)
                                  }}
                                  type="file"
                                  accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.csv,text/csv,.pdf,application/pdf,image/jpeg,image/png,image/tiff"
                                />
                              </div>
                            ) : (
                              ''
                            )}
                           
                            <button
                              type="submit"
                              title="Send"
                              className="bg-blue-500 hover:bg-blue-700 rounded-lg px-4 font-medium py-2 text-white cursor-pointer"
                            >
                              <FontAwesomeIcon icon={faPaperPlane} />
                            </button>
                          </div>
                        </div>
                      </div>
                      <ul className="flex flex-wrap flex-row-reverse">
                              {messageAttachments.map((attachment) => {
                                return (
                                  <li key={attachment.name} className="flex py-3 mr-2">
                                    <span className="pill small">
                                      {attachment.name}
                                    </span>
                                    <span
                                    title="Remove Attachment"
                                    className="small pill-button"
                                    onClick={() => deleteAttachment(attachment)}
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                  </span> 
                                  </li>
                                )
                              })}
                            </ul>
                    </form>
                  )}
                </div>
              </aside>
            </section>
          ) : (
            <div className="text-center w-full text-2xl my-4">
              <span>Please select a Thread from the dropdown to view comms</span>
            </div>
          )}
        </section>
      </section>
    </div>
  )
}

export default Comms
