import React, { useRef, useState, useEffect } from "react"
import useAuth from "../hooks/useAuth"
import { Link, useNavigate, useLocation } from "react-router-dom"

import axios from "../api/axios"

const TwoFa = () => {
  const { setAuth } = useAuth()

  const navigate = useNavigate()
  const location = useLocation()

  // const from = location.state?.from?.pathname || "/"
  const [disableConfirm, setDisableConfirm] = useState(true)
  const [loginAttempts, setLoginAttempts] = useState(1)
  const [maxAttempts] = useState(5)

  const codeRef = useRef()
  const errRef = useRef()

  const [code, setCode] = useState("")

  const [errMsg, setErrMsg] = useState("")

  const handlInputChange = (e) => {
    if (code.length >= 1) {
      setDisableConfirm(false)
      return
    }
    setDisableConfirm(true)
    return
  }

  useEffect(() => {
    codeRef.current.focus()
    if (!localStorage.getItem("login")) {
      navigate("/login")
    }
  }, [])

  useEffect(() => {
    setErrMsg("")
  }, [code])

  const handleSubmit = async (e) => {
    e.preventDefault()
    let data = {
      pin: code,
      id: location.state,
      attempts: loginAttempts,
    }
    try {
      const response = await axios.post("/2fa/confirm", data)
      if (response.data.error === false) {
        const token = response.data.result.token
        const user = response.data.result.user
        setAuth({ user, token })
        localStorage.setItem("token", token)
        setCode("")
        navigate("/", { replace: true })
      }

      if (response.data.error === true) {
        setLoginAttempts(loginAttempts + 1)
        if (loginAttempts < 5 && loginAttempts > 1) {
          setErrMsg("Failed attempt " + loginAttempts + " of " + maxAttempts)
        } else {
          setErrMsg(response.data.message)
          localStorage.removeItem("login")
        }
      }
    } catch (error) {
      if (!error?.response) {
        setErrMsg("No Server Response")
      } else if (error.response?.status === 400) {
        setErrMsg("Email or Password Missing.")
      } else if (error.response?.status === 401) {
        setErrMsg("Incorrect Email or Password entered.")
      } else {
        setErrMsg("Error Occured. Could not log you in.")
      }
      errRef.current.focus()
    }
  }

  return (
    <div className="p-16">
      <h1 className="text-2xl text-gray-800 font-bold text-center mb-6">
        Enter your Two FA code
      </h1>
      <p
        ref={errRef}
        className={errMsg ? "errmsg" : "offscreen"}
        aria-live="assertive"
      >
        {errMsg}
      </p>
      <form onSubmit={handleSubmit}>
        <div className="input-wrap">
          <input
            type="text"
            ref={codeRef}
            id="code"
            onChange={(e) => {
              setCode(e.target.value)
            }}
            required
            placeholder="0 0 0 0 0 0"
            className="field text-center"
          />
        </div>

        <div className="input-wrap">
          <input type="submit" value="Confirm" />
        </div>
      </form>
      {loginAttempts >= 6 && (
        <div
          className="flex justify-center
        "
        >
          <Link
            to="/login"
            // onClick={() => window.location.reload(false)}
            className="hover:text-blue-800 py-4"
          >
            Return to login page
          </Link>
        </div>
      )}
    </div>
  )
}

export default TwoFa
