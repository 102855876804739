import { Link } from 'react-router-dom'
function UserNav({ active = 'profile', userId }) {
  return (
    <div className="bg-white rounded-md shadow-md py-3 px-4 mb-8">
      <ul className="flex flex-col md:flex-row flex-wrap list-none px-0">
        <Link to={`/users/${userId}`}>
          <li className={`tab-btn ${active === 'profile' ? 'active' : ''}`}>
            Profile
          </li>
        </Link>
        <Link to={`/users/${userId}/account-settings`}>
          <li
            className={`tab-btn ${
              active === 'account-settings' ? 'active' : ''
            }`}
          >
            Account Settings
          </li>
        </Link>

        <Link to={`/users/${userId}/availability`}>
          <li
            className={`tab-btn ${
              active === 'availability' ? 'active' : ''
            }`}
          >
            Availability
          </li>
        </Link>

      </ul>
    </div>
  )
}

export default UserNav
