import React from 'react'

function CaseCards({claim, isKis=false, setTableView, setCurrentContacts, setCurrentClaims}) {

  let classNames = isKis ? 'hover:border-sky-400 hover:shadow-lg': 'hover:border-violet-400 hover:shadow-lg';

  if(isKis) {
    classNames += ' ring ring-blue-400 shadow-xl';
  } else {
    classNames += ' ring ring-violet-400 shadow-xl';
  }

  return (
    <div>
      <div className={"block max-w-sm p-4 bg-white border mx-3 border-gray-200 rounded-lg shadow-md text-wrap " + classNames}>
          <span className="flex flex-row mb-2 text-sm text-gray-900 font-bold space-x-1">
            <p>Title:</p>
            <p className="font-medium">{claim.case_title}</p>
          </span>
          <span className="flex flex-row mb-2 text-sm text-gray-900 font-bold space-x-1">
            <p>Reference:</p>
            <p className="font-medium">{claim.case_reference}</p>
          </span>
          <span className="flex flex-row mb-2 text-sm text-gray-900 font-bold space-x-1">
            <p>Status:</p>
            <p className="font-medium">{claim.case_status}</p>
          </span>
          <span className="flex flex-row mb-2 text-sm text-gray-900 font-bold space-x-1">
            <p>Created By:</p>
            <p className="font-medium">{claim.created_by_user}</p>
          </span>
          <div className='flex'>
            {claim?.contact.length > 0 ? (
              <button 
                onClick={() => {
                  setTableView('Contacts');
                  setCurrentContacts(claim.contact)}} className="px-4 py-2 text-sm rounded-lg bg-violet-400 font-medium text-white hover:bg-violet-500 mr-2">View Contacts</button>
                ) : (
              <p className='px-4 py-2 text-sm rounded-lg bg-gray-200 font-medium text-black mr-2 select-none'>No Contacts</p>
            )}
            {claim?.claims.length > 0 ? (
              <button 
                onClick={() => {
                  setTableView('Claims')
                  setCurrentClaims(claim.claims)}} className="px-4 py-2 text-sm rounded-lg bg-violet-400 font-medium text-white hover:bg-violet-500 mr-2">View Claims</button>
              ) : (
                <p className='px-4 py-2 text-sm rounded-lg bg-gray-200 font-medium text-black mr-2 select-none'>No Referrals</p>
              )}                
            
          </div>
      </div>
      
    </div>   
  )
}

export default CaseCards