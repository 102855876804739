import React from 'react'

function PolicyCards({policy, isKis=true}) {

  let classNames = isKis ? 'hover:border-sky-400 hover:shadow-lg': 'hover:border-violet-400 hover:shadow-lg';

  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  });

  if(isKis) {
    classNames += ' ring ring-blue-400 shadow-xl';
  } else {
    classNames += ' ring ring-violet-400 shadow-xl';
  }

  return (
      <div className={"block max-w-sm p-4 bg-white border mx-3 border-gray-200 rounded-lg shadow-md text-wrap " + classNames}>
          <span className="flex flex-row mb-2 text-sm text-gray-900 font-bold space-x-1">
            <p>Reference:</p>
            <p className="font-medium">{policy.reference}</p>
          </span>
          <span className="flex flex-row mb-2 text-sm text-gray-900 font-bold space-x-1">
            <p>Status:</p>
            <p className="font-medium">{policy.status}</p>
          </span>
          <span className="flex flex-row mb-2 text-sm text-gray-900 font-bold space-x-1">
            <p>Premium:</p>
            {policy.premium ? (
              <p className="font-medium">{formatter.format(policy.premium)}</p>
            ) : (
              <p className="font-medium">£0</p>
            )}
          </span>
          <span className="flex flex-row mb-2 text-sm text-gray-900 font-bold space-x-1">
            <p>Inception Date:</p>
            <p className="font-medium">{policy.inception}</p>
          </span>
          <span className="flex flex-row mb-2 text-sm text-gray-900 font-bold space-x-1">
            <p>Expiry Date:</p>
            <p className="font-medium">{policy.expire}</p>
          </span>
          
          
         
      </div>
  )
}

export default PolicyCards