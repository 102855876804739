import { React } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons"

function InfoBanner({messages, color="yellow", icon=false}) {
  return (
    <div className={"mb-2 border border-" + color + "-500 bg-" + color + "-100 p-4 rounded-lg"}>
    <div className="flex">
      <div className="flex-shrink-0">
        {icon && (<FontAwesomeIcon icon={faTriangleExclamation} className={"text-" + color + "-500 text-lg mr-2"} />)}
      </div>
      {messages && messages.length > 1 ? (
        <div className="ml-3">
        {messages?.map((item) => {
          return( 
          <p className={"text-md text-bold text-" + color + "-600"}>{item.value}</p>
        )
        })}
      </div>
      ) : (
        <p className={"text-md text-bold text-" + color + "-600"}>{messages}</p>
      )}
      
    </div>
  </div>
  )
}

export default InfoBanner