import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation, faClock } from '@fortawesome/free-solid-svg-icons';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import moment from 'moment';
import Modal from '../../../components/Modal';
import useAuth from '../../../hooks/useAuth';

const Tasks = ({ tasks, filter, refreshTasks }) => {
  const axios = useAxiosPrivate();
  const { setShowLoader, createInfo, can } = useAuth()
  const [allTasks, setAllTasks] = useState(tasks);
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [updateTask, setUpdateTask] = useState(false)
  const [updatedTask, setUpdatedTask] = useState({})

  const statusStyles = {
    due: 'text-green-700 bg-green-200',
    overdue: 'text-red-700 bg-red-200',
    important: 'text-amber-700 bg-amber-200',
    completed: 'text-blue-700 bg-blue-200',
  }

  const determineStatus = (task) => {
    const today = moment()
    const dueDate = moment(task.due_date)
  
    if (task.completed === 1) return 'completed'
    if (task.isImportant) return 'important'
    if (dueDate.isBefore(today, 'day')) return 'overdue'
    return 'due'
  }

  const filteredTasks = tasks
    .filter((task) => {
      const status = determineStatus(task);
      switch (filter) {
        case 'Due':
          return status === 'due' && task.completed !== 1
        case 'Overdue':
          return status === 'overdue' && task.completed !== 1
        case 'Important':
          return status === 'important' && task.completed !== 1
        case 'Done':
          return task.completed === 1
        default:
          return true
      }
    })
    .map((task) => ({ ...task, status: determineStatus(task) }));
  

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const handleToggleComplete = async (id) => {
    const taskToUpdate = allTasks.find(task => task.id === id);
    if (!taskToUpdate) return;
    const updatedCompletedStatus = !taskToUpdate.completed;
    try {
      const response = await axios.post(`/tasks/completed/${id}`, {
        completed: updatedCompletedStatus ? 1 : 0,
      });
      setAllTasks(prevTasks => prevTasks.map(task =>
        task.id === id ? { ...task, completed: updatedCompletedStatus } : task
      ));
      console.log('Update successful', response.data);
    } catch (error) {
      console.error('Failed to update task', error);
    }

    refreshTasks();
  };

  const updateTaskClicked = (task) => {
    setUpdateTask(true)
    setUpdatedTask(task);
    setModalTitle(`Update Task`)
    setShowModal(true)
  }

  const ModalBody = () => {
    if(updateTask) {
      return <UpdateTaskElement />
    }
  }

  const UpdateTaskElement = () => {
    const updatedSelectedTask = async (e) => {
      e.preventDefault();
      setShowLoader(true);
      try {
        const response = await axios.post(`/tasks/update`, {
          id: updatedTask.id,
          title: updatedTask.title,
          due_date: updatedTask.due_date,
          important: updatedTask.important ? 1 : 0, 
        });
  
        if (response.data.error === false) {
          createInfo('success', `Task Updated`);
          setUpdatedTask('');
          refreshTasks()
        }
      } catch (error) {
        console.log(error.message);
        createInfo('error', `Failed to update task`);
      }
      resetModal();
      setShowLoader(false);
    };
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setUpdatedTask((prevTask) => ({
        ...prevTask,
        [name]: value,
      }));
    };
  
    const handleCheckboxChange = (e) => {
      setUpdatedTask((prevTask) => ({
        ...prevTask,
        important: e.target.checked,
      }));
    };
  
    return (
      <form onSubmit={updatedSelectedTask}>
        <div className="mb-3">
          <label
            htmlFor="title"
            className="block font-medium text-gray-600 text-sm"
          >
            Title
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              required
              name="title"
              value={updatedTask.title || ''}
              onChange={handleInputChange}
              type="text"
              id="title"
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
  
        <div className="mb-3">
          <label
            htmlFor="due_date"
            className="block font-medium text-gray-600 text-sm"
          >
            Due Date
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              required
              name="due_date"
              type="date"
              value={updatedTask.due_date || ''}
              onChange={handleInputChange}
              id="due_date"
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
  
        <div className="mb-3">
          <label
            htmlFor="important"
            className="block mb-2 font-medium text-gray-600 text-sm"
          >
            Important
          </label>
          <input
            name="important"
            checked={updatedTask.important || false}
            onChange={handleCheckboxChange}
            type="checkbox"
            className="bg-blue-500 focus:accent-blue-500 h-4 w-4"
          />
        </div>
  
        <div className="flex mt-2 w-full space-x-4">
          <button type="submit" className="btn mt-4 mr-4 w-1/2">Update Task</button>
          <button type="button" className="btn red mt-4 w-1/2" onClick={resetModal}>Cancel</button>
        </div>
      </form>
    );
  };
  

  const resetModal = () => {
    setUpdateTask(false)
    setShowModal(false)
  }

  return (
    <div className="mb-6 mt-4 space-y-4 text-sm">
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}
      {filteredTasks.length > 0 ? (
        filteredTasks.map((task) => (
          <div
            className={`border rounded-xl m-1 px-4 py-2 shadow-md flex items-center space-x-2 cursor-pointer ${
              task.completed === 1 ? 'bg-violet-200' : ''
            }`}
            key={task.id}
          >
            <div
              className={`w-6 h-6 border rounded-full border-gray-400 flex items-center justify-center cursor-pointer ${
                task.completed === 1 ? 'bg-hub-primary' : 'bg-white'
              }`}
              onClick={() => handleToggleComplete(task.id)}
            >
              {task.completed === 1 && <span className="text-white">&#10003;</span>}
            </div>
            <div
              onClick={() => updateTaskClicked(task)}
              className="flex items-center justify-between space-x-2 w-full"
            >
              <div>
                <div className={`font-medium ${task.completed === 1 ? 'line-through' : ''}`}>
                  {task.title}
                </div>
                <div className="text-xs">
                  <FontAwesomeIcon icon={faClock} className="text-gray-400" />{' '}
                  {task.due_date}
                </div>
              </div>
              <div className="text-end flex items-center space-x-2">
                <div
                  className={classNames(
                    statusStyles[task.status],
                    'text-xs px-3 py-1 text-center rounded-full capitalize'
                  )}
                >
                  {task.status}
                </div>
                {task.isImportant && (
                  <div>
                    <FontAwesomeIcon
                      icon={faCircleExclamation}
                      className="text-lg bg-amber-200 text-amber-600 rounded-full p-0.5"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        ))
      ) : (
        <div>No tasks found</div>
      )}
    </div>
  )
};

export default Tasks;
