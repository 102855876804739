import React, { useState, useEffect } from 'react';
import useAuth from '../../../hooks/useAuth';
import '../../../viewRequest.css';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleDown, faCircleXmark, faChevronCircleUp, faChevronDown, faEdit, faCircleCheck, faFile, faDownload } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import Modal from '../../../components/Modal'
import ReferralDocuments from './ReferralDocuments';

const ViewRequest = ({ isOpen, onClose, viewingRequest, onRequestUpdate }) => {
  const { setShowLoader} = useAuth()
  const [activityLogItems, setActivityLogItems] = useState(null);
  const [requestItems, setRequestItems] = useState(null);
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const [isAccordionOpen, setAccordionOpen] = useState(false);
  const [isResultsOpen, setResultsOpen] = useState(false);
  const [withdraw, setWithdraw] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [activeTab, setActiveTab] = useState("Details")
  const axios = useAxiosPrivate();
  //Modal consts
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalOpen, setModalOpen] = useState(false) 
  const isReopen = viewingRequest?.claim_status === 'paid' || viewingRequest?.claim_status === 'withdrawn';

  useEffect(() => {
    if (viewingRequest?.service_request?.activity_log) {
      setActivityLogItems(JSON.parse(viewingRequest.service_request?.activity_log));
    }
    if (viewingRequest?.service_request?.request_information) {
      setRequestItems(JSON.parse(viewingRequest.service_request?.request_information));
    }
  }, [viewingRequest]);

  const toggleMoreDetails = () => setShowMoreDetails(!showMoreDetails);

  const statusClass = (serviceName) => {
    const lowerName = serviceName ? serviceName.toLowerCase() : '';

    if (lowerName.includes("waiting") || lowerName.includes("follow up") || lowerName.includes("in progress") || lowerName.includes("settle")) {
      return "bg-amber-200 text-amber-800 border-amber-400";
    }
    if (lowerName.includes("expired") || lowerName.includes("deleted") || lowerName.includes("incomplete") || lowerName.includes("withdrawn")) {
      return "bg-rose-200 text-rose-800 border-rose-400";
    }
    if (lowerName.includes("no barriers") || lowerName.includes("complete")) {
      return "bg-green-200 text-green-800 border-green-400";
    }

    return "bg-gray-200 text-gray-800"; 
  };

  // Handle file download
  const handleDownload = async () => {
    try {
      let fileName = requestItems.filename.replace('/pdf/oh-result/', '');
      window.open(`${process.env.REACT_APP_KIS}/download-decrypted-file/` + fileName, '_blank').focus();
    } catch (error) {
      console.error('Error downloading the file', error);
    }
  };

  const toggleAccordion = () => {
    setAccordionOpen(!isAccordionOpen);
  };

  const toggleResults = () => {
    setResultsOpen(!isResultsOpen);
  };

  const openWithdraw = () => {
    setWithdraw(true);
    setShowModal(true);
    setModalTitle("Withdraw this referral?");
  }

  const handleAction = () => {
    if (isReopen) {
      setModalTitle("Would you like to reopen this referral?");
    } else {
      setModalTitle(`Are you sure you want to complete ${viewingRequest.claim_reference}?`);
    }
    setCompleted(true);
    setShowModal(true);
  }; 

  const ModalBody = () => {
    if(withdraw) {
      return <WithdrawReferralElement />
    }

    return <CompleteOrReopenReferralElement />
  }
  
  const resetModal = () => {
    setShowModal(false);
    setCompleted(false);
    setWithdraw(false);
    setModalTitle('');
  };

  const CompleteOrReopenReferralElement = () => {
    const handleSubmit = async (e) => {
      e.preventDefault();
      setShowLoader(true);  
      try {
        let response;
        if (isReopen) {
          response = await axios.post(`https://test.kisdigital.co.uk/api/v1/singl/case/claim-reopen/${viewingRequest.id}?api_token=9e12909f-7aca-4513-aae7-28fd193cd3bb`);
        } else {
          response = await axios.post(`https://test.kisdigital.co.uk/api/v1/singl/case/claim-complete/${viewingRequest.id}?api_token=9e12909f-7aca-4513-aae7-28fd193cd3bb`);
        }
        
        if (response.data) {
          resetModal();
          onRequestUpdate()
        }
      } catch (err) {
        console.error("Error processing request:", err);
      } finally {
        setShowLoader(false);
      }
    };
  
    return (
      <form>
        <div className="mb-3">
          {isReopen ? "Would you like to reopen this referral?" : "Would you like to mark the referral as paid"}
        </div>
        <div className="flex mt-2 w-full">
          <button onClick={handleSubmit} type="button" className="btn mt-4 mr-4 w-1/2">
            {isReopen ? "Reopen" : "Complete"}
          </button>
          <button type="button" className="btn red mt-4 w-1/2" onClick={resetModal}>
            Cancel
          </button>
        </div>
      </form>
    );
  };

  const WithdrawReferralElement = () => {
    const handleSubmit = async (e) => {
      e.preventDefault();
      setShowLoader(true);  
      try {
        let response = await axios.post(`https://test.kisdigital.co.uk/api/v1/singl/case/claim-withdraw/${viewingRequest.id}?api_token=9e12909f-7aca-4513-aae7-28fd193cd3bb`);
        if (response.data) {
          resetModal();
          onRequestUpdate();
        }
      } catch (err) {
        console.error("Error processing request:", err);
      } finally {
        setShowLoader(false);
      }
    };
  
    return (
      <form>
        <div className="mb-3">
          Are you sure you want to withdraw this referral
        </div>
        <div className="flex mt-2 w-full">
          <button onClick={handleSubmit} type="button" className="btn mt-4 mr-4 w-1/2">
            Withdraw
          </button>
          <button type="button" className="btn red mt-4 w-1/2" onClick={resetModal}>
            Cancel
          </button>
        </div>
      </form>
    );
  };

  const renderContent = () => {
    switch (activeTab) {
      case "Details":
        return (
          <div className='p-4 flex text-gray-700 space-x-2 overflow-hidden'>
            <div className='w-1/2 flex-shrink-0 text-sm border rounded-lg p-4 overflow-hidden'>
              <h2 className='text-base font-semibold text-gray-700 mb-4'>Details</h2>
              <div className='space-y-2'>
                <div><p><strong>Title: </strong> Occupational Health Query </p></div>
                <div>
                  <p className='w-96 break-words overflow-hidden'>
                    <strong>Description: </strong> {viewingRequest?.contact_description || ''}
                  </p>
                </div>
                <div><p><strong>Contact Type: </strong> {viewingRequest?.contact_type?.name || ''}</p></div>
                <div>
                  <p><strong>Service Code: </strong>
                    {viewingRequest?.case_service?.wbs_service?.service_code || ''}
                  </p>
                </div>
                <div>
                  <p><strong>Status: </strong>
                    <span className={`${statusClass(viewingRequest?.case_service?.wbs_service?.service_name)} rounded-full px-2 py-1 capitalize font-medium text-sm`}>
                      {viewingRequest?.case_service?.wbs_service?.service_name || ''}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            
            <div className='w-1/2 flex-shrink-0 overflow-hidden'>
              {viewingRequest?.service_request && (
                <div className='text-sm border rounded-lg p-4 overflow-y-auto'>
                  <h2 className='text-base font-semibold text-gray-700 mb-4'>PPQ Details</h2>
                  <div className='space-y-2'>
                    <div><p><strong>Title: </strong> Occupational Health Query </p></div>
                    <div><p><strong>Description: </strong> {viewingRequest?.service_request?.description || 'N/A'}</p></div>
                    <div>
                      <p><strong>Status: </strong>
                        <span className={`${statusClass(viewingRequest?.service_request?.request_status)} rounded-full px-2 py-1 capitalize font-medium text-sm`}>
                          {viewingRequest?.service_request?.request_status || ''}
                        </span>
                      </p>
                    </div>
                    <div className='grid grid-cols-2 gap-x-6 gap-y-2'>
                      {viewingRequest?.service_request?.request_information ? (
                        Object.entries(JSON.parse(viewingRequest?.service_request?.request_information).staff_details).map(([key, value]) => (
                          <div key={key} className=''>
                            <strong className='capitalize'>{key.replace(/_/g, ' ')}:</strong>{' '}
                            {key.toLowerCase().includes('date') && value ? moment(value).format('DD/MM/YYYY') : value}
                          </div>
                        ))
                      ) : (
                        'Not Found'
                      )}
                    </div>
                    
                    {/* Activity Log Section */}
                    <div>
                      <p className="mb-4 flex items-center">
                        <strong>Activity Log</strong>
                        <button onClick={toggleAccordion} className="ml-2 text-indigo-900 hover:underline">
                          <FontAwesomeIcon icon={isAccordionOpen ? faChevronCircleUp : faChevronCircleDown} className="h-4 w-4 focus:border-none" />
                        </button>
                      </p>
                      {isAccordionOpen && (
                        <div className="pl-6 text-sm">
                          {/* Activity log rendering logic */}
                        </div>
                      )}
                    </div>

                    {/* File Download Section */}
                    {requestItems && requestItems?.filename && (
                      <div className='flex items-center justify-between border border-teal-400 bg-teal-50 rounded-md px-4 py-2 text-sm'>
                        <div className='flex items-center'>
                          <FontAwesomeIcon icon={faFile} className="h-4 w-4 text-[#02ADAD] pr-2" />
                          <div className='text-gray-800'>PPQ Results Document</div>
                        </div>
                        <div className='flex items-center space-x-2'>
                          <FontAwesomeIcon
                            icon={faDownload}
                            className="text-[#02ADAD] hover:text-teal-700 cursor-pointer"
                            onClick={handleDownload}
                          />
                        </div>
                      </div>
                    )}

                    {/* Questionnaire Results Section */}
                    <div>
                      <p className="mb-4 flex items-center">
                        <strong>Questionnaire Results</strong>
                        <button onClick={toggleResults} className="ml-2 text-indigo-900 hover:underline">
                          <FontAwesomeIcon icon={isResultsOpen ? faChevronCircleUp : faChevronCircleDown} className="h-4 w-4 focus:border-none" />
                        </button>
                      </p>
                      {isResultsOpen && (
                        <div className="pl-6 text-sm">
                          <ol className="relative border-s">Results here</ol>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      case "Documents":
        return (
          <div className='p-4 text-gray-700 m-4'>
            <ReferralDocuments viewingRequest={viewingRequest} />
          </div>
        );
      case "Download Result":
        return (
          <div className="p-4">
            <a
              href={`${process.env.REACT_APP_KIS}/download-decrypted-file/${viewingRequest?.request_information?.filename}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 underline"
            >
              Click here to download the result
            </a>
          </div>
        );
      default:
        return null;
    }
  };

  return (
   <div>
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog static as="div" className="relative z-[100]" onClose={() => {}}>
        {showModal && (
          <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
        )}
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex w-2/3 pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen transform transition duration-500 ease-in-out sm:duration-700">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="bg-[#02ADAD] px-4 py-6 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-2xl font-semibold leading-6 text-white">
                          Reference: {viewingRequest?.claim_reference || ''}
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button type="button" className="relative text-teal-100 hover:text-white" onClick={onClose}>
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <FontAwesomeIcon icon={faCircleXmark} className="h-6 w-6 focus:border-none" />
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* Tab navigation */}
                    <div >
                      <ul className='flex items-center space-x-2 p-4 text-sm'>
                        <li 
                            className={`rounded-lg px-4 py-2 cursor-pointer hover:shadow-md hover:bg-nav hover:text-white ${activeTab === 'Details' ? 'bg-nav text-white' : 'bg-gray-200 text-gray-700'}`} 
                            onClick={() => setActiveTab('Details')}
                          >
                          Details
                        </li>
                        <li 
                          className={`rounded-lg px-4 py-2 cursor-pointer hover:shadow-md hover:bg-nav hover:text-white ${activeTab === 'Documents' ? 'bg-nav text-white' : 'bg-gray-200 text-gray-700'}`} 
                          onClick={() => setActiveTab('Documents')}
                        >
                          <FontAwesomeIcon icon={faFile} className="h-4 w-4 focus:border-none" /> Documents
                        </li>
                        <li 
                          className="rounded-lg px-4 py-2 cursor-pointer hover:shadow-md hover:bg-nav hover:text-white bg-gray-200 text-gray-700"
                          onClick={handleAction}
                        >
                          <div className='flex items-center space-x-2'> 
                            <FontAwesomeIcon icon={faCircleCheck} className="h-4 w-4 focus:border-none" /> 
                            <span>{isReopen ? "Reopen" : "Complete"}</span>
                          </div>
                        </li>
                        {viewingRequest?.claim_status !== "withdrawn" && (
                          <li  onClick={openWithdraw}
                          className={`rounded-lg px-4 py-2 cursor-pointer hover:shadow-md hover:bg-nav hover:text-white bg-gray-200 text-gray-700`} 
                          >
                            Withdraw 
                          </li>
                        )}
                        <li 
                          className={`rounded-lg px-4 py-2 cursor-pointer hover:shadow-md hover:bg-nav hover:text-white bg-gray-200 text-gray-700`} 
                          onClick={() => setActiveTab('staff')}
                        >
                          <FontAwesomeIcon icon={faDownload} className="h-4 w-4 focus:border-none" /> Download Result
                        </li>
                      </ul>
                    </div>
                    {/* Tab content */}
                    {renderContent()}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
   </div>
  );
};

export default ViewRequest;


