import useAxiosPrivate from '../../../hooks/useAxiosPrivate';

const RequestInfo = ({ requestInfo }) => {

  return (
    <div className="w-full bg-white rounded-xl shadow-md p-4 mb-8">
      <h2 className="font-bold text-xl mb-4">Request Information</h2>
      <div className="border-gray-300 border-solid border-1 rounded-xl">
        {requestInfo && requestInfo.map((info, index) => (
          <div key={index} className={`w-full flex p-4 ${index === 0 ? 'rounded-t-xl' : ''} ${index === requestInfo.length - 1 ? 'rounded-b-xl' : ''} ${index % 2 === 0 ? 'bg-gray-50' : 'bg-gray-100'}`}>
            <p className="text-sm text-gray-600 mr-2 font-bold">{info.title}: </p>
            <p className="text-sm text-gray-600">{info.value}</p>
          </div>
        ))}
        {!requestInfo && (
          <div className="w-full">
            <p className="text-sm text-gray-600">Loading Request Info</p>
          </div>
        )}
      </div>
    </div>
  )
};

export default RequestInfo;
