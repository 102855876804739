/* eslint-disable */
import { useState, useEffect } from 'react'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import MainTitle from '../../../components/MainTitle'
import { useParams } from 'react-router-dom'
import useAuth from '../../../hooks/useAuth'
import moment from 'moment'
import { faCirclePlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Modal from '../../../components/Modal'

const Notes = () => {
  const { id } = useParams()
  const axios = useAxiosPrivate()
  const { setShowLoader, createInfo, can } = useAuth()
  //Modal consts
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [addNote, setAddNote] = useState(false)
  const [deleteNote, setDeleteNote] = useState(false)
  const [note, setNote] = useState(null)
  const [generalNotes, setGeneralNotes] = useState({})
  const [nurseNotes, setNurseNotes] = useState({})
  const [noteType, setNoteType] = useState(null)
  const [addNoteBody, setAddNoteBody] = useState({})
  //
  const [organisationData, setOrganisationData] = useState({
    name: '',
    reference: 'HUB-1',
  })

  const getOrganisationById = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/organisations/${id}`)
      await setOrganisationData(response.data.result)
      setShowLoader(false)
    } catch (error) {}
  }

  const getNotes = async () => {
    try {
      setShowLoader(true);
      const response = await axios.get(`/organisations/${id}/notes`)
      const generalNotes = response.data.result.filter(note => note.note_type_id === 1);
      await setGeneralNotes(generalNotes);
  
      const nurseNotes = response.data.result.filter(note => note.note_type_id === 2);
      await setNurseNotes(nurseNotes);
  
      setShowLoader(false);
    } catch (error) {
      console.error(error);
      setShowLoader(false);
    }
  }  

  useEffect(() => {
    getOrganisationById()
    getNotes()
  }, [id])

  const addNoteClicked = (noteType) => {
    setNoteType(noteType);
    setAddNote(true);
    setModalTitle(`Create Note`);
    setShowModal(true);
  };

  const deleteNoteClicked = (note) => {
    setDeleteNote(true);
    setNote(note);
    setModalTitle(`Delete Note`);
    setShowModal(true);
  };
  
  const ModalBody = () => {
    if (addNote) {
      return <AddNoteElement noteType={noteType} />
    }

    if (deleteNote) {
      return <DeleteNoteElement />
    }
  }

  const AddNoteElement = ({ noteType }) => {
    const createNote = async (e) => {
      e.preventDefault();
      setShowLoader(true);
      try {
        const response = await axios.post(`/organisations/${id}/notes/create`, {
          body: addNoteBody.body ?? "Test String",
          type: noteType === 'general' ? 1 : 2,
        });
  
        if (response.data.error === false) {
          createInfo('success', `Note Added`);
          setAddNoteBody({});

          const newNote = response.data.result;
          if (noteType === 'general') {
            setGeneralNotes(prevNotes => [...prevNotes, newNote]);
          } else if (noteType === 'nurse') {
            setNurseNotes(prevNotes => [...prevNotes, newNote]);
          }
        }
      } catch (error) {
        console.log(error.message);
        createInfo('error', `Failed to create note`);
      }
      resetModal();
      setShowLoader(false);
    };

    return (
      <form onSubmit={createNote}>
        <label className='text-xs' htmlFor="">Description <span className='text-red-500'>*</span></label>
        <textarea
          value={addNoteBody.body}
          onChange={(e )=> addNoteBody.body = e.target.value}
          required
          className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md focus:ring-violet-500 focus:border-violet-500"
        />
        <div className="text-xs mt-2 flex justify-end">
          <span className="text-red-500">*</span> denotes required fields.
        </div>
        <div className="flex mt-2 w-full">
          <button type="submit" className="btn mt-4 mr-4 w-1/2">Add Note</button>
          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const DeleteNoteElement = () => {
    const destroyNote = async (e) => {
      e.preventDefault();
      setShowLoader(true);
      try {
        const response = await axios.get(`/organisations/notes/delete/${note.id}`);
        if (response.status === 200) {
          createInfo('success', 'Note deleted successfully');
          if (note.note_type_id === 1) {
            setGeneralNotes(currentNotes => currentNotes.filter(n => n.id !== note.id));
          } else if (note.note_type_id === 2) { 
            setNurseNotes(currentNotes => currentNotes.filter(n => n.id !== note.id));
          }
        }
      } catch (error) {
        console.error(error.message);
        createInfo('error', 'Failed to delete note');
      }
      setShowLoader(false);
      resetModal();
    };

    return (
      <form onSubmit={destroyNote}>
        <div className="mb-3">
         Are you sure you want to delete <span className='font-medium'>"{note.body}"</span>?
        </div>
        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Delete note</button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const resetModal = () => {
    setShowModal(false)
    setAddNote(false)
    setDeleteNote(false)
    setAddNoteBody({});
    setModalTitle('')
  }

  return (
    <div>
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}
      {organisationData.id && (
        <section className='text-gray-700'>
          <h2 className="font-bold text-xl mb-2">Notes</h2>
          <div className='space-y-4'>
            <div className="w-full bg-white rounded-xl shadow-md p-4 mb-8">
              <div className='flex items-center justify-between px-2'>
                <h3 className='font-medium'>General Notes</h3>
                <button onClick={() => addNoteClicked('general')} className='text-nav text-lg hover:text-nav-dark'><FontAwesomeIcon icon={faCirclePlus} className='cursor-pointer' /></button>
              </div>
              {generalNotes.length > 0 && (
                generalNotes.map((note) => {
                  return (
                    <div key={note.id} className='flex items-start justify-between mt-6 p-2 border-b max-h-screen overflow-y-scroll'>
                      <div className='w-3/4 space-y-2'>
                        <div className='text-sm font-medium text-violet-700'>{note.user?.name}</div>
                        <div className=''>{note.body}</div>
                      </div>
                      <div className='flex flex-col items-end space-y-2'>
                        <div className='text-gray-700 text-sm'>{moment(note.created_at).format('DD/MM/YYYY')}</div>
                        <FontAwesomeIcon onClick={() => deleteNoteClicked(note)} icon={faTrash} className='text-red-800 hover:text-red-600 cursor-pointer' />
                      </div>
                    </div>
                  )
                })
              )}
            </div>
            {can('access nurse notes') ? (
              <div className="w-full bg-white rounded-xl shadow-md p-4 mb-8 ">
                <div className='flex items-center justify-between px-2'>
                  <h3 className='font-medium'>Nurse Notes</h3>
                  <button onClick={() => addNoteClicked('nurse')} className='text-nav text-lg hover:text-nav-dark'><FontAwesomeIcon icon={faCirclePlus} className='cursor-pointer' /></button>
                </div>
                  {nurseNotes.length > 0 && (
                    nurseNotes.map((note) => {
                      return (
                        <div key={note.id} className='flex items-start justify-between mt-6 p-2 border-b max-h-screen overflow-y-scroll'>
                          <div className='w-3/4 space-y-2'>
                            <div className='text-sm font-medium text-violet-700'>{note.user_name}</div>
                            <div className=''>{note.body}</div>
                          </div>
                          <div className='flex flex-col items-end space-y-2'>
                            <div className='text-gray-700 text-sm'>{moment(note.created_at).format('DD/MM/YYYY')}</div>
                            <FontAwesomeIcon onClick={() => deleteNoteClicked(note)} icon={faTrash} className='text-red-800 hover:text-red-600 cursor-pointer' />
                          </div>
                        </div>
                      )
                    })
                  )}
              </div>
            ) : null}
          </div>

        </section>
      )}
    </div>
  )
}

export default Notes
