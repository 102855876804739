import { React, useEffect, useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useParams } from 'react-router-dom'
import useAuth from "../hooks/useAuth";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faUpload, faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { PhoneNumberUtil } from 'google-libphonenumber';

function UserProfile({ user, onUpdateUser }) {
  const { id } = useParams()
  const axios = useAxiosPrivate();
  const [editMode, setEditMode] = useState(false);
  const [editProfileData, setEditProfileData] = useState({})
  const { setShowLoader, admin, can, auth } = useAuth()
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [validEmail, setValidEmail] = useState(true)


  const handlePhoneNumberChange = (event) => {
    const { value } = event.target;
    setPhoneNumber(value);
    const isValidNumber = validatePhoneNumber(value);
    setIsValid(isValidNumber);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberUtil = PhoneNumberUtil.getInstance();

    try {
      const parsedNumber = phoneNumberUtil.parse(phoneNumber, 'GB');
      const isValidNumber = phoneNumberUtil.isValidNumber(parsedNumber);
      return isValidNumber;
    } catch (e) {
      console.error('Error parsing phone number:', e);
      return false;
    }
  };

  const handleEditClick = () => {
    setEditMode((prevState) => !prevState);
    const data = {
      name: user.name,
      email: user.email,
      phone_number: user.phone_number
    }
    setEditProfileData({...editProfileData, ...data});
  };

  const handleInputChange = (fieldName, e) => {
    setEditProfileData({ ...editProfileData, [fieldName]: e.target.value });
  };

  const handleSaveClick = async () => {
    let data = editProfileData
    setValidEmail(true)

    try {
      setShowLoader(true)
      const response = await axios.post(`/users/update/${id}`, data)
      const responseData = response.data.result;
      setEditProfileData({...editProfileData, ...{
        name: responseData.name,
        email: responseData.email,
        phone_number: responseData.phone_number
      }})
      onUpdateUser(response.data.result);
      setEditMode(false);
      setValidEmail(false);
    } catch (error) { 
      console.error(error);
    }
    
    setShowLoader(false)
  };


  return (
    <div>
      <div style={{ paddingBottom: 25 }} className="bg-white rounded-md shadow-md py-3 px-4 mb-8">
        {admin || auth.user.id === id ? (
           <div className="flex justify-end">
           <FontAwesomeIcon
             title='Edit Client'
             onClick={handleEditClick}
             icon={faPenToSquare}
             className={`text-xl hover:text-violet-600 h-6 w-6 cursor-pointer ${editMode ? 'text-violet-400' : ''}`}
           />
         </div>
        ) : (
          <div></div>
        )}
        <div className="flex mt-20 w-full">
          <div className="rounded-full overflow-hidden relative w-48 border-4 border-white h-48 block shadow ml-10 -mt-20 z-10 bg-white">
            <span
              className="profile-pic"
              style={{
                backgroundImage: `url(${user.profile_pic})`,
                PointerEvent: "none",
              }}
            ></span>
          </div>
          <div className={editMode ? 'flex justify-end w-4/5' : 'flex justify-between w-4/5'}>
            <div className="block ml-8 mt-5 pr-6">
                <h3 className="text-violet-400 font-bold text-lg">Name:</h3>
                {editMode ? (
                 <div>
                   <input
                    type="text"
                    className="border-2 border-slate-400 rounded-lg p-2 w-full"
                    defaultValue={editProfileData.name}
                    onChange={(e) => handleInputChange('name', e)}
                  />

                  <p
                    id="emailnote"
                    className={
                      !validEmail ? 'instructions failed' : 'offscreen'
                    }
                  >
                    <FontAwesomeIcon icon={faCircleExclamation} />
                    This email already exists, please enter a different one.
                  </p>
                 </div>
                ) : (
                  <h4 className="text-gray-800 font-bold">{user.name}</h4>
                )}

            </div>
            <div className="block mt-5 border-gray-200 border-l px-6">
                <h3 className="text-violet-400 font-bold text-lg">Email:</h3>
                {editMode ? (
                  <input
                    type="text"
                    className="border-2 border-slate-400 rounded-lg p-2 w-full"
                    defaultValue={editProfileData.email}
                    onChange={(e) => handleInputChange('email', e)}
                  />
                ) : (
                  <h4 className="text-gray-800 font-bold">{user.email}</h4>
                )}
            </div>
            <div className="block mt-5 border-gray-200 border-l px-6">
              <div>
                <h3 className="text-violet-400 font-bold text-lg">
                  Contact Number:
                </h3>
                {editMode ? (
                  <div>
                    <input
                    type="text"
                    className="border-2 border-slate-400 rounded-lg p-2 w-full"
                    defaultValue={editProfileData.phone_number}
                    onChange={(e) => {
                      handlePhoneNumberChange(e);
                      handleInputChange('phone_number', e);
                    }}
                  />
                  
                  {phoneNumber.length > 0 && (
                    <>
                      {isValid ? (
                        <span className='text-green-500 text-sm'>Valid phone number</span>
                      ) : (
                        <span className='text-red-600 text-sm'>Invalid phone number</span>
                      )}
                    </>
                  )}
                  </div>
                ) : (
                  <h4 className="text-gray-800 font-bold">{user.phone_number}</h4>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='flex justify-end'>
              {editMode && (
                  <button onClick={handleSaveClick} className='px-4 py-2 rounded-lg bg-violet-400 text-white hover:bg-violet-600'>
                    <FontAwesomeIcon icon={faUpload}/><span className='pl-1'>Save</span>
                  </button>
              )}
            </div>
      </div>
    </div>
  );
}

export default UserProfile;
