/* eslint-disable */
import React, { useRef, useState, useEffect } from 'react'
import { faCircleExclamation, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Link } from 'react-router-dom'

import axios from '../api/axios'

const EMAIL_REGEX = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,12})$/
const FORGOT_PWD_URL = '/forgot-password'

const ForgotPassword = () => {

  const emailRef = useRef()
  const errRef = useRef()

  const [email, setEmail] = useState('')
  const [validEmail, setValidEmail] = useState(false)
  const [emailFocus, setEmailFocus] = useState(false)

  const [errMsg, setErrMsg] = useState('')
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    emailRef.current.focus()
  }, [])

  useEffect(() => {
    const result = EMAIL_REGEX.test(email)
    setValidEmail(result)
  }, [email])

  useEffect(() => {
    setErrMsg('')
  }, [email,])

  const handleSubmit = async (e) => {
    e.preventDefault()

    const v1 = EMAIL_REGEX.test(email)
    if (!v1) {
      setErrMsg('Invalid Entry')
      return
    }

    try {
      await axios.post(FORGOT_PWD_URL, { email })
      setEmail('')
      setSuccess(true);

    } catch (error) {
      if (!error?.response) {
        setErrMsg('No Server Response')
      } else if (error.response?.status === 422) {
        setErrMsg('The email provided already exists.')
      } else {
        setErrMsg('Error Occured. Could not create account.')
      }
      errRef.current.focus()
    }
  }

  return (   
    <div className='p-6 space-y-4 md:space-y-6 sm:p-8'>
      <h1 className="text-2xl font-bold text-center pb-4 leading-tight tracking-tight text-gray-700 md:text-2xl dark:text-white">
        Forgot your Password?
      </h1>
    
      <p ref={errRef} className={errMsg ? 'errmsg' : 'offscreen'} aria-live='assertive'>
        {errMsg}
      </p>
      <form onSubmit={handleSubmit} className='space-y-4 md:space-y-6'>
        <div className=" w-full">
          <label htmlFor="email" className='block mb-2 text-sm font-medium text-gray-700 dark:text-white'>Email</label>
          <input
            type="email"
            ref={emailRef}
            id="email"
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Email"
            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:outline-none focus:border-violet-300 focus:ring-1 focus:ring-violet-300 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
          <span className={validEmail ? 'valid input-check' : 'hide input-check'}>
            <FontAwesomeIcon icon={faCheck} />
          </span>
          <span className={validEmail || !email ? 'hide input-check' : 'invalid input-check'}>
            <FontAwesomeIcon icon={faTimes} />
          </span>
          <p id='emailnote' className={emailFocus && email && !validEmail ? 'instructions' : 'offscreen'}>
            <FontAwesomeIcon icon={faCircleExclamation} />
            Invalid email address.
            <br /> Valid email addresses can contain only letters, numbers, '@' and '.'
          </p>
        </div>

        <div className='mt-4 text-wba-primary text-xs flex justify-center'>
          <Link to='/login' className='hover:text-blue-800'>
            Back to Login
          </Link>
        </div>
        
        <div className='input-wrap'>
          <input type='submit' disabled={!validEmail ? true : false} value='Reset Password' />
        </div>
      </form>
    </div>
  )
}

export default ForgotPassword
