import React from 'react'

function NurseSupport({setSupportType}) {
  return (
    <div className=''>
        <div>
        <label htmlFor="" className='text-slate-800'>What support are you looking for?</label>
        <input
            type="text"
            id="supportType"
            required
            onChange={(e) => { setSupportType(e.target.value)}}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-violet-500 dark:focus:border-violet-500"
        />
        </div>
       
        
    </div>
  )
}

export default NurseSupport