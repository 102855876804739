/* eslint-disable */
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import MainTitle from '../../../components/MainTitle'
import useAuth from '../../../hooks/useAuth'
import { useState, useEffect, Link, Fragment } from 'react'
import Pagination from '../../../components/Pagination';
import { useParams, useLocation, useNavigate, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faEye, faSortUp, faSort, faSortDown, faPlusCircle, faChevronDown, faUpRightAndDownLeftFromCenter} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import Modal from '../../../components/Modal'
import CardButton from '../../../components/CardButton';
import { Menu, Transition } from '@headlessui/react'
import ClientNav from '../../../components/Admin/ClientNav'
import ViewClaimSlideOut from '../../../components/ViewClaimSlideOut';

const ClientReferrals = ({policy}) => {
  const { id } = useParams()
  const axios = useAxiosPrivate()
  const { setShowLoader, can, createInfo, auth, admin } = useAuth()
  // search
  const [filteredRecords, setFilteredRecords] = useState([])
  const [nPages, setNumberOfPages] = useState(0)
  const [currentRecords, setCurrentRecords] = useState([])
  const navigate = useNavigate();
  
  const [search, setSearch] = useState('')
  const [statusFilter, setStatusFilter] = useState('');

  // No of Records to be displayed on each page
  const [currentPage, setCurrentPage] = useState(1)
  const [recordsPerPage] = useState(10)
  const [indexOfLastRecord, setIndexOfLastRecord] = useState(10)
  const [indexOfFirstRecord, setIndexOfFirstRecord] = useState(0)
  const [originalRecords, setOriginalRecords] = useState([]);
  const [columnClickCounts, setColumnClickCounts] = useState({});
  const [claimOpen, setClaimOpen] = useState(false)
  //Modal consts
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  //
  const [enquiries, setEnquiries] = useState([])
  const [clientData, setClientData] = useState([])
  // const [claims, setClaims] = useState(null);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending',
  });
  const [claimStatuses, setClaimStatuses] = useState({})
  const [dropdownValue, setDropdownValue] = useState('');

  useEffect(() => {
    getStatuses();
    getClientById()
    getClientClaims(id)
  }, [statusFilter, id])

  const getClientById = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/clients/` + id)
         setClientData(response.data.result)
    } catch (error) {
      console.log(error)
      setShowLoader(false)
    }
    setShowLoader(false)
  }

  const getClientClaims = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/claims/client/` + id)
      // let filteredClaims = response.data.result;
      // if (statusFilter) {
      //   filteredClaims = filteredClaims.filter(claim => claim.status.title.toLowerCase() === statusFilter);
      // }
      // if (filteredClaims) {
        // await setClaims(filteredClaims)
        await setOriginalRecords(response.data.result)
        await setCurrentRecords(response.data.result)
        // await setFilteredRecords(filteredClaims)
        setShowLoader(false)
      // }
    } catch (error) {
      console.log(error)
      setShowLoader(false)
    }
    setShowLoader(false)
  }
  
  const handleStatusChange = (e) => {
    setStatusFilter(e.target.value.toLowerCase());
    setDropdownValue(e.target.value);
  }

  const getStatuses = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/claims/statuses`)
      if (response.data) {
        await setClaimStatuses(response.data.result)
        setShowLoader(false)
      }
    } catch (error) {
      setShowLoader(false)
    }
    setShowLoader(false)
  }

  const resetFilters = () => {
    setStatusFilter('');
    setSearch('');
    setDropdownValue('');
  };

  const isFilterApplied = () => {
    return statusFilter !== '' || search !== '';
  };

  const handleSearch = (handle) => {
    setIndexOfFirstRecord(0)
    setIndexOfLastRecord(10)
    setCurrentPage(0)
    setCurrentRecords(filteredRecords.slice(0, 10))

    let searchValue = handle.target.value
    let searchString = searchValue.trim().toLowerCase()
    if (searchString.length > 0) {
      let records = enquiries.filter((e) => {
        return (
          e.name.toLowerCase().match(searchString)
        )
      })
      setFilteredRecords(records)
      setCurrentRecords(records.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(records.length / recordsPerPage))
    } else {
      setFilteredRecords(enquiries)
      setCurrentRecords(enquiries.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(enquiries.length / recordsPerPage))
    }
  }

  const viewClaimClicked = (claimId) => {
    navigate(`/clients/${id}/claims/${claimId}`)
  }

  const handleSort = (key) => {
    let newColumnClickCounts = { ...columnClickCounts };
    if (!newColumnClickCounts[key]) {
      newColumnClickCounts[key] = 1;
    } else {
      newColumnClickCounts[key]++;
    }

    if (newColumnClickCounts[key] === 3) {
      setSortConfig({
        key: null,
        direction: 'descending',
      });
      newColumnClickCounts[key] = 0;
      setCurrentRecords(originalRecords.slice(
        indexOfFirstRecord,
        indexOfLastRecord
      ));
    } else {
      let direction =
        sortConfig.direction === 'ascending'
          ? 'descending'
          : 'ascending';
      setSortConfig({ key, direction });
  
      const sortedRecords = sortArray(
        originalRecords,
        key,
        direction
      );
      setCurrentRecords(sortedRecords.slice(
        indexOfFirstRecord,
        indexOfLastRecord
      ));
    }
    setColumnClickCounts(newColumnClickCounts);
  };

  const resetModal = () => {
    setShowModal(false)
    setModalTitle('')
  }

  const createClaimClicked = () => 
  {
    setClaimOpen(true)
  }

  const sortArray = (array, key, direction) => {
    return [...array].sort((a, b) => {
      
      const segments = key.split('.');
      let aKey = a;
      let bKey = b;

      segments.forEach(element => {
        if(!aKey[element]) {
          aKey = '';
          return;
        }

        if(!bKey[element]) {
          bKey = '';
          return;
        }

        aKey = aKey[element];
        bKey = bKey[element];
      });

      if (aKey < bKey) return direction === 'ascending' ? -1 : 1;
      if (aKey > bKey) return direction === 'ascending' ? 1 : -1;
      return 0;
    });
  };

  const renderSortArrow = (columnKey) => {
    if (sortConfig.key === columnKey) {
      if (sortConfig.direction === 'ascending') {
        return <FontAwesomeIcon icon={faSortUp} />;
      } else {
        return <FontAwesomeIcon icon={faSortDown} />;
      }
    }
    return <FontAwesomeIcon icon={faSort} />;
  };

  const nextPage = () => {
    if (currentPage < nPages) {
      const newPage = currentPage + 1;
      const indexFirst = newPage * recordsPerPage - recordsPerPage;
      const indexLast = newPage * recordsPerPage;
  
      setIndexOfFirstRecord(indexFirst);
      setIndexOfLastRecord(indexLast);
      setCurrentPage(newPage);
      const sortedRecords = sortArray(originalRecords, sortConfig.key, sortConfig.direction);
      setCurrentRecords(sortedRecords.slice(indexFirst, indexLast));
    }
  };
  
  const previousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      const indexFirst = (newPage - 1) * recordsPerPage;
      const indexLast = newPage * recordsPerPage;
  
      setIndexOfFirstRecord(indexFirst);
      setIndexOfLastRecord(indexLast);
      setCurrentPage(newPage);
  
      const sortedRecords = sortArray(originalRecords, sortConfig.key, sortConfig.direction);
      setCurrentRecords(sortedRecords.slice(indexFirst, indexLast));
    }
  };

  const ModalBody = () => {
   
  }  

  return (
    <section>
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}
      <ViewClaimSlideOut claimOpen={claimOpen} setClaimOpen={setClaimOpen} />
      <section className="">
        <div className='relative'>
            <div className="flex items-center w-full justify-between"></div>
            <div className="pb-4 flex items-center justify-between">
              <div className="relative flex items-center space-x-2">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    onClick={handleSearch}
                  />
                </div>
                <input
                  type="text"
                  id="table-search"
                  onChange={handleSearch}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block w-80 pl-10 p-2.5  "
                  placeholder="Search Referrals"
                />
                {can('view client status') &&
                  <div className="ml-auto relative">
                    <select
                      onChange={handleStatusChange}
                      value={dropdownValue}
                      className="px-4 border border-gray-300 rounded-md mr-2"
                    >
                      <option value="">Filter by Status</option>
                      {claimStatuses.length > 0 && claimStatuses?.map((status, index) => {
                          return <option key={index} value={status.title}>
                          {status.title}
                        </option>
                      })}
                    </select>
                    {isFilterApplied() && (
                      <button
                        onClick={resetFilters}
                        className="bg-red-500 text-white rounded-lg px-4 py-2 mr-2"
                      >
                        Reset Filters
                      </button>
                    )}
                  </div>
                }
              </div>
              
              <div onClick={createClaimClicked}>
                <FontAwesomeIcon icon={faPlusCircle} className='text-lg text-nav hover:text-nav-dark cursor-pointer' />
              </div>
            </div>

            <table className="table-main mt-4 mb-10">
              <thead>
                <tr>
                  <th className='cursor-pointer' scope="col" onClick={() => handleSort('reference')}>Reference {renderSortArrow('reference')}</th>
                  <th className='cursor-pointer' scope="col" onClick={() => handleSort('service.name')}>Service {renderSortArrow('service')}</th>
                  {can('view client status') && <th className='cursor-pointer' scope="col" onClick={() => handleSort('statuses.title')}>Status {renderSortArrow('statuses.title')} </th>}
                  <th className='cursor-pointer' scope="col" onClick={() => handleSort('created_at')}>Created At {renderSortArrow('created_at')}</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
              {currentRecords?.length > 0 ? (
                currentRecords?.map((claim) => {
                  return (
                    <tr key={claim.id}>
                      <td>{claim.reference}</td>
                      <td>{claim.service.name}</td>
                      <td><span className={`status ${claim.status.title.replace(/\s+/g, '-').toLowerCase()}`}>{claim.status.title}</span></td>
                      <td>{moment(claim.created_at).format('DD/MM/YYYY')}</td>
                      <td>
                        <Menu as="div" className="relative inline-block text-left">
                          <div>
                            <Menu.Button className="flex items-center rounded-md cursor-pointer ml-2 hover:bg-gray-300 px-2 py-1">
                              <span className="font-semibold text-sm mr-2">
                                Actions
                              </span>
                              <FontAwesomeIcon icon={faChevronDown} />
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="z-50 absolute right-0 mt-2  origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <div className="p-2 space-y-2 relative">
                                  <Menu.Item>
                                    <div onClick={() => viewClaimClicked(claim.id)} title="View Claim" className="flex justify-start items-center px-2 py-1 rounded-md hover:bg-blue-200 cursor-pointer">
                                      <FontAwesomeIcon icon={faEye} className='text-blue-800' /> <div className='pl-2'>View</div>
                                    </div>
                                  </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={8}>
                    <p className="no-records">No referrals found</p>
                  </td>
                </tr> 
              )}
              </tbody>
            </table>
            <Pagination
              next={nextPage}
              prev={previousPage}
              first={indexOfFirstRecord}
              last={indexOfLastRecord}
              total={filteredRecords.length}
            />
        </div>
      </section>
    </section>
  )
}

export default ClientReferrals

