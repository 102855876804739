import React, { useState, useEffect, useRef } from 'react';
import { faCircleChevronLeft, faCircleChevronRight, faCircle, faVideoCamera, faPhone, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SlideOut from '../../../components/CalendarSlideOut';

const MonthCalendar = () => {
  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const dayAbbreviations = daysOfWeek.map(day => day.charAt(0));
  const [open, setOpen] = useState(false)
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const dummyAppointments = {
    "2024-10-10": [
      { id: 1, name: "Brian Matthews", time: "10:00 am - 11:00 am", type: "video", profileLink: "/profile/brian-matthews", date: "2024-10-10" }
    ],
    "2024-10-11": [
      { id: 2, name: "Sandy Weaver", time: "2:00 pm - 3:00 pm", type: "telephone", profileLink: "/profile/sandy-weaver", date: "2024-10-11" }
    ],
    "2024-10-12": [
      { id: 3, name: "Tom Cooper", time: "4:00 pm - 5:00 pm", type: "video", profileLink: "/profile/tom-cooper", date: "2024-10-12" }
    ],
    "2024-10-13": [
      { id: 4, name: "Emily Johnson", time: "9:00 am - 10:00 am", type: "telephone", profileLink: "/profile/emily-johnson", date: "2024-10-13" }
    ],
  };

  const [date, setDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const [appointmentsForWeek, setAppointmentsForWeek] = useState([]);

  const daysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const firstDayOfMonth = () => {
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    return firstDay.getDay();
  };

  const handlePrevMonth = () => {
    setDate(new Date(date.getFullYear(), date.getMonth() - 1, 1));
  };

  const handleNextMonth = () => {
    setDate(new Date(date.getFullYear(), date.getMonth() + 1, 1));
  };

  const isCurrentDate = (day) => {
    const today = new Date();
    return (
      day === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const handleClickDay = (day) => {
    const selectedDateStr = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    setSelectedDate(selectedDateStr);
    setAppointmentsForWeek(getWeekAppointments(day));
  };

  const calendarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setSelectedDate(null);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const hasDataForDay = (day) => {
    const dateStr = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    return dummyAppointments[dateStr];
  };

  const renderDays = () => {
    const totalDays = daysInMonth(date.getFullYear(), date.getMonth());
    const firstDay = firstDayOfMonth();
    const days = [];

    for (let i = 0; i < firstDay; i++) {
      days.push(<div key={`empty-${i}`} className="rounded-lg bg-gray-200 h-full"></div>);
    }

    for (let day = 1; day <= totalDays; day++) {
      days.push(
        <div
          key={day}
          className={`calendar-day flex flex-col justify-center items-center px-2 py-4 text-center relative
            ${isCurrentDate(day) ? 'bg-violet-200' : ''} 
            ${selectedDate && selectedDate === `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}` ? 'bg-light-blue' : ''} 
            rounded-lg cursor-pointer hover:bg-violet-200`}
          onClick={() => handleClickDay(day)}
        >
          <div>{day}</div>
          {hasDataForDay(day) && <FontAwesomeIcon icon={faCircle} className="absolute bottom-0 h-2 w-2 mb-1" />}
        </div>
      );
    }

    return days;
  };

  // Function to get the week's appointments based on the clicked day
  const getWeekAppointments = (selectedDay) => {
    const appointments = []
    const currentYear = date.getFullYear()
    const currentMonth = String(date.getMonth() + 1).padStart(2, '0')

    for (let i = 0; i < 7; i++) {
      const currentDay = String(selectedDay + i).padStart(2, '0')
      const dateStr = `${currentYear}-${currentMonth}-${currentDay}`

      if (dummyAppointments[dateStr]) {
        appointments.push(...dummyAppointments[dateStr])
      }
    }

    return appointments
  }

  const openRequest = () => {
    setOpen(true)
  }

  return (
    <div className='flex w-full bg-white rounded-xl p-4 space-x-4'>
      <div className="border rounded-xl w-1/2" ref={calendarRef}>
        {/* Calendar Header */}
        <div className="calendar-header bg-nav rounded-t-xl text-white px-2 py-4 flex justify-between items-center">
          <button onClick={handlePrevMonth} className='hover:bg-hub-primary-hover rounded-lg py-2 px-4'><FontAwesomeIcon icon={faCircleChevronLeft} className="cursor-pointer h-5 w-5 " /></button>
          <h2 className='font-medium'>{months[date.getMonth()]} {date.getFullYear()}</h2>
          <button onClick={handleNextMonth} className='hover:bg-hub-primary-hover rounded-lg py-2 px-4'><FontAwesomeIcon icon={faCircleChevronRight} className="cursor-pointer h-5 w-5 " /></button>
        </div>
  
        {/* Calendar Days */}
        <div className="calendar-days grid grid-cols-7 gap-1 p-1 pb-2 text-sm text-dark-blue">
          {dayAbbreviations.map((dayAbbr, index) => (
            <div key={index} className={`calendar-day-header font-bold px-2 py-4 text-center ${index >= 1 ? 'hidden md:block' : ''} ${index >= 4 ? 'hidden lg:block' : ''}`}>
              {dayAbbr}
            </div>
          ))}
          {renderDays()}
        </div>
      </div>

      {/* Week's Appointments Section */}
      <div className="w-1/2 space-y-2 text-dark-blue overflow-y-auto max-h-96">
        <div className='flex items-start justify-between'>
          <div className='text-lg font-medium mb-3'>Appointments This Week</div>
          <FontAwesomeIcon icon={faPlusCircle} className='text-lg hover:text-nav-dark cursor-pointer' onClick={openRequest} />
        </div>
        {appointmentsForWeek.length > 0 ? (
          appointmentsForWeek.map((appointment, index) => {
            const appointmentDate = new Date(`${appointment.date}T00:00:00`);
            const dayName = `${daysOfWeek[appointmentDate.getDay()]} ${appointmentDate.getDate()} ${months[appointmentDate.getMonth()]}`;

            return (
              <div key={appointment.id} className="space-y-2">
                <div className='text-sm'>{dayName}</div>
                <div className='bg-[#F4EFEF] rounded-lg p-2 flex items-center justify-between text-xs space-x-2'>
                  <div>
                    {appointment.type === 'video' ? (
                      <FontAwesomeIcon icon={faVideoCamera} className='w-6 text-sm' />
                    ) : (
                      <FontAwesomeIcon icon={faPhone} className='w-6 text-sm' />
                    )}
                  </div>
                  <div className='wrap text-[12px]'>
                    {appointment.time} - <a href={appointment.profileLink} className="text-blue-500 underline">{appointment.name}</a>
                  </div>
                  <div className='bg-white rounded-lg px-2 py-0.5 border border-dark-blue text-[10px] min-w-[70px] text-center hover:bg-dark-blue hover:text-white cursor-pointer'>Start Call</div>
                </div>
              </div>
            );
          })
        ) : (
          <div>No appointments this week</div>
        )}
      </div>
      <SlideOut
        open={open}
        setOpen={setOpen}
      />
    </div>
  );
};

export default MonthCalendar;

