/* eslint-disable */
import { useState, useEffect, useRef } from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import UserProfile from '../../components/UserProfile'
import MainTitle from '../../components/MainTitle'
import { Link, useParams } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import UserNav from '../../components/UserNav'
import Modal from '../../components/Modal'

const User = () => {
  const { id } = useParams()
  const axios = useAxiosPrivate()
  const [userData, setUserData] = useState({})
  const { setShowLoader, can, createInfo } = useAuth()
  const errRef = useRef()
  const [errMsg, setErrMsg] = useState('')

  // Services
  const [services, setServices] = useState([])
  const [assignService, setAssignService] = useState({})
  const [assignedServices, setAssignedServices] = useState([])
  const [deleteService, setDeleteService] = useState({})

  // Modal
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')

  const getUserById = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/users/${id}`)
      setUserData(response.data.result)
      setAssignedServices(response.data.result.services)
      setShowLoader(false)
    } catch (error) {}
  }

  const handleUpdateUser = (updatedUser) => {
    setUserData(updatedUser)
  }

  const assignServiceClicked = () => {
    getServices()
    setAssignService({})
    setModalTitle(`Assign Service`)
    setShowModal(true)
  }

  const deleteServiceClicked = (service) => {
    setModalTitle('Unassign Service: ' + service.title)
    setDeleteService({ ...service })
    setShowModal(true)
  }

  const getServices = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/services/by-user/${id}`)
      if (response.data.result) {
        setServices(response.data.result)
      }
    } catch (error) {}
    setShowLoader(false)
  }

  const resetModal = () => {
    setAssignService({})
    setDeleteService({})
    setShowModal(false)
  }

  const ModalBody = () => {
    if (deleteService.id) {
      return <DeleteServiceElement />
    }

    if (assignService) {
      return <AssignServiceElement />
    }
  }

  const AssignServiceElement = () => {
    const assignServiceSubmit = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      const data = JSON.parse(assignService)

      try {
        const response = await axios.post(`/users/assign-service`, {
          userId: userData.id,
          serviceId: data.id,
        })
        createInfo('success', `Assigned Service: ${data.title}`)
        setUserData(response.data.result)
        setAssignedServices(response.data.result.services)
        resetModal(false)
      } catch (error) {
        console.log(error)
      }

      setShowLoader(false)
    }

    return (
      <form onSubmit={assignServiceSubmit}>
        <div className="mb-3">
          <label
            htmlFor="name"
            className="block font-medium text-gray-600 text-sm"
          >
            Service Name
          </label>
          <select
            defaultValue={assignService}
            className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            required
            onChange={(e) => setAssignService(e.target.value)}
          >
            <option disabled value={{}}>
              Select Service
            </option>
            {services?.map((service) => {
              if (
                !assignedServices.find((assignedService) => {
                  return service.id === assignedService.id
                })
              ) {
                return (
                  <option key={service.id} value={JSON.stringify(service)}>
                    {service.title}
                  </option>
                )
              }
            })}
          </select>
        </div>
        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Assign Service</button>
          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const DeleteServiceElement = () => {
    const removeService = async () => {
      setShowLoader(true)
      try {
        const response = await axios.post(`/users/unassign-service`, {
          userId: userData.id,
          serviceId: deleteService.id,
        })
        createInfo('error', `Unassigned Service: ${deleteService.title}`)
        setUserData(response.data.result)
        setAssignedServices(response.data.result.services)
        resetModal(false)
      } catch (error) {}
      setShowLoader(false)
    }
    return (
      <form onSubmit={removeService} className="justify-center flex">
        <div className="mb-3">
          <p className="text-lg font-bold my-8 text-center">
            Are you sure you want to unassign this service?
          </p>
          <div className="flex mt-2 w-full">
            <button className="btn red mt-4 mr-4 w-1/2">
              Unassign Service
            </button>

            <button
              type="button"
              className="btn mt-4 w-1/2"
              onClick={resetModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    )
  }

  useEffect(() => {
    getUserById(id)
  }, [])

  return (
    <div>
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}

      <MainTitle title="Profile" />
      <UserNav active="profile" userId={userData.id} />
      <UserProfile user={userData} onUpdateUser={handleUpdateUser} />
{/* 
      <section className="w-full bg-white rounded-xl shadow-md py-4 px-4 mb-8">
        <div className="flex items-center mb-4 w-full justify-between">
          <h2 className="font-bold text-lg">Services</h2>
          {can(`assign services`) && (
            <div
              className="cursor-pointer ml-4 text-blue-500 font-bold items-center flex btn"
              onClick={assignServiceClicked}
            >
              <span>Assign Service</span>
            </div>
          )}
        </div>

        <ul className="flex">
          {assignedServices?.map((service) => {
            return (
              <li key={service.id} className="flex">
                <span className="pill">{service.title}</span>
                {can('remove user services') ? (
                  <span
                    title="Remove Service"
                    className="pill-button"
                    onClick={() => deleteServiceClicked(service)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </span>
                ) : (
                  <span className="mr-4"></span>
                )}
              </li>
            )
          })}
        </ul>
      </section> */}
    </div>
  )
}

export default User
