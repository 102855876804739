import { useState, useEffect } from 'react'
import useAuth from '../../hooks/useAuth'

function OrganisationNav({ activeTab, setActiveTab, organisation = {} }) {
  const { admin } = useAuth()
  const [historyCount, setHistoryCount] = useState('')

  useEffect(() => {
    if(organisation?.policy_information) {
      let orgPolicies = JSON.parse(organisation?.policy_information)
      setHistoryCount(orgPolicies.length)
    }
  }, [organisation.policy_information])

  return (
    <div className="mb-8">
      <ul className="flex flex-col md:flex-row flex-wrap list-none px-0">
        <li 
          className={`tab-btn ${activeTab === 'details' ? 'active' : ''}`} 
          onClick={() => setActiveTab('details')}
        >
          Referrals
        </li>
        {/* <li 
          className={`tab-btn ${activeTab === 'claims' ? 'active' : ''}`} 
          onClick={() => setActiveTab('claims')}
        >
          PPQ's
        </li> */}
        <li 
          className={`tab-btn ${activeTab === 'contacts' ? 'active' : ''}`} 
          onClick={() => setActiveTab('contacts')}
        >
          Contacts
        </li>
        <li 
          className={`tab-btn ${activeTab === 'enquiries' ? 'active' : ''}`} 
          onClick={() => setActiveTab('enquiries')}
        >
          Enquiries
        </li>
        <li 
          className={`tab-btn ${activeTab === 'history' ? 'active' : ''}`} 
          onClick={() => setActiveTab('history')}
        >
          History 
          {/* <span className={"bg-red-500 border-red-700 text-white rounded-xl ml-2 px-2 py-0.5"}>{historyCount ? historyCount : '0'}</span> */}
        </li>
        <li 
          className={`tab-btn ${activeTab === 'staff' ? 'active' : ''}`} 
          onClick={() => setActiveTab('staff')}
        >
          Staff
        </li>
      </ul>
    </div>
  )
}

export default OrganisationNav

