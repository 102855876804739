import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faPlus} from '@fortawesome/free-solid-svg-icons'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import useAuth from '../hooks/useAuth'

const EnquiryForm = ({contact_id = null, contact_type_id, description, contact_outcome_id, contactable_id, contactable_type,  created_at, created_by, withdrawal_status, withdrawal_reason = null, setSuccessfulCreation, setMarkedAsWithdrawn,}) => {
  const axios = useAxiosPrivate()
  const [contactType, setContactType] = useState(null)
  const [contactDetails, setContactDetails] = useState(null)
  const [contactOutcome, setContactOutcome] = useState(null)
  const [withdrawalStatus, setWithdrawalStatus] = useState(withdrawal_status)
  const [withdrawalReason, setWithdrawalReason] = useState(withdrawal_reason)
  const [formDisabled, setFormDisabled] = useState(false)
  const [contactTypeView, setContactTypeView] = useState(null)
  const [contactDetailsView, setContactDetailsView] = useState(null)
  const [contactOutcomeView, setContactOutcomeView] = useState(null)
  
  const { createInfo } = useAuth()

  const getContactInformation = () => {
    setContactType(contact_type_id)
    setContactDetails(description)
    setContactOutcome(contact_outcome_id)
    setWithdrawalStatus(withdrawal_status)
    setWithdrawalReason(withdrawal_reason)

    switch(contact_type_id) {
      case 1:
        setContactTypeView('Email');
        break;
      case 2:
        setContactTypeView('Phone');
        break;
      case 3:
        setContactTypeView('Website');
        break;
      case 4:
        setContactTypeView('App');
        break;
        default:
          setContactTypeView('Could Not Be Determined')
    }

    setContactDetailsView(description)
    
    switch(contact_outcome_id) {
      case 1:
        setContactOutcomeView('Passed to a Team Member');
        break;
      case 2:
        setContactOutcomeView('Advice, Client to recall');
        break;
      case 3:
        setContactOutcomeView('Referred to a Service');
        break;
      case 4:
        setContactOutcomeView('Escalated to Nurses');
        break;
      case 5:
        setContactOutcomeView('Care Ended');
        break;
      case 6:
        setContactOutcomeView('Session Booked');
        break;
        default:
          setContactOutcomeView('Could Not Be Determined')
    }
  }

  const markAsWithdrawn = async (e) => {
    e.preventDefault();

    if(!withdrawalReason) {
      createInfo('error', 'Please fill in reason for withdrawal')
      return
    }

    const formData = {
      id: contact_id,
      withdrawalReason: withdrawalReason
    }
    
    try {
      const response = await axios.post(`/contacts/markAsWithdrawn`, formData);
      if (response.status === 200) {
        setSuccessfulCreation(true)
      } else {
        createInfo('error', 'Failed To Mark As Withdrawn, Please Try Again')
      }
    } catch (error) {
      console.error("Form submission failed:", error);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      contactType:      contactType,
      contactableID:    contactable_id,
      contactableType:  contactable_type,
      contactDetails:   contactDetails,
      contactOutcome:   contactOutcome,
    }

    try {
      const response = await axios.post(`/contacts/store`, formData);
      if (response.status === 200) {
        setSuccessfulCreation(true)
      } else {
        createInfo('error', 'Failed To Create Contact, Please Try Again')
      }
    } catch (error) {
      console.error("Form submission failed:", error);
    }
  }

  useEffect(() => {
    if(contact_id) {
      getContactInformation()
      setFormDisabled(true)
    } else {
      setContactType(1)
      setContactDetails('')
      setContactOutcome(1)
      setFormDisabled(false)
    }
  }, [contact_id])

  return (
    <section className="w-full">
      <form onSubmit={handleSubmit}>
          <div className='bg-white rounded-lg p-4 w-full'>
          
              <div>
                {formDisabled ? (
                  <section>
                    <div className='flex flex-col'>
                      <div className='flex mb-4'>
                        <div className='w-1/2'>
                          <label className='text-nav mb-1 font-bold text-lg' htmlFor="">Created By</label>
                          <p>{created_by}</p>
                        </div>
                        <div className='w-1/2'>
                          <label className='text-nav mb-1 font-bold text-lg' htmlFor="">Created At</label>
                          <p>{created_at}</p>
                        </div>
                      </div>

                      <hr className='mb-4'></hr>

                      <div className='flex mb-4'>
                        <div className='w-1/2'>
                          <label className='text-nav font-bold text-lg' htmlFor="">Contact Type</label>
                          <p>{contactTypeView}</p>
                        </div>
                        
                        <div className='w-1/2'>
                          <label className='text-nav mb-1 font-bold text-lg' htmlFor="">Contact Outcome</label>
                          <p>{contactOutcomeView}</p>
                        </div>
                      </div>

                      <hr className='mb-4'></hr>

                      <div className='mb-4'>
                        <label htmlFor="" className='text-nav mb-1 font-bold text-lg'>Details</label>
                        <p>{contactDetailsView}</p>
                      </div>
                    </div>
                  </section>
                ) : (
                  <section>
                    <div className='flex flex-col justify-between'>
                      <div className='mb-4 '>
                        <label className='text-nav mb-1 font-bold text-lg' htmlFor="">Created By</label>
                        <select
                          id="contactType"
                          defaultValue={contactType}
                          onChange={(e) => {setContactType(e.target.value)}}
                          className="border border-nav-dark text-gray-900 text-md rounded-lg focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-violet-500 dark:focus:border-violet-500"
                        >
                          <option value="1">Email</option>
                          <option value="2">Phone</option>
                          <option value="3">Website</option>
                          <option value="4">App</option>
                        </select>
                      </div>
                
                      <div className='mb-4'>
                        <label htmlFor="" className='text-nav mb-1 font-bold text-lg'>Details</label>
                        <textarea
                          type="text"
                          id="contactDetails"
                          required
                          defaultValue={contactDetails}
                          onChange={(e) => {setContactDetails(e.target.value)}}
                          className=" border border-nav-dark text-gray-900 text-md rounded-lg focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-violet-500 dark:focus:border-violet-500"
                        />
                      </div>
                
                      <div className='mb-4'>
                        <label className='text-nav mb-1 font-bold text-lg' htmlFor="">Contact Outcome</label>
                        <select
                          id="contactOutcome"
                          defaultValue={contactOutcome}
                          onChange={(e) => {setContactOutcome(e.target.value)}}
                          className=" border border-nav-dark text-gray-900 text-md rounded-lg focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-violet-500 dark:focus:border-violet-500"
                        >
                          <option value="1">Passed to a Team Member</option>
                          <option value="2">Advice, Client to recall</option>
                          <option value="3">Referred to a Service</option>
                          <option value="4">Escalated to Nurses</option>
                          <option value="5">Care Ended</option>
                          <option value="6">Session Booked</option>
                        </select>
                      </div>

                      <div className="w-11/12 mx-auto my-4 absolute bottom-0 left-8 ">
                        <button
                          type="submit"
                          className='border border-nav-dark hover:bg-nav-dark hover:text-white text-nav hover:shadow-md flex items-center rounded h-[45px] w-full'
                        >
                          <div className="h-full flex justify-center items-center px-10 font-medium w-full">
                            Save
                          </div>
                          <div className="bg-nav text-white h-full flex w-[45px] justify-center items-center">
                            <FontAwesomeIcon icon={faPlus} />
                          </div>
                        </button>
                      </div>

                    </div>
                  </section>
                )}
                
            </div>
          </div>
        </form>

        {formDisabled && (
          <>
            {withdrawalStatus === 0 ? (
              <section className='mx-4'>
                <hr className='mb-4'></hr>
                <form onSubmit={markAsWithdrawn}>
                  <div className='mb-4'>
                    <label htmlFor="" className='text-nav mb-1 font-bold text-lg'>Reason for Withdrawal?</label>
                    <textarea
                      type="text"
                      id="withdrawalReason"
                      required
                      onChange={(e) => {setWithdrawalReason(e.target.value)}}
                      className="border border-nav-dark text-gray-900 text-md rounded-xl focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-violet-500 dark:focus:border-violet-500"
                      />
                  </div>

                  <div className="w-11/12 mx-auto absolute bottom-0 left-8">
                      <button
                        type="submit"
                        className='border border-nav-dark hover:bg-nav-dark hover:text-white text-nav hover:shadow-md flex items-center rounded h-[45px] w-full'
                      >
                        <div className="h-full flex justify-center items-center px-10 font-medium w-full">
                          Mark As Withdrawn
                        </div>
                        <div className="bg-nav text-white h-full flex w-[45px] justify-center items-center">
                          <FontAwesomeIcon icon={faPlus} />
                        </div>
                      </button>
                    </div>
                </form>
              </section>
            ) : (
              
              <section className='w-full mx-4'>
                <hr className='mb-4'></hr>
                <div className='mb-4'>
                  <label htmlFor="" className='text-violet-500 mb-1 font-bold text-lg'>Withdrawal Reason</label>
                  <p>{withdrawalReason}</p>
                </div>
              
              </section>
            )}
          </>
        )}
        
    </section>
  )
}

export default EnquiryForm
